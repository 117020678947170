import { useLocationTypes } from '@/services/locationTypes';
import { ActionsHistory } from '@/types/actionsHistory';
import { formatMoney } from '@/utils';
import { formatAssessmentAddress } from '@/utils/address';
import { ArrowRightOutlined } from '@ant-design/icons';
import { equals, isEmpty, omit, uniq } from 'ramda';
import React from 'react';

type Props = {
  item: ActionsHistory;
};

const YearlyCheckSubmitted = ({ item }: Props) => {
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const locationLabelGetter =
    locationTypesData && !locationTypesLoading
      ? locationTypesData.getLocationLabel
      : () => 'Loading...';

  return (
    <div className="historyCard">
      <span className="historyCard-date">
        {new Date(item.createdAt).toLocaleDateString()}
      </span>
      <div className="historyCard-message spacer">
        <span>{item.actionExtraData?.user.name}</span>
        <span className="font-bold">({item.actionExtraData?.user.email})</span>
        <span>edited the assessment</span>
      </div>
      <div className="historyCard-message">
        <span>Changes:</span>
        <ul>{formatChanges(item.actionExtraData, locationLabelGetter)}</ul>
      </div>
    </div>
  );
};

export default YearlyCheckSubmitted;

const ArrowRight = () => <ArrowRightOutlined style={{ color: '#1890FF' }} />;

const renderChange = (
  label: string,
  before: any,
  after: any,
  formatFunction = (x: any) => x
) => {
  const isEqual = equals(before, after);
  return (
    <li className="yearly-check-change-label">
      <span className="font-bold">{label}:</span>
      {!isEmpty(before) && !isEqual && <span>{formatFunction(before)}</span>}
      {!isEmpty(before) && !isEqual && <ArrowRight />}
      <span>{formatFunction(isEqual ? before : after)}</span>
    </li>
  );
};

const formatEmployeeDetails = (details: any) => (
  <span className="spacer">
    <span>Owners: {details.ownersCount};</span>
    <span>Full-Time: {details.fullTimeEmployeesCount};</span>
    <span>Part-Time: {details.partTimeEmployeesCount};</span>
    <span>Minijobber: {details.miniJobbersCount}</span>
  </span>
);

const yearlyCheckMapper = (
  key: string,
  before: any,
  after: any,
  locationLabelGetter: (code: string) => string
) => {
  switch (key) {
    case 'businessType': {
      const formatLocationType = (data: any) =>
        uniq([...data.locationSubtypes, data.locationType])
          .map((lt) => locationLabelGetter(lt))
          .join('; ');
      return renderChange('Location type', before, after, formatLocationType);
    }
    case 'locationDetails':
      return (
        <>
          {renderChange('Business name', before.name, after.name)}
          {renderChange(
            'Business address',
            before.address,
            after.address,
            formatAssessmentAddress
          )}
        </>
      );

    case 'employeesCount':
      return renderChange('Employees', before, after, formatEmployeeDetails);

    case 'lastYearFinances': {
      return (
        <>
          {renderChange(
            'Approx. turnover',
            before.approxTurnover || 0,
            after.approxTurnover || 0,
            formatMoney
          )}
          {renderChange(
            'Personnel Expenses',
            before.approxPersonelExpenses || 0,
            after.approxPersonelExpenses || 0,
            formatMoney
          )}
        </>
      );
    }

    case 'notes': {
      const formatNotes = (data: Record<string, string>) => data.body;
      return <>{renderChange('Notes', before, after, formatNotes)}</>;
    }
    default:
      return (
        <>
          <p className="font-bold">
            *** DEBUG, please inform the product team ***
          </p>
          {JSON.stringify({ key, before, after })}
        </>
      );
  }
};

const formatChanges = (
  changes: any,
  locationLabelGetter: (code: string) => string
) => {
  const changesWithoutUser = omit(['user'], changes);
  return Object.keys(changesWithoutUser).map((key) => {
    return yearlyCheckMapper(
      key,
      changes[key].before,
      changes[key].after,
      locationLabelGetter
    );
  });
};
