import {
  AttributeDefinitionItemApiResponse,
  AttributeDefinitionPutApiPayload,
  AttributeDefinitionsApiResponse,
  createOrUpdateAttributeDefinition,
  getAttributesDefinitionById,
  getAttributesDefinitionsList,
  getAttributesTranslations
} from '@/api/attributes';
import { useQuery, UseQueryOptions } from 'react-query';
import { useInvalidateOnSuccessMutation } from '@/utils';

const keys = {
  attributesTranslations: 'attributes-translations',
  attributesDefinitions: 'attributes-definitions'
};

export const useAttributesTranslations = (language: 'en' | 'de' = 'en') => {
  return useQuery(
    [keys.attributesTranslations, language],
    () => getAttributesTranslations(language),
    {
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
};

export const useAttributesDefinitions = (
  {
    page = 1,
    searchQuery,
    language = 'en'
  }: {
    searchQuery: string;
    page: number;
    language: 'en' | 'de';
  },
  options: UseQueryOptions<
    AttributeDefinitionsApiResponse,
    unknown,
    AttributeDefinitionsApiResponse,
    [string, string, string, number]
  > = {}
) => {
  return useQuery(
    [keys.attributesDefinitions, searchQuery, language, page],
    () => getAttributesDefinitionsList({ page, searchQuery, language }),
    options
  );
};

export const useAttributesDefinitionItem = (
  id: string,
  options: UseQueryOptions<
    AttributeDefinitionItemApiResponse,
    unknown,
    AttributeDefinitionItemApiResponse,
    [string, string]
  > = {}
) => {
  return useQuery(
    [keys.attributesDefinitions, id],
    () => getAttributesDefinitionById(id),
    options
  );
};

export const useCreateOrUpdateAttributeDefinition = () =>
  useInvalidateOnSuccessMutation(
    [keys.attributesDefinitions],
    (data: AttributeDefinitionPutApiPayload) =>
      createOrUpdateAttributeDefinition(data)
  );
