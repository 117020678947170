import { Carrier } from '@/types/carrier';
import { api } from './api';

export const getCarriers = () => api.get<Carrier[]>('/carrier');

export const getAllCarriersData = () => api.get('/carrier?full=true');

export const getCarrierById = (id: string) => api.get(`/carrier/${id}`);

export const addCarrier = ({
  name,
  postalCode,
  city,
  street,
  country,
  acronym,
  generalBrokerId,
  clientBrokerId,
  contactPerson,
  contactPhoneNr,
  email,
  brokerSupportEmail,
  creationOfferEmail,
  contractDocumentsEmail,
  clientTransferEmail,
  requestProtectionEmail,
  generalRequestProtectionEmail,
  linkPriceCalculator,
  linkPriceCalculatorUsername,
  linkPriceCalculatorPassword,
  linkBrokerSite,
  linkBrokerSiteUsername,
  linkBrokerSitePassword,
  linkPostbox,
  linkPostboxUsername,
  linkPostboxPassword,
  notes,
  commissionFile,
  folderLink,
  contactUpdatesDate,
  customerSatisfactionRating,
  claimHandlingRating,
  responseTimeRating
}: Carrier) =>
  api.post('/carrier', {
    name,
    postalCode,
    city,
    street,
    country,
    acronym,
    generalBrokerId,
    clientBrokerId,
    contactPerson,
    contactPhoneNr,
    email,
    brokerSupportEmail,
    creationOfferEmail,
    contractDocumentsEmail,
    clientTransferEmail,
    requestProtectionEmail,
    generalRequestProtectionEmail,
    linkPriceCalculator,
    linkPriceCalculatorUsername,
    linkPriceCalculatorPassword,
    linkBrokerSite,
    linkBrokerSiteUsername,
    linkBrokerSitePassword,
    linkPostbox,
    linkPostboxUsername,
    linkPostboxPassword,
    notes,
    commissionFile,
    folderLink,
    contactUpdatesDate,
    customerSatisfactionRating,
    claimHandlingRating,
    responseTimeRating
  });

export const updateCarrier = ({
  carrierId,
  name,
  postalCode,
  city,
  street,
  country,
  acronym,
  generalBrokerId,
  clientBrokerId,
  contactPerson,
  contactPhoneNr,
  email,
  brokerSupportEmail,
  creationOfferEmail,
  contractDocumentsEmail,
  clientTransferEmail,
  requestProtectionEmail,
  generalRequestProtectionEmail,
  linkPriceCalculator,
  linkPriceCalculatorUsername,
  linkPriceCalculatorPassword,
  linkBrokerSite,
  linkBrokerSiteUsername,
  linkBrokerSitePassword,
  linkPostbox,
  linkPostboxUsername,
  linkPostboxPassword,
  notes,
  commissionFile,
  folderLink,
  contactUpdatesDate,
  customerSatisfactionRating,
  claimHandlingRating,
  responseTimeRating,
  logoUrl
}: Carrier) =>
  api.put(`/carrier/${carrierId}`, {
    name,
    postalCode,
    city,
    street,
    country,
    acronym,
    generalBrokerId,
    clientBrokerId,
    contactPerson,
    contactPhoneNr,
    email,
    brokerSupportEmail,
    creationOfferEmail,
    contractDocumentsEmail,
    clientTransferEmail,
    requestProtectionEmail,
    generalRequestProtectionEmail,
    linkPriceCalculator,
    linkPriceCalculatorUsername,
    linkPriceCalculatorPassword,
    linkBrokerSite,
    linkBrokerSiteUsername,
    linkBrokerSitePassword,
    linkPostbox,
    linkPostboxUsername,
    linkPostboxPassword,
    notes,
    commissionFile,
    folderLink,
    contactUpdatesDate,
    customerSatisfactionRating,
    claimHandlingRating,
    responseTimeRating,
    logoUrl
  });
