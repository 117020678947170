import { AttributeDefinition } from '@/api/attributes';
import { Alert, Form, FormInstance, Input } from 'antd';
import React from 'react';

export const AttributeDefinitionForm = ({
  attribute,
  creatingNew,
  form,
  onFinish
}: {
  attribute?: AttributeDefinition;
  creatingNew: boolean;
  form?: FormInstance;
  onFinish: (data: Omit<AttributeDefinition, 'id'>) => void;
}) => {
  return (
    <Form
      name="basic"
      initialValues={attribute}
      onFinish={onFinish}
      layout="vertical"
      form={form}
    >
      {creatingNew && (
        <Alert
          message="Code naming convention"
          style={{ marginBottom: '1rem' }}
          description={
            <div>
              <p>
                Please use English words and numbers separated by underscores.
                Example:
              </p>
              <ul>
                <li>personal_liability_insurance</li>
                <li>unnamed_perils_2</li>
              </ul>
            </div>
          }
          type="info"
          showIcon
        />
      )}
      <Form.Item
        label="Code"
        name="code"
        rules={[
          { required: true, message: 'Please input attribute code!' },
          {
            validator: (_rule, value) => {
              if (!value) {
                return Promise.resolve();
              }

              if (!/^[a-z0-9_]+$/.test(value)) {
                return Promise.reject(
                  'You can only use lowercase letters, numbers and underscores'
                );
              }

              return Promise.resolve();
            },
            message: 'You can only use lowercase letters and underscores'
          },
          {
            validator: (_rule, value) => {
              if (!value) {
                return Promise.resolve();
              }

              if (/__+/.test(value)) {
                return Promise.reject(
                  'You cannot have more than one consecutive underscore'
                );
              }
              return Promise.resolve();
            },
            message: 'You can only use lowercase letters and underscores'
          }
        ]}
        extra={
          !creatingNew &&
          "You can't change attribute code after creation. If you need to, please contact dev team."
        }
      >
        <Input disabled={!creatingNew} />
      </Form.Item>

      <Form.Item
        label="Name (EN)"
        name="name_en"
        rules={[
          { required: true, message: 'Please input attribute English name' }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Name (DE)"
        name="name_de"
        rules={[
          { required: true, message: 'Please input attribute German name' }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Description (EN)" name="description_en">
        <Input.TextArea rows={6} />
      </Form.Item>

      <Form.Item label="Description (DE)" name="description_de">
        <Input.TextArea rows={6} />
      </Form.Item>
    </Form>
  );
};
