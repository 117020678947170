import { setUnauthorizedInterceptor } from '@/api/api';
import { config } from '../config';
import { useAuth } from '@/providers';
import { isAdmin } from '@/utils';
import { useEffect } from 'react';

export const UnauthorizedGuard = () => {
  const { logout, user, isLoading } = useAuth();

  useEffect(() => {
    setUnauthorizedInterceptor(logout);
  }, [logout]);

  if (!isLoading && user && !isAdmin(user)) {
    logout();
    window.location.replace(config.webApp as string);
  }

  return null;
};
