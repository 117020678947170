import { arrayOf, elementType, node, oneOfType } from 'prop-types';

const renderableType = oneOfType([elementType, node]);

export const oneOrManyChildren = oneOfType([
  renderableType,
  arrayOf(renderableType)
]);

export const oneChild = renderableType;
