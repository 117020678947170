import {
  RecommendationProduct,
  RecommendationProductPriceFetchResult,
  RecommendationProductResult
} from '@/types/recommendation';
import { api } from './api';

export const createRecommendationProduct = ({
  recommendationId,
  insuranceProductId
}: CreateRecommendationProductPayload) =>
  api.post(`/admin/recommendationProduct/${insuranceProductId}`, {
    recommendationId
  });

export const recalculatePrice = (recommendationProductId: string) =>
  api.post<RecommendationProductPriceFetchResult>(
    `/admin/recommendation-product/${recommendationProductId}/recalculate-price`
  );

export const updateRecommendationProductByInsuranceProductId = ({
  recommendationProductId,
  insuranceProductId
}: UpdateRecommendationProductPayload) =>
  api.put(
    `/admin/recommendationProduct/${recommendationProductId}/${insuranceProductId}`
  );

export const updateRecommendationProduct = ({
  id,
  deductible,
  parametersValues,
  amountInsured,
  amountInsuredUnlimited,
  netPrice,
  grossPrice,
  yesNoValues,
  paymentPeriod,
  highlightFields,
  highlightText,
  highlightedAttributes,
  startDate,
  commission,
  offerTag,
  discountValue,
  risksInsured,
  lossOfEarnings,
  options
}: Partial<RecommendationProduct>) =>
  api.put<RecommendationProductResult>(`/admin/recommendationProduct/${id}`, {
    deductible,
    parametersValues,
    amountInsured,
    amountInsuredUnlimited,
    netPrice,
    grossPrice,
    paymentPeriod,
    yesNoValues,
    highlightFields,
    highlightedAttributes,
    highlightText,
    startDate,
    commission,
    offerTag,
    discountValue,
    risksInsured,
    lossOfEarnings,
    options
  });

type CreateRecommendationProductPayload = {
  recommendationId: string;
  insuranceProductId: string;
};

type UpdateRecommendationProductPayload = {
  recommendationProductId: string;
  insuranceProductId: string;
};
