export const config = {
  environment: process.env.REACT_APP_ENV,
  backend: process.env.REACT_APP_BACKEND,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  authDomain: process.env.REACT_APP_AUTH0_DOMAIN,
  cdn: process.env.REACT_APP_CDN,
  webApp: process.env.REACT_APP_WEB,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sentryTracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    : 0,
  genericCarrierLogo: 'genericCarrier.png',
  backendApiAudience: process.env.REACT_APP_BACKEND_API_AUDIENCE
};
