import { getExpirings } from '@/api';
import { Expiring } from '@/types/insurance';
import { useQuery } from 'react-query';

interface UseExpiringsArgs {
  from: number;
  to: number;
}

const keys = {
  expirings: 'expirings'
};

export const useExpiring = ({ from, to }: UseExpiringsArgs) =>
  useQuery<Expiring[], unknown, Expiring[]>([keys.expirings], () =>
    getExpirings({ from, to })
  );
