import axios, {
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import { assoc, identity, mergeLeft, pipe, prop } from 'ramda';
import { camelToSnakeCase, snakeToCamelCase } from './case';
import { log } from './environment';
import { renameObjKeysBy } from './object';

export const createMultiTenantApi = (
  baseURL: string,
  getHeaders = identity as any // fixme
) => {
  /**
   * @param config
   */
  const debugInterceptor =
    (config: InternalAxiosRequestConfig) => (data: any) => {
      if (process.env.REACT_APP_FORCE_RESPONSE_LOGS === 'true') {
        log(
          config.method?.toUpperCase(),
          (config.baseURL || '') + (config.url || ''),
          config.data || data
        );
      }

      return data;
    };

  /**
   * @param {import('axios').AxiosResponse<any>} response
   */
  const responseInterceptor = (response: AxiosResponse) =>
    pipe(
      prop('data'),
      renameObjKeysBy(snakeToCamelCase),
      debugInterceptor(response.config)
    )(response);
  /**
   * @param {import('axios').InternalAxiosRequestConfig} config
   */
  const contentInterceptor = (config: InternalAxiosRequestConfig) => {
    const headers = mergeLeft(config.headers || {}, {
      'Content-Type': 'application/json',
      ...getHeaders()
    });

    config.headers = { ...config.headers, ...headers } as AxiosRequestHeaders;

    return config;
  };

  /**
   * @param {import('axios').InternalAxiosRequestConfig} config
   */
  const requestInterceptor = (config: InternalAxiosRequestConfig) => {
    if (!config.data) return config;

    const data = renameObjKeysBy(camelToSnakeCase, config.data);

    return assoc('data', data, config);
  };

  log('Api baseUrl:', baseURL);

  const multiTenantApi = axios.create({
    baseURL
  });

  multiTenantApi.interceptors.request.use(contentInterceptor);
  multiTenantApi.interceptors.request.use(requestInterceptor);
  multiTenantApi.interceptors.response.use(responseInterceptor);

  return multiTenantApi;
};
