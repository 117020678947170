import { useHistory, useLocation } from 'react-router-dom';

export function useUrlParams<T extends { [key: string]: string }>(): {
  current: T;
  setParams: (params: Partial<T>) => void;
} {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const current = [...searchParams.entries()].reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {} as { [key: string]: string }
  );

  const history = useHistory();

  const setParams = (newState: Partial<T>) => {
    const newSearchParams = new URLSearchParams(location.search);
    Object.entries(newState).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, String(value));
      } else {
        newSearchParams.delete(key);
      }
    });
    history.push(`${location.pathname}?${newSearchParams.toString()}`);
  };

  return {
    current: current as T,
    setParams
  };
}
