export const InsuranceLabels = {
  submitted: 'submitted',
  important: 'important',
  recommended: 'recommended',
  useful: 'useful',
  veryGood: 'veryGood',
  optimisable: 'optimisable',
  weak: 'weak',
  arrears: 'beitragsrückstand'
};

export const InsuranceTags = {
  important: 'important',
  recommended: 'recommended',
  useful: 'useful',
  veryGood: 'very good',
  optimisable: 'optimisable',
  weak: 'weak',
  submitted: 'submitted',
  arrears: 'arrears'
};
