import { curry, map, replace } from 'ramda';
import React from 'react';

export const mapWithKey = curry((Comp, key, list) =>
  map((item) => <Comp key={item[key]} {...item} />, list)
);

export const handleNegativeInput = (event) => {
  if (event.key === '-') event.preventDefault();
};

export const linkify = replace(
  /(https?:\/\/[^\s]+)/g,
  '<a href="$1" rel="noreferrer" target="_blank">$1</a>'
);
