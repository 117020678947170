import React, { useRef } from 'react';

import { timestampToDateString } from '@/utils';

export const Datepicker = ({ onChange, value, ...props }: DatepickerProps) => {
  const inputRef = useRef();

  return (
    <input
      className={[
        'ant-input',
        props['aria-invalid'] === 'true' ? 'ant-input-status-error' : ''
      ].join(' ')}
      type="date"
      ref={inputRef?.current}
      onBlur={(evt) => {
        const parsed = Date.parse(evt.target.value);
        if (isNaN(parsed)) {
          onChange?.(undefined);
        } else {
          onChange?.(parsed);
        }
      }}
      defaultValue={!!value ? timestampToDateString(value) : ''}
      {...props}
    />
  );
};

interface DatepickerProps {
  onChange?: (value: number | undefined) => void;
  value?: string;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  'aria-invalid'?: 'true';
}
