import { Form, FormItemProps, Input, Select } from 'antd';
import React from 'react';
import { isEmpty } from 'ramda';

const { Option } = Select;

/**
 * Renders Form.Input with text number or select inputs
 */
export const NumberOrSelectField = ({
  selectOptions,
  handleChange,
  disabled,
  ...props
}: FormItemProps & {
  /**
   * empty array - disabled select
   * null - free text field
   * array of values - select
   */
  selectOptions: { value: number; label: string }[] | null;
  handleChange: () => void;
  disabled: boolean;
}) => {
  return (
    <Form.Item {...props}>
      {selectOptions === null ? (
        <Input
          onBlur={handleChange}
          disabled={disabled}
          type="number"
          addonBefore="€"
        />
      ) : (
        <Select
          disabled={disabled || isEmpty(selectOptions)}
          onChange={handleChange}
        >
          {selectOptions.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};
