import { api } from './api';

const baseUrl = '/admin/expiring_insurances';

export const getExpirings = ({ from, to }: ExpiringArgs) =>
  api.get(`${baseUrl}?from=${from}&to=${to}`);

interface ExpiringArgs {
  from: number;
  to: number;
}
