import {
  addCarrier,
  getAllCarriersData,
  getCarrierById,
  getCarriers,
  updateCarrier
} from '@/api';
import { Carrier } from '@/types/carrier';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { UseQueryOptions, useQuery } from 'react-query';

export interface UpdateCarrierArgs {
  carrierId: string;
  customerSatisfactionRating?: number;
  claimHandlingRating?: number;
  responseTimeRating?: number;
}

const keys = {
  carriers: 'carriers',
  carrier: 'carrier',
  fullCarriers: 'fullCarriers'
};

export const useCarriers = (
  options?: UseQueryOptions<Carrier[], unknown, Carrier[], string>
) => useQuery(keys.carriers, getCarriers, options);

export const useCarrier = (
  id: string,
  options: UseQueryOptions<Carrier[], unknown, Carrier[], string[]>
) => useQuery([keys.carrier, id], () => getCarrierById(id), options);

export const useAllCarriersData = (
  options?: UseQueryOptions<Carrier[], unknown, Carrier[], string>
) => useQuery(keys.fullCarriers, getAllCarriersData, options);

export const useAddCarrier = () =>
  useInvalidateOnSuccessMutation<Partial<Carrier>>(
    keys.fullCarriers,
    addCarrier
  );

export const useUpdateCarrier = (carrierId?: string) =>
  useInvalidateOnSuccessMutation<Carrier, unknown, UpdateCarrierArgs, unknown>(
    [keys.fullCarriers, [keys.carrier, carrierId]],
    (body) => updateCarrier({ carrierId, ...body })
  );
