import { Alert, AlertProps } from 'antd';

import { RecommendationProduct } from '../../types/recommendation';

const alertPropsMap = {
  priceNotVerified: {
    type: 'warning',
    message: 'Price not verified',
    description:
      'The price is approximate. Please verify and update it manually.'
  },
  searchEnginePrice: {
    type: 'success',
    message: 'Search Engine price',
    description: 'Parameters can be edited'
  },
  manualPrice: {
    type: 'warning',
    message: 'Manual price',
    description: 'Parameters can only be edited with approval'
  }
} as const satisfies Record<string, AlertProps>;

type AlertKey = keyof typeof alertPropsMap;

const getAlertKey = (
  recommendationProduct: RecommendationProduct
): AlertKey | null => {
  if (recommendationProduct.isPriceVerified === false) {
    return 'priceNotVerified';
  }

  if (recommendationProduct.isSearchEnginePrice) {
    return 'searchEnginePrice';
  }

  if (recommendationProduct.isSearchEnginePrice === false) {
    return 'manualPrice';
  }

  return null;
};

type Props = {
  recommendationProduct?: RecommendationProduct;
};

export const PriceAlert = ({ recommendationProduct }: Props) => {
  if (!recommendationProduct) {
    return null;
  }

  const alertKey = getAlertKey(recommendationProduct);

  if (!alertKey) {
    return null;
  }

  const alertProps = alertPropsMap[alertKey];

  return <Alert {...alertProps} showIcon />;
};
