import {
  addUserToCompany,
  AddUserToCompanyPayload,
  changeMD,
  createCompany,
  CreateCompanyPayload,
  createCompanyUser,
  CreateCompanyUserPayload,
  deleteCompany,
  getCompaniesByUser,
  getCompanyById,
  listCompanies,
  removeUserFromCompany,
  updateCompany,
  UpdateCompanyPayload
} from '@/api';
import { Company } from '@/types/company';
import { Location } from '@/types/location';
import { UserTypes } from '@/types/user';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery, UseQueryOptions } from 'react-query';

export interface UseUserCompaniesEntry {
  company: Company;
  userType: UserTypes;
}

export interface UseUserCompaniesSelectEntry {
  label: string;
  value: string;
}

interface UseCreateCompanyData {
  company: {
    item: Company;
    status: boolean;
  };
  location: {
    item: Location;
    status: boolean;
  };
}

const keys = {
  companies: 'companies',
  company: 'company',
  userCompanies: 'userCompanies',
  location: 'location'
};

export const useListCompanies = (
  options: UseQueryOptions<Company[], unknown, Company[], string>
) => useQuery(keys.companies, listCompanies, options);

export const useUserCompanies = (
  userId: string,
  options: UseQueryOptions<
    UseUserCompaniesEntry[],
    unknown,
    UseUserCompaniesSelectEntry[],
    string[]
  >
) =>
  useQuery(
    [keys.userCompanies, userId],
    () => getCompaniesByUser(userId),
    options
  );

export const useCompany = (companyId: string) =>
  useQuery<Company, unknown, Company, string[]>([keys.company, companyId], () =>
    getCompanyById(companyId)
  );

export const useAddUserToCompany = (companyId: string) =>
  useInvalidateOnSuccessMutation(
    [keys.company, companyId, keys.location],
    ({
      email,
      name,
      surname,
      phone,
      isMd,
      emailNotificationsDisabled,
      locationId
    }: AddUserToCompanyPayload) =>
      addUserToCompany({
        companyId,
        email,
        name,
        surname,
        phone,
        isMd,
        emailNotificationsDisabled,
        locationId
      })
  );

export const useAddCompanyUser = (companyId: string) =>
  useInvalidateOnSuccessMutation(
    [keys.company, companyId, keys.location],
    ({
      email,
      name,
      surname,
      phone,
      emailNotificationsDisabled,
      role,
      birthdate
    }: CreateCompanyUserPayload) =>
      createCompanyUser({
        companyId,
        email,
        name,
        surname,
        phone,
        emailNotificationsDisabled,
        role,
        birthdate
      })
  );

export const useRemoveUserFromCompany = (
  companyId: string,
  locationId?: string
) =>
  useInvalidateOnSuccessMutation(
    [keys.company, companyId, keys.location],
    (userId: string) => removeUserFromCompany({ companyId, userId, locationId })
  );

export const useUpdateCompany = (companyId: string) =>
  useInvalidateOnSuccessMutation(
    [keys.companies, [keys.company, companyId]],
    ({ companyId: id, ...rest }: UpdateCompanyPayload) =>
      updateCompany({ companyId: companyId || id, ...rest })
  );

export const useDeleteCompany = (companyId: string) =>
  useInvalidateOnSuccessMutation<string, unknown, void, unknown>(
    keys.companies,
    (id: string) => deleteCompany(id || companyId)
  );

export const useCreateCompany = (id: string) =>
  useInvalidateOnSuccessMutation<
    UseCreateCompanyData,
    unknown,
    CreateCompanyPayload,
    unknown
  >(keys.companies, ({ userId, company, location, businessType }) =>
    createCompany({ userId: id || userId, company, location, businessType })
  );

export const useChangeManagingDirector = (companyId: string) =>
  useInvalidateOnSuccessMutation([keys.company, companyId], (userId: string) =>
    changeMD({ userId, companyId })
  );
