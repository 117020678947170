import { RecommendationProduct } from '@/types/recommendation';
import { api } from './api';

export interface CheckoutPayload {
  items: Pick<
    RecommendationProduct,
    'recommendationId' | 'recommendationProductId'
  >[];
  paymentId: string;
  nextPaymentDate?: Date;
  startDate?: Date;
  endDate?: Date;
  paymentPeriod?: string;
  agreementNumber?: string;
  finalize?: boolean;
}

export const submitCheckout = (checkout: CheckoutPayload) =>
  api.post(`/cart/checkout`, checkout);
