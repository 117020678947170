import { Address } from '@/types/self-assessment';
import { api } from './api';

export const listCompanies = () => api.get('/admin/company');

export const getCompaniesByUser = (userId: string) =>
  api.get(`/admin/company/user/${userId}`);

export const getCompanyById = (companyId: string) =>
  api.get(`/admin/company/${companyId}`);

export interface AddUserToCompanyPayload {
  companyId: string;
  email: string;
  name: string;
  surname: string;
  phone: string;
  isMd: boolean;
  emailNotificationsDisabled?: boolean;
  locationId?: string;
}

export const addUserToCompany = ({
  companyId,
  email,
  name,
  surname,
  phone,
  isMd = false,
  emailNotificationsDisabled = false,
  locationId
}: AddUserToCompanyPayload) =>
  api.post(`/company/add-user/${companyId}`, {
    email,
    name,
    surname,
    phone,
    isMd,
    emailNotificationsDisabled,
    locationId
  });

export interface CreateCompanyPayload {
  userId: string;
  company: {
    name: string;
    address: Partial<Address>;
  };
  location: {
    name: string;
    address: Partial<Address>;
  };
  businessType: {
    businessType: string;
    locationType: string;
    locationSubtypes: string[];
    locationTypeSpecification?: string;
  };
}

export const createCompany = ({
  userId,
  company,
  location,
  businessType
}: CreateCompanyPayload) =>
  api.post(`/admin/company/${userId}`, {
    company,
    location,
    businessType
  });

export interface UpdateCompanyPayload {
  companyId: string;
  body?: any;
}

export const updateCompany = ({ companyId, ...body }: UpdateCompanyPayload) =>
  api.put(`/company/${companyId}`, body);

export const deleteCompany = (id: string) => api.delete(`/company/${id}`);

export interface CompanyUserPayload {
  companyId: string;
  userId: string;
  locationId?: string;
}

export const removeUserFromCompany = ({
  companyId,
  userId,
  locationId
}: CompanyUserPayload) =>
  api.delete(
    locationId
      ? `/company/deleteUserFromCompany/${userId}/${companyId}?location_id=${locationId}`
      : `/company/deleteUserFromCompany/${userId}/${companyId}`
  );

export const changeMD = ({ companyId, userId }: CompanyUserPayload) =>
  api.post(`/admin/assignMD/${userId}/${companyId}`);
