import {
  approveContractOffer,
  createMultipleInsurances,
  deleteInsurance,
  getFinancials,
  getInsuranceById,
  getInsuranceRequestProtectionDependenciesById,
  requestProtection,
  updateInsurance,
  updateInsuranceFinancials
} from '@/api';
import { InsuranceApiResult } from '@/types/insurance';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { assoc, map, pipe } from 'ramda';
import { QueryOptions, useQuery, UseQueryOptions } from 'react-query';

const keys = {
  insurances: 'insurances',
  insurance: 'insurance',
  insuranceRequestProtectionDependencies:
    'insuranceRequestProtectionDependencies'
};

export const useInsurance = (
  id: string,
  options: UseQueryOptions<
    InsuranceApiResult,
    unknown,
    InsuranceApiResult,
    string[]
  > = {}
) => useQuery([keys.insurance, id], () => getInsuranceById(id), options);

export const useInsuranceRequestProtectionDependencies = (id: string) =>
  useQuery([keys.insuranceRequestProtectionDependencies, id], () =>
    getInsuranceRequestProtectionDependenciesById(id)
  );

//fixme
const transform = (locationId: string) => {
  return pipe(
    map<Record<string, string>, any>(assoc('locationId', locationId)),
    createMultipleInsurances
  );
};

export const useCreateMultipleInsurances = (locationId: string) =>
  useInvalidateOnSuccessMutation(
    [keys.insurances, ['location', locationId]],
    transform(locationId)
  );

export const useDeleteInsurance = (id: string) =>
  useInvalidateOnSuccessMutation<string, unknown, void, unknown>(
    [keys.insurances, 'location'],
    () => deleteInsurance(id)
  );

export const useUpdateInsuranceLabel = (id: string) =>
  useInvalidateOnSuccessMutation('location', ({ insuranceLabel }) =>
    updateInsurance({ id, insuranceLabel })
  );

export const useUpdateInsuranceStatus = () =>
  useInvalidateOnSuccessMutation('expirings', ({ id, status }) =>
    updateInsurance({ id, status })
  );

export const useUpdateInsurance = (id: string) =>
  useInvalidateOnSuccessMutation(
    [[keys.insurance, id], 'location', 'expirings'],
    ({
      name,
      description,
      startDate,
      grossPrice,
      netPrice,
      commission,
      deductible,
      parametersValues,
      yesNoValues,
      endDate,
      policyNumber,
      contractStatus,
      contractType,
      closingCommission,
      paymentPeriod,
      contractPaymentNextDate,
      amountInsured,
      amountInsuredUnlimited,
      shouldRenewAutomatically,
      risksInsured,
      commissionPaidAt,
      commissionPaidTo
    }) =>
      updateInsurance({
        id,
        name,
        description,
        startDate,
        grossPrice,
        netPrice,
        commission,
        deductible,
        parametersValues,
        yesNoValues,
        endDate,
        closingCommission,
        policyNumber,
        contractStatus,
        contractType,
        paymentPeriod,
        contractPaymentNextDate,
        amountInsured,
        amountInsuredUnlimited,
        shouldRenewAutomatically,
        risksInsured,
        commissionPaidAt,
        commissionPaidTo
      })
  );

export const useContractOfferApproval = (locationId: string) =>
  useInvalidateOnSuccessMutation<
    Awaited<ReturnType<typeof approveContractOffer>>
  >(
    ['insurance', ['location', locationId]],
    (data: {
      id: string;
      finalize: boolean;
      paymentId?: string;
      nextPaymentDate?: string | Date;
      startDate?: string | Date;
      endDate?: string | Date;
      agreementNumber?: string;
    }) => approveContractOffer(data)
  );

export const useRequestProtection = (insuranceId: string, locationId: string) =>
  useInvalidateOnSuccessMutation<Awaited<ReturnType<typeof requestProtection>>>(
    [
      [keys.insurance, insuranceId],
      ['location', locationId]
    ],
    (data: { email: string; coverNoteFileId: string; poaFileId: string }) =>
      requestProtection({
        id: insuranceId,
        ...data
      })
  );

export const useFinancials = (options: QueryOptions) =>
  useQuery('financials', getFinancials, options);

export const useUpdateInsuranceFinancial = () =>
  useInvalidateOnSuccessMutation(['financials'], updateInsuranceFinancials);
