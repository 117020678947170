import { BusinessVertical } from '@/enums/businessVertical';
import { LocationType } from '@/types/location';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { difference, uniq } from 'ramda';

export type BusinessTypePayload = {
  businessType: BusinessVertical;
  locationType: LocationType;
  locationSubtypes: LocationType[];
  locationTypeSpecification?: string;
};

export const makeBusinessTypePayload = ({
  businessType,
  locationType: initialLocationType,
  locationSubtypes = [],
  locationTypeSpecification,
  ...props
}: BusinessTypePayload) => {
  // Determine the location type. Prioritize initialLocationType,
  // fall back to the first subtype if not set, and use 'other' as a last resort.
  const locationType =
    initialLocationType ||
    (locationSubtypes.length > 0 && !locationTypeSpecification
      ? locationSubtypes[0]
      : 'other');

  // If initialLocationType is not set, assume the first subtype is used as the locationType
  const effectiveSubtypes =
    initialLocationType || locationTypeSpecification
      ? locationSubtypes
      : locationSubtypes.slice(1);

  // Ensure locationTypeSpecification defaults to an empty string if not provided, or if the business type is not 'other'
  const effectiveLocationTypeSpecification =
    businessType === 'other' ? locationTypeSpecification || '' : '';

  // Construct and return the final object, appending any additional props
  return {
    businessType,
    locationType,
    locationSubtypes: effectiveSubtypes,
    locationTypeSpecification: effectiveLocationTypeSpecification,
    ...props
  };
};

export const handleCheckboxGroupChange = (
  value: string[],
  sectionValues: string[],
  selectedValues: CheckboxValueType[]
) => {
  // Find the differences between the selected values and the current values
  const differences = difference(sectionValues, selectedValues);
  // If there are no differences, we are adding values
  const isAdding = !differences.length;

  // If we are adding, we can uniquify the values and append
  if (isAdding) return uniq([...value, ...selectedValues]);
  // If we are removing, we can filter out the differences
  else return value.filter((val) => !differences.includes(val));
};
