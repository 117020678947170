export const killerQuestions = {
  noWaterImpact:
    'Kommen nur normale Haushaltsabwässer ins Wasser oder in die Kanalisation, und wird nichts anderes in die Gewässer geleitet oder beeinflusst?',
  onlyOneWastewaterFacility:
    'Gibt es nur eine Öl- / Fettabscheider / Abwasseranlagen?',
  noEnvironmentalFacilities:
    'Du bist nur Inhaber von Umweltanlagen wie Öl- oder Fettabscheidern oder Anlagen, die weniger als 10.000 Liter gemäß dem Wasserhaushaltsgesetz halten?',
  noAmericaBranches:
    'Das Unternehmen hat weder eigenständige Standorte im Ausland noch unabhängige Zweigstellen in den USA/Kanada, und es werden auch keine direkten Exporte oder Arbeiten vor Ort in den USA/Kanada durchgeführt, stimmt das so?',
  isSolidConstruction:
    'Das Gebäude ist robust gebaut mit massiven Materialien wie Beton oder Mauerwerk und einem festen Dach aus Ziegeln, Schiefer oder Betonplatten. Es ist vollständig geschlossen, ohne offene Seiten. Gebäude der Bauklasse I und II sind automatisch versichert. Klasse III, IV und V erfordern eine Anfrage und separate Prüfung.',
  inBuildupArea:
    'Das Gebäude oder der Betrieb liegt innerhalb einer Stadt oder Gemeinde, nicht am Rand oder in einem Gewerbe- oder Industriegebiet.',
  isPredominantlyOccupied:
    'Das versicherte Gebäude oder Objekt wird größtenteils genutzt (unter 40% Leerstand) und ist in gutem Zustand ohne Sanierungsbedarf oder Baumängel.',
  noRiskIncreasingBusinesses:
    'Weder innerhalb des Gebäudes noch in einem 10-Meter-Radius befinden sich betrieblich gefährliche Aktivitäten, Geschäfte oder Lager.',
  locatedInASolidBuilding:
    'Die versicherten Gegenstände sind in einem stabilen Gebäude mit massiven Wänden, Böden, Decken und Dächern untergebracht. Alle Räume sind vollständig geschlossen. Die Türen, die aus diesen versicherten Räumen führen, haben Sicherheitsschlösser mit Zylindern, die bündig mit der Außenseite der Türblätter oder Sicherheitsbeschläge abschließen.',
  noInsolvencyProceedings:
    'Es wurde kein Insolvenzverfahren gegen Sie oder Ihr Vermögen eingeleitet.',
  noLossEvents:
    'In den letzten 5 Jahren gab es keine Schäden in dem Bereich, für den Sie eine Versicherung beantragen oder haben.',
  noInsuranceIssues:
    'Falls Du eine vorherige Versicherung hattest, gibt es keine ausstehenden Beiträge, keine Kündigung seitens des Versicherers und kein Angebot für Sanierungsmaßnahmen aufgrund von Schäden.',
  noTourOperator: 'Es werden keine Reiseveranstalter Tätigkeiten durchgeführt',
  noClaimsOrInvestigations:
    'In den vergangenen 5 Jahren gab es keine Forderungen oder Untersuchungen gegen dich oder andere versicherte Personen im Rahmen der versicherten Tätigkeit. Und dir sind momentan keine Umstände bekannt, die zu einem möglichen Schaden führen könnten.',
  noAdditionalActivity:
    'Übst Du noch andere Tätigkeiten aus, die du versichern möchtest?',
  hasMoreThan10Marketing:
    'Erwirtschaftest Du mehr als 10 % deines Umsatzes mit Tätigkeiten als Reprobetrieb, Lettershop oder im Direktmailing bzw. Direktmarketing?',
  isProcessingContactsOrProductDesign:
    'Bietest Du Beratung und Dienstleistungen im Bereich des Handels mit privaten und gewerblichen Kontaktdaten (Adressen, Telefonnummern, E-Mail-Adressen) sowie im Design von Konsum- und Industriegütern an?',
  doesReviewPrint: 'Prüfst du die Druckdaten, bevor du einen Auftrag erteilst?',
  noSecurityBreaches:
    'In den letzten fünf Jahren gab es keine größeren Sicherheitsvorfälle in deinem Netzwerk, wie Hacker-Angriffe, Ausfälle von Diensten oder Probleme durch schädliche Software, die Schäden oder Kosten über 1.000 € verursacht haben. Du bist auch nicht über mögliche zukünftige Risiken informiert.',
  noSubsidiaryCompany:
    'Dein Unternehmen ist keine Tochtergesellschaft eines Unternehmens mit einem Umsatz von über 100 Millionen Euro.',
  itMeasuresImplemented:
    'Folgende IT-Sicherheitsmaßnahmen sind bei dir Pflicht:\n\nDu hast klare Berechtigungen für administrative Zugänge, die nur von IT-Verantwortlichen genutzt werden.\n\nDeine Datensicherung erfüllt bestimmte Kriterien, wie eine vollständige wöchentliche Sicherung für mindestens 30 Tage oder eine spezielle Offline- oder unveränderbare Online-Sicherung.\n\nSicherheitsupdates für Server, Computer und Netzwerkgeräte werden innerhalb von 30 Tagen nach Veröffentlichung des Updates installiert.\n\nDu nutzt keine veralteten Betriebssysteme ohne Sicherheitsupdates oder betreibst sie isoliert vom Internet.\n\nDu hast aktuelle Virenschutzprogramme für deine Systeme, außer für Apple-, Unix- und Linux-Betriebssysteme.\n\nAn allen Übergängen ins Internet hast du Firewalls für stationäre IT-Systeme.\n\nRegelmäßige Datensicherungen, mindestens wöchentlich bei geringerem Umsatz und täglich bei höherem Umsatz, auf separaten Systemen oder Datenträgern.',
  creditCardPayments:
    'In deinem Betrieb nutzen jährlich höchstens 20.000 Kunden Kreditkarten für Zahlungen. Wenn du Kreditkartenzahlungen akzeptierst, erfüllst du oder dein Zahlungsdienstleister den PCI DSS (Payment Card Industry Data Security Standard).',
  telephoneSystem:
    'Du nutzt entweder eine analoge Telefonanlage ohne Anrufbeantworter oder eine digitale Anlage mit geänderten Werkspasswörtern.',
  transferSecurity:
    'Bei Überweisungen über 10.000 EUR gilt ein 4-Augen-Prinzip und für Fernzugriffe auf Remote-Desktops oder E-Mails wird eine Zwei-Faktor-Authentifizierung genutzt.',
  externalDataCenters:
    'Externe Rechenzentren oder Cloud-Services werden genutzt, wenn der Drittanbieter ISO27001-Zertifizierungen oder ein TIA-942 Tier Level 3-Zertifikat hat.',
  industrialControlSystems:
    'Du benutzt keine Industrie-Steuerungsanlagen mit automatisierten Kontrollsystemen (ICS/SCADA) und führst kein produzierendes Gewerbe.',
  businessScope:
    'Deine Tätigkeit umfasst nicht:\n\nZahlungsabwicklung oder Inkassodienstleistungen, Glücksspiel, Pornografie oder Datenverarbeitung als Hauptgeschäft, Ratingagentur, Vermögensverwaltung oder Finanzberatung, Versicherungs- oder Finanzproduktvermittlung',
  usaPersonalData:
    'Du speicherst keine persönlichen Daten von Personen aus den USA oder verarbeitest im Jahr weniger als 20.000 Kreditkartendaten.',
  regulatoryLegalProceedings:
    'Bisher hat keine Aufsichtsbehörde oder staatliche Stelle Klage eingereicht, Ermittlungen eingeleitet oder Auskünfte zum Umgang mit sensiblen Daten angefordert.'
};
