import React, { useState } from 'react';

import { Datepicker, Loader, Uploader, YesNo } from '@/components';
import { useInsurance, useUpdateInsurance } from '@/services';
import { Checkbox, Form, Input, message, Modal, Select } from 'antd';
import {
  calculateYearlyPrice,
  formatMoney,
  nonNegativeRule,
  snakeToCamelCase
} from '@/utils';
import './styles.scss';
import { paymentPeriodOptions } from '@/enums';
import { ContractStatus } from '@/types/insurance';
import { useAttributesTranslations } from '@/services/attributes';
import { useAuth } from '@/providers';
import { useAdminList } from '@/utils/hooks/useAdminList';

const { TextArea } = Input;
const { Option } = Select;

const superAdmins = [
  'marc.winkelbauer+admin@surein.de',
  'david+admin@surein.de',
  'max+admin@surein.de',
  'operations+admin@surein.de',
  'david.graetz+admin@surein.de'
] as const;

const contractStatusOptions: { label: string; value: ContractStatus }[] = [
  { label: 'Active', value: 'aktiv' },
  { label: 'Cancelled', value: 'storniert' },
  { label: 'Dormant', value: 'ruhend' },
  { label: 'Expiration', value: 'ablauf' },
  { label: 'Request', value: 'antrag' },
  { label: 'Rejection', value: 'ablehnung' },
  { label: 'Review', value: 'review' },
  { label: 'Validated', value: 'validated' }
];

const contractTypeOptions = [
  { label: 'Own', value: 'eigen' },
  { label: 'Foreign', value: 'fremd' },
  { label: 'Correspondence', value: 'korrespondenz' },
  { label: 'Open / unclear', value: 'unklar' }
];

const UnlimitedSumInsured = ({ checked, onChange }: any) => (
  <Checkbox onChange={onChange} checked={checked}>
    {checked ? (
      <strong className="add-recommendation-unlimited">Unlimited</strong>
    ) : (
      <Form.Item
        name="amountInsured"
        rules={[{ required: true, message: 'Required!' }, nonNegativeRule]}
      >
        <Input type="number" addonBefore="€" />
      </Form.Item>
    )}
  </Checkbox>
);

interface EditInsuranceModalProps {
  visible: boolean;
  onClose: (close?: boolean) => void;
  id: string;
}

export const EditInsuranceModal = ({
  visible,
  onClose,
  id
}: EditInsuranceModalProps) => {
  const { user } = useAuth();
  const { adminList } = useAdminList();

  const [form] = Form.useForm();
  const { data: insurance, isLoading } = useInsurance(id, {
    enabled: Boolean(id)
  });
  const updateInsuranceMutation = useUpdateInsurance(id);
  const [isUpdating, setIsUpdating] = useState(false);
  const { isLoading: isLoadingAttributes, data: attributes } =
    useAttributesTranslations();

  if (isLoading || !insurance || isLoadingAttributes) return <Loader />;

  const handleSubmit = async (values: any) => {
    let payload = values;
    if (values.closingCommission === '') {
      payload = {
        ...values,
        closingCommission: null
      };
    }
    setIsUpdating(true);
    await updateInsuranceMutation.mutateAsync(payload);
    setIsUpdating(false);

    if (
      values.paymentPeriod !== insurance.paymentPeriod ||
      values.grossPrice !== insurance.grossPrice
    ) {
      message.info('The yearly net price has been updated');
    }

    onClose();
  };

  const attributesList = insurance.insuranceTemplate
    ? insurance.insuranceTemplate.attributes.map(({ code }) =>
        snakeToCamelCase(code)
      )
    : null;

  const netYearlyPrice = calculateYearlyPrice(
    insurance.netPrice ?? 0,
    insurance.paymentPeriod ?? 'yearly'
  );
  return (
    <Modal
      centered
      onOk={() => form.submit()}
      onCancel={() => onClose(false)}
      okText="Update"
      title="Update Insurance"
      closable={false}
      className="add-company-modal"
      open={visible}
      width={976}
      confirmLoading={isUpdating}
    >
      <Form
        initialValues={insurance}
        layout="vertical"
        form={form}
        style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
        onFinish={handleSubmit}
      >
        <div className="add-insurance-row">
          <Form.Item
            label="Gross price"
            className="add-insurance-form-item"
            initialValue={0}
            name="grossPrice"
            rules={[nonNegativeRule]}
          >
            <Input type="number" addonBefore="€" placeholder="monthly" />
          </Form.Item>
          <Form.Item
            label="Net price yearly"
            className="add-insurance-form-item"
            name="netPriceYearly"
            rules={[nonNegativeRule]}
          >
            <p className="net-price-yearly">{formatMoney(netYearlyPrice)}</p>
          </Form.Item>
        </div>
        <Form.Item label="Payment period" name="paymentPeriod">
          <Select>
            {paymentPeriodOptions.map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Policy Number" name="policyNumber">
          <Input />
        </Form.Item>
        <Form.Item label="RaVe-Nummer" name="agreementNumber">
          <Input readOnly disabled={true} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea autoSize={{ minRows: 6 }} />
        </Form.Item>
        <Form.Item label="Tariff (product name)" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          label="Insurance Sum"
          name="amountInsuredUnlimited"
          valuePropName="checked"
        >
          <UnlimitedSumInsured />
        </Form.Item>
        <Form.Item label="Risk Insured" name="risksInsured">
          <Input />
        </Form.Item>
        <Form.Item label="Request Date" name="createdAt">
          <Datepicker
            placeholder="Eingeben"
            disabled
            value={insurance.createdAt}
          />
        </Form.Item>
        <Form.Item label="Start Date" name="startDate">
          <Datepicker placeholder="Eingeben" />
        </Form.Item>
        <Form.Item label="End Date" name="endDate">
          <Datepicker placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="Automatic renewal"
          name="shouldRenewAutomatically"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item initialValue={0} label="Deductible" name="deductible">
          <Input type="number" addonBefore="€" />
        </Form.Item>
        <Form.Item initialValue={0} label="Commission Rate" name="commission">
          <Input type="number" addonBefore="%" />
        </Form.Item>
        <Form.Item
          initialValue={0}
          label="Closing Commissions"
          name="closingCommission"
        >
          <Input type="number" addonBefore="€" />
        </Form.Item>
        <Form.Item label="Contract status" name="contractStatus">
          <Select>
            {contractStatusOptions.map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Contract type" name="contractType">
          <Select>
            {contractTypeOptions.map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Next Payment Date" name="contractPaymentNextDate">
          <Datepicker placeholder="Eingeben" />
        </Form.Item>
        {attributesList && (
          <div className="gridContainer">
            <div className="attributeContainer">
              <span>Attributes</span>
              <span>German</span>
              <span>English</span>
              <span>Yes/No</span>
            </div>
            {attributesList.map((code) => (
              <div className="attributeContainer">
                <i>{attributes?.getAttributeLabel(code)}</i>
                <Form.Item name={['parametersValues', 'de', code]}>
                  <Input />
                </Form.Item>
                <Form.Item name={['parametersValues', 'en', code]}>
                  <Input />
                </Form.Item>
                <Form.Item name={['yesNoValues', code]}>
                  <YesNo />
                </Form.Item>
              </div>
            ))}
          </div>
        )}
        <Form.Item label="Commission paid at" name="commissionPaidAt">
          <Datepicker
            placeholder="Eingeben"
            disabled={!superAdmins.includes(user.email)}
          />
        </Form.Item>
        <Form.Item label="Commission paid to" name="commissionPaidTo">
          <Select
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toLowerCase()
                .startsWith(input.toLowerCase())
            }
            placeholder="Choose a consultant"
            options={adminList.map(({ auth0, email }) => ({
              value: auth0,
              label: email === user.email ? `Assign to me (${email})` : email
            }))}
            disabled={!superAdmins.includes(user.email)}
          >
            {adminList.map(({ auth0, email }) => (
              <Option key={auth0} value={auth0}>
                {email}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Uploader
        max={100}
        files={insurance.files || []}
        queryKeys={['insurance', insurance.insuranceId]}
        belongsTo={insurance.insuranceId}
        type="insurance"
        extended
      />
    </Modal>
  );
};
