import React, { useEffect, useState } from 'react';

import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import './styles.scss';

interface CsvButtonProps {
  forId: string;
  filename: string;
}

const CsvButton = ({ forId, filename }: CsvButtonProps) => {
  const [csvLink, setCsvLink] = useState('');

  useEffect(() => {
    const data = [];
    const rows = document.getElementById(forId)?.querySelectorAll('table tr');
    if (!rows || rows?.length === 0) return;

    const toReformat = columnIndicesToReformat(
      rows[0].querySelectorAll('td, th')
    );

    for (let i = 0; i < rows.length; i++) {
      const row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (let j = 0; j < cols.length; j++) {
        let cell = cols[j].textContent || '';
        if (toReformat.includes(j) && i > 0 && cell.length > 0)
          cell = convertStringToEuInt(cell);
        if (cell.includes(',')) cell = `"${cell}"`;
        const input = cols[j].getElementsByTagName('input')[0];
        if (input) {
          if (input.className.includes('checkbox'))
            cell = input.checked.toString();
          else cell = input.value;
        }

        if (j !== cols.length - 1 || cols[j].textContent) row.push(cell);
      }

      data.push(row.join(','));
    }

    setCsvLink(
      URL.createObjectURL(new Blob([data.join('\n')], { type: 'text/csv' }))
    );
  }, [forId]);

  return (
    <Button type="link" download={filename} href={csvLink}>
      Export to CSV
    </Button>
  );
};

interface FinancialsToCsvProps {
  columns: ColumnsType<object>;
  records: object[];
  isLoading: boolean;
}

export const FinancialsToCsv = ({
  columns,
  records,
  isLoading
}: FinancialsToCsvProps) => {
  if (isLoading) {
    return (
      <Button type="link" disabled>
        Export to CSV
      </Button>
    );
  }

  return (
    <>
      <CsvButton forId="financialTable" filename="financials" />
      <Table
        id="financialTable"
        style={{ display: 'none' }}
        rowKey="insuranceId"
        columns={columns}
        dataSource={records}
        pagination={false}
      />
    </>
  );
};

const convertStringToEuInt = (input: string) => {
  const number = parseFloat(input);
  return number.toLocaleString('de-DE');
};

const columnIndicesToReformat = (columnLabels: NodeListOf<HTMLElement>) => {
  const colNamesToReformat = [
    'Gross Total Yearly',
    'Net Total',
    'Commission Rate (%)',
    'Commission total (€)',
    'Closing commissions (€)'
  ];
  const colIndicesToReformat = [];
  for (let i = 0; i < columnLabels.length; i++) {
    if (colNamesToReformat.includes(columnLabels[i].innerText))
      colIndicesToReformat.push(i);
  }
  return colIndicesToReformat;
};
