import React from 'react';

import { Loader, Table } from '@/components';
import { CarrierLogo } from '@/components/CarrierLogo';
import { CategoryLabelMap } from '@/enums';
import {
  useArchiveInsuranceProduct,
  useGetActiveInsuranceProducts,
  useGetArchivedInsuranceProducts
} from '@/services';
import { InsuranceProductWithCarrier } from '@/types/insurance';
import { useItemDialog, useLocalStorage } from '@/utils';
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm, Tabs, TabsProps } from 'antd';
import { ProductModal } from './ProductModal';
import './styles.scss';
import { useLocationTypes } from '@/services/locationTypes';

export const Products = () => {
  const { data: archivedProducts, isLoading: isArchivedProductsLoading } =
    useGetArchivedInsuranceProducts();

  const { data: activeProducts, isLoading: isActiveProductsLoading } =
    useGetActiveInsuranceProducts();

  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const archiveProductMutation = useArchiveInsuranceProduct();
  const { isOpen, openItemDialog, item, closeItemDialog } = useItemDialog<{
    insuranceProductId: string;
    isDuplicate?: boolean;
  }>();
  const [sortsAndFilters, setSortsAndFilters] = useLocalStorage(
    'productsSortAndFilters',
    {
      sorts: {},
      filters: {}
    }
  );

  if (
    isArchivedProductsLoading ||
    isActiveProductsLoading ||
    locationTypesLoading
  )
    return <Loader />;

  const ProductActions = ({
    insuranceProductId,
    isArchived
  }: {
    insuranceProductId: string;
    isArchived: boolean;
  }) => (
    <Menu
      items={[
        {
          key: 'Edit',
          label: 'Edit',
          onClick: () => openItemDialog({ insuranceProductId })
        },
        {
          key: 'Duplicate',
          label: 'Duplicate',
          onClick: () =>
            openItemDialog({ insuranceProductId, isDuplicate: true })
        },
        {
          key: 'Confirm',
          label: (
            <Popconfirm
              title={
                isArchived
                  ? 'Are you sure to reactivate this product?'
                  : 'Are you sure to archive this product?'
              }
              onConfirm={() =>
                archiveProductMutation.mutateAsync({
                  insuranceProductId,
                  isArchived: !isArchived
                })
              }
            >
              <div style={{ color: isArchived ? '#52C41A' : 'red' }}>
                {isArchived ? 'Reactivate' : 'Archive'}
              </div>
            </Popconfirm>
          )
        }
      ]}
    />
  );

  const makeRecords = (
    search: string = '',
    data: InsuranceProductWithCarrier[] = []
  ) => {
    if (search && data) {
      return (
        data?.filter(
          ({ name, carrier }) =>
            name.toLowerCase().includes(search.toLowerCase()) ||
            carrier?.name?.toLowerCase().includes(search.toLowerCase())
        ) || []
      );
    }
    return data || [];
  };

  const columns = [
    {
      title: 'Carrier logo',
      dataIndex: ['carrier', 'logoUrl'],
      render: (logo: string) => <CarrierLogo name={logo} />
    },
    {
      title: 'Carrier',
      dataIndex: ['carrier', 'name'],
      sortOrder:
        JSON.stringify(sortsAndFilters.sorts.field) ===
        JSON.stringify(['carrier', 'name'])
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (
        a: InsuranceProductWithCarrier,
        b: InsuranceProductWithCarrier
      ) => a?.carrier?.name?.localeCompare(b?.carrier?.name)
    },
    {
      title: 'Tariff',
      dataIndex: 'name',
      sortOrder:
        sortsAndFilters.sorts.field === 'name'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (
        a: InsuranceProductWithCarrier,
        b: InsuranceProductWithCarrier
      ) => a?.name?.localeCompare(b?.name)
    },
    {
      title: 'Category',
      dataIndex: 'categoryId',
      render: (category: string) => CategoryLabelMap[category],
      filters: [
        {
          text: 'Liability insurance',
          value: 'LI'
        },
        {
          text: 'Content insurance',
          value: 'CO'
        },
        {
          text: 'Other insurances',
          value: 'other'
        }
      ],
      filteredValue: sortsAndFilters.filters.categoryId || null,
      onFilter: (
        value: string,
        { categoryId }: InsuranceProductWithCarrier
      ) => {
        if (value === 'other') return !['LI', 'CO'].includes(categoryId);

        return categoryId === value;
      }
    },
    {
      title: 'Vertical',
      dataIndex: 'businessVertical',
      render: (vertical: string) =>
        locationTypesData?.getVerticalLabel(vertical) || '- Default -',
      sortOrder:
        sortsAndFilters.sorts.field === 'businessVertical'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (
        a: InsuranceProductWithCarrier,
        b: InsuranceProductWithCarrier
      ) => a?.businessVertical?.localeCompare(b?.businessVertical)
    },
    {
      title: '',
      render: (
        __: unknown,
        { insuranceProductId, isArchived }: InsuranceProductWithCarrier
      ) => (
        <Dropdown
          overlay={
            <ProductActions
              insuranceProductId={insuranceProductId}
              isArchived={isArchived}
            />
          }
        >
          <MenuOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      )
    }
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Active Products',
      children: (
        <Table
          onButtonClick={() => openItemDialog()}
          searchPlaceholder="Search by tariff or carrier name"
          buttonText="New Insurance Product"
          setSortsAndFilters={setSortsAndFilters}
          rowKey="insuranceProductId"
          columns={columns}
          records={(search) => makeRecords(search, activeProducts)}
          paginationKey="productsPagination"
          searchKey="productsSearch"
        />
      )
    },
    {
      key: '2',
      label: 'Archived',
      children: (
        <Table
          onButtonClick={() => openItemDialog()}
          searchPlaceholder="Search by tariff or carrier name"
          buttonText="New Insurance Product"
          setSortsAndFilters={setSortsAndFilters}
          rowKey="insuranceProductId"
          columns={columns}
          records={(search) => makeRecords(search, archivedProducts)}
          paginationKey="productsPagination"
          searchKey="productsSearch"
        />
      )
    }
  ];

  return (
    <div>
      <div className="table-container">
        <Tabs items={tabItems} />
      </div>
      {isOpen && (
        <ProductModal
          insuranceProductId={item?.insuranceProductId}
          isDuplicated={item?.isDuplicate}
          visible={isOpen}
          onClose={closeItemDialog}
        />
      )}
    </div>
  );
};
