import { Form, Select } from 'antd';

import { useLocationTypes } from '@/services/locationTypes';

function useAvailableVerticalTemplates(
  verticals: string[]
): { label: string; value: string }[] {
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  return verticals.map((item) => ({
    value: item,
    label: locationTypesLoading
      ? 'Loading...'
      : locationTypesData?.getVerticalLabel(item) || '- Default -'
  }));
}

export function VerticalTemplateSelector({
  verticals,
  onChange
}: {
  verticals: string[];
  onChange?: (value: string) => void;
}) {
  const opts = useAvailableVerticalTemplates(verticals);

  return (
    <Form.Item
      label="Vertical"
      name="business_vertical"
      rules={[{ required: true, message: 'Required!' }]}
    >
      <Select disabled={opts.length === 0} options={opts} onChange={onChange} />
    </Form.Item>
  );
}
