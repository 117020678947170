import { api } from './api';

export const getAllTransferAccount = () =>
  api.get('/admin/insurances_with_transfer_accounts');

export const startAutomationTA = ({ insuranceId }: StartAutomationTAPayload) =>
  api.post('/automation', {
    insuranceId: insuranceId,
    type: 'transfer_account'
  });

export const markAsCompletedTA = ({
  automationCaseTransferAccountId
}: MarkAsCompletedTAPayload) =>
  api.put(`/automation/${automationCaseTransferAccountId}/markAsCompleted`);

type StartAutomationTAPayload = {
  insuranceId: string;
};

type MarkAsCompletedTAPayload = {
  automationCaseTransferAccountId: string;
};
