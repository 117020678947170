import type { DocumentDependency, OtherDependency } from '@/api';
import {
  useInsuranceRequestProtectionDependencies,
  useRequestProtection
} from '@/services';
import { Company } from '@/types/company';
import { InsuranceApiResult, isProtectionRequested } from '@/types/insurance';
import {
  Alert,
  Badge,
  Form,
  message,
  Modal,
  Radio,
  Space,
  Typography
} from 'antd';

export interface ValidateModalProps {
  visible: boolean;
  onClose: (value: boolean) => void;
  data: {
    insurance: InsuranceApiResult;
    company: Company;
  };
}

const DocumentStatus = ({
  isMet,
  label
}: {
  // file: File | undefined;
  isMet: boolean;
  label: string;
}) => (
  <Space direction="horizontal" style={{ marginRight: '1rem' }}>
    {isMet ? <Badge color="green" /> : <Badge color="red" />}
    {label}
  </Space>
);

const EmailPreviewLabel = ({ email }: { email?: string | null }) => (
  <>{!!email ? `(${email})` : `(not available)`}</>
);

const OtherDependencies = ({
  dependencies
}: {
  dependencies?: Array<OtherDependency>;
}) => {
  // TODO: this should handle other dependencies
  const dependency = dependencies?.find((d) => d.dependency === 'email');

  if (!dependency) {
    return <></>;
  }

  return (
    <>
      <Typography.Title level={4}>Select email address</Typography.Title>

      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Email is required' }]}
        initialValue={dependency.emailOptions[0]?.email}
      >
        <Radio.Group>
          <Space direction="vertical">
            {dependency.emailOptions.map(({ label, email }) => (
              <Radio value={email} disabled={!email}>
                {label} <EmailPreviewLabel email={email} />
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

const DocumentDependencies = ({
  dependencies
}: {
  dependencies?: Array<DocumentDependency>;
}) => {
  if (!dependencies) {
    return <></>;
  }

  return (
    <>
      <Typography.Title level={4}>Documents ready</Typography.Title>
      {dependencies.map((dependency) => (
        <Form.Item
          name={dependency.dependency}
          rules={[
            {
              validator: (_, value) => {
                return value
                  ? Promise.resolve()
                  : Promise.reject(`${dependency.dependency} is not ready`);
              }
            }
          ]}
          initialValue={dependency.isMet}
          className="required-files"
        >
          <DocumentStatus
            isMet={dependency.isMet}
            label={dependency.dependency}
          />
        </Form.Item>
      ))}
    </>
  );
};

export const SendToCarrierModal = ({
  visible,
  onClose,
  data: { insurance }
}: ValidateModalProps) => {
  const requestProtectionMutation = useRequestProtection(
    insurance.insuranceId,
    insurance.locationId
  );

  const {
    data: requestProtectionDependencies,
    isLoading: isLoadingDependencies
  } = useInsuranceRequestProtectionDependencies(insurance.insuranceId);

  const [form] = Form.useForm();

  const handleSubmit = async (submitData: {
    email: string;
    coverNoteFileId: string;
    poaFileId: string;
  }) => {
    try {
      const res = await requestProtectionMutation.mutateAsync(submitData);

      if (res.status === 'PROTECTION_REQUESTED') {
        message.success(
          `The email has been successfully sent to: ${submitData.email}`
        );
      }

      if (res.status === 'FAILED') {
        message.error(
          `The email has not been sent because: ${res.reason}. Please contact tech support and check operations@surein.de email inbox.`
        );
      }
      onClose(false);
    } catch (error) {
      message.error(
        `The email has not been sent. Please contact tech support and check operations@surein.de email inbox.`
      );
    }
  };

  return (
    <Modal
      centered
      title="Send to carrier"
      closable={false}
      open={visible}
      onCancel={() => onClose(false)}
      onOk={form.submit}
      confirmLoading={
        requestProtectionMutation.isLoading || isLoadingDependencies
      }
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        {isProtectionRequested(insurance) && (
          <Alert
            message="The insurance has already been requested."
            type="info"
            showIcon
            style={{ marginBottom: '1rem' }}
          />
        )}

        <DocumentDependencies
          dependencies={requestProtectionDependencies?.documents}
        />

        <OtherDependencies
          dependencies={requestProtectionDependencies?.other}
        />
      </Form>
    </Modal>
  );
};
