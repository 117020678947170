import React from 'react';

import { useLocalStorage, noop } from '@/utils';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Table as AntdTable, TablePaginationConfig } from 'antd';
import './styles.scss';
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource
} from 'antd/lib/table/interface';

const { Search } = Input;

interface TableProps {
  rowKey: string;
  columns: any[];
  records: (search?: string) => readonly any[];
  setSortsAndFilters?: (sortsAndFilters: any) => void;
  onPaginationChange?: (page: number) => void;
  paginationKey: string;
  buttonText?: string;
  searchKey: string;
  searchPlaceholder?: string;
  buttonIcon?: any;
  onButtonClick?: () => void;
  scroll?: {
    x?: number | true | string;
    y?: number | string;
  };
}

export const Table = ({
  rowKey,
  columns,
  records,
  setSortsAndFilters = noop,
  onPaginationChange = noop,
  paginationKey,
  buttonText,
  searchKey,
  searchPlaceholder,
  buttonIcon = <PlusCircleOutlined />,
  onButtonClick,
  ...rest
}: TableProps) => {
  const hasSearch = Boolean(searchPlaceholder);

  const [search, setSearch] = useLocalStorage(searchKey, '');
  const [pagination, setPagination] = useLocalStorage(paginationKey, {
    hideOnSinglePage: true,
    pageSize: 8
  });
  const [filteredRecordsLength, setFilteredRecordsLength] = useLocalStorage(
    `filteredRecordsLength_${rowKey}`
  );

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorts: SorterResult<any> | SorterResult<any>[],
    { currentDataSource }: { currentDataSource: TableCurrentDataSource<any>[] }
  ) => {
    setSortsAndFilters({ sorts: sorts, filters: filters });
    setFilteredRecordsLength(currentDataSource?.length);
    setPagination(pagination);
  };

  return (
    <>
      <div className="dashboard-control-panel">
        {hasSearch && (
          <Search
            placeholder={searchPlaceholder}
            value={search}
            size="large"
            className="dashboardSearch"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        )}
        {buttonText && (
          <Button
            size="large"
            type="primary"
            onClick={onButtonClick}
            icon={buttonIcon}
          >
            {buttonText}
          </Button>
        )}
      </div>
      <AntdTable
        {...rest}
        rowKey={rowKey}
        columns={columns}
        dataSource={hasSearch ? records(search) : (records as any)} // fixme, ComboFinancials is misusing this, should be function
        onChange={onTableChange}
        pagination={{ ...pagination, onChange: onPaginationChange }}
        footer={(page) => (
          <div>
            Displaying {page.length} of{' '}
            {filteredRecordsLength || records?.length}
          </div>
        )}
      />
    </>
  );
};
