import {
  getPayments,
  getPaymentById,
  createPayment,
  deletePayment,
  updatePayment,
  CreatePaymentPayload,
  UpdatePaymentPayload
} from '@/api';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery } from 'react-query';
import { PaymentInfo } from '@/types/payment';

const keys = {
  payments: 'payments',
  payment: 'payment'
};

export const usePayments = (companyId: string) =>
  useQuery([keys.payments, companyId], () => getPayments(companyId));

export const usePayment = (id: string, options: { enabled: boolean }) =>
  useQuery([keys.payment, id], () => getPaymentById(id), options);

export const useCreatePayment = (companyId: string) =>
  useInvalidateOnSuccessMutation<PaymentInfo>(
    [keys.payments, companyId],
    ({ name, bic, iban, priority }: CreatePaymentPayload) =>
      createPayment({ name, bic, iban, priority, companyId })
  );

export const useUpdatePayment = (id: string) =>
  useInvalidateOnSuccessMutation(
    [keys.payment],
    ({ name, bic, iban, priority }: UpdatePaymentPayload) =>
      updatePayment({ id, name, bic, iban, priority })
  );

export const useDeletePayment = (id?: string) =>
  useInvalidateOnSuccessMutation(keys.payments, (paymentId) =>
    deletePayment(paymentId || id)
  );
