import { useState } from 'react';
import { useModal } from './useModal';

export const useItemDialog = <T = undefined>(
  defaultItem: T | undefined = undefined,
  updateOnClose = true
) => {
  const { isOpen, open, close } = useModal();
  const [item, setItem] = useState(defaultItem);

  const openItemDialog = (newItem: T | undefined = undefined) => {
    setItem(newItem);
    open();
  };

  const closeItemDialog = () => {
    close();
    if (updateOnClose) setItem(defaultItem);
  };

  return {
    item,
    isOpen,
    openItemDialog,
    closeItemDialog
  };
};
