import React, { useState } from 'react';

import { Modal, Form, message, Checkbox, Alert } from 'antd';
import './styles.scss';
import { requestSignPoaV2 } from '@/api';

interface PaymentModalProps {
  visible: boolean;
  companyId: string;
  MDs: { email: string; auth0: string }[];
  onClose: (arg: boolean) => void;
}

function makeInitialData(items: { auth0: string }[]): { userIds: string[] } {
  return {
    userIds: items.map(({ auth0 }) => auth0)
  };
}

const UnsentEmailsWarning = ({ unsentEmails }: { unsentEmails: string[] }) => (
  <Alert
    type="warning"
    showIcon
    message="Partial failure"
    description={
      <div>
        <p>There was a problem with sending some messages</p>
        <ul>
          {unsentEmails.map((email) => (
            <li key={email}>{email}</li>
          ))}
        </ul>
      </div>
    }
  />
);

export const SendPOAModal = ({
  visible,
  onClose,
  companyId,
  MDs
}: PaymentModalProps) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [unsentEmails, setUnsentEmails] = useState<string[] | null>(null);

  const onFinish = async (data: { userIds: string[] }) => {
    setUnsentEmails(null);
    setIsLoading(true);
    try {
      const res = await requestSignPoaV2({
        companyId,
        userIds: data.userIds
      });

      setIsLoading(false);

      if (res.partialFailure) {
        setUnsentEmails(res.failedEmails ?? []);
      } else if (res.success) {
        message.success('Poa mail send!');
      } else {
        message.error('Error');
      }
    } catch (e) {
      setIsLoading(false);
      message.error('Error');
    }
  };

  const validateCheckbox = (_rule: unknown, value: string[]): Promise<void> => {
    if (value && value.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject('Please select at least one email');
  };

  return (
    <Modal
      centered
      onCancel={() => {
        setUnsentEmails(null);
        onClose(false);
      }}
      okText={'Send'}
      onOk={() => form.submit()}
      cancelText="Cancel"
      title="Send POA"
      closable={false}
      open={visible}
      confirmLoading={isLoading}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={makeInitialData(MDs)}
      >
        <Form.Item name="userIds" rules={[{ validator: validateCheckbox }]}>
          <Checkbox.Group
            options={MDs.map(({ email, auth0 }) => ({
              label: email,
              value: auth0
            }))}
          />
        </Form.Item>
      </Form>
      {unsentEmails && <UnsentEmailsWarning unsentEmails={unsentEmails} />}
    </Modal>
  );
};
