import React from 'react';

import { Loader, LocationAutocomplete } from '@/components';
import { useCompany, useUpdateAddress, useUpdateCompany } from '@/services';
import { locationAutocompleteRule } from '@/utils';
import { Modal, Form, Input } from 'antd';
import { pick } from 'ramda';
import './styles.scss';

interface EditCompanyModalProps {
  visible: boolean;
  onClose: (visible: boolean) => void;
  id: string;
}

export const EditCompanyModal = ({
  visible,
  onClose,
  id
}: EditCompanyModalProps) => {
  const [form] = Form.useForm();
  const { data, isLoading } = useCompany(id);
  const updateCompanyMutation = useUpdateCompany(id);
  const updateAddressMutation = useUpdateAddress();

  if (isLoading) return <Loader />;

  const onSubmit = () =>
    form.validateFields().then(
      ({ name, address }) =>
        data &&
        Promise.all([
          updateCompanyMutation.mutateAsync({ name }),
          updateAddressMutation.mutate({
            ...address,
            id: data.address.addressId
          })
        ]).then(() => onClose(false))
    );

  return (
    <Modal
      centered
      onOk={onSubmit}
      onCancel={() => onClose(false)}
      okText="Save"
      title="Edit Company"
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form
        initialValues={pick(['name', 'address'], data)}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Company Name"
          name="name"
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="Company Address"
          name="address"
          rules={[
            { required: true, message: 'Required!' },
            locationAutocompleteRule
          ]}
        >
          <LocationAutocomplete name="address" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
