import { useCallback, useRef } from 'react';

/**
 * useThrottleCallback
 *
 * Returns a throttled version of the given callback function that will only execute
 * at most once per specified delay.
 *
 * @param {Function} callback - The function to be throttled.
 * @param {number} delay - The delay in milliseconds for the throttle.
 *
 * @returns {Function} - A throttled version of the callback.
 */
export function useThrottleCallback<T extends (...args: any[]) => any>(
  callback: T,
  delay: number
) {
  const lastCall = useRef(0); // Holds the timestamp of the last function call.
  const timeout = useRef<NodeJS.Timeout>(); // Stores the active timeout.

  return useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();

      // Check if the last call was made more than the delay period ago.
      if (now - lastCall.current >= delay) {
        lastCall.current = now;
        callback(...args);
      } else {
        // Clear any previous timeout if there's one set
        if (timeout.current) {
          clearTimeout(timeout.current);
        }

        // Set a timeout to call the callback once the throttle delay has passed
        timeout.current = setTimeout(
          () => {
            lastCall.current = Date.now();
            callback(...args);
          },
          delay - (now - lastCall.current)
        );
      }
    },
    [callback, delay]
  );
}
