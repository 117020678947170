export const fileTypes: Record<string, string> = {
  pdf: 'application/pdf',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg'
};

export const fileCategories = {
  contract: 'Vertragsdokumente',
  offer: 'Angebotsdokumente',
  application: 'Antragsdokumente',
  invoice: 'Beitragsrechnungen',
  incident: 'Störfälle',
  claim: 'Schadendokumente',
  cover_note: 'Deckungsnote'
};
