import { useAuth } from '@/providers';
import { useListAdmins } from '@/services';
import { isNotEmpty } from '../type';

export function useAdminList() {
  const { user } = useAuth();
  const { data: adminList, isLoading } = useListAdmins();
  const newAdminList = adminList?.map((admin) => {
    if (admin.auth0 === user.sub) {
      return {
        auth0: user.sub,
        email: user.email
      };
    } else {
      return admin;
    }
  });
  const finalAdminList = [
    newAdminList?.find(({ auth0 }) => auth0 === user.sub),
    ...(newAdminList ?? []).filter(({ auth0 }) => auth0 !== user.sub)
  ].filter(isNotEmpty);
  return { adminList: finalAdminList, isLoading };
}
