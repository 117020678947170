import React, { ReactNode } from 'react';

import { Form } from 'antd';

interface UpdatableFieldProps {
  children: ReactNode[] | ReactNode | (() => React.JSX.Element | null);
}

export const UpdatableField = ({ children }: UpdatableFieldProps) => (
  <Form.Item shouldUpdate noStyle>
    {children}
  </Form.Item>
);
