import {
  MutationFunction,
  QueryKey,
  useMutation,
  useQueryClient
} from 'react-query';
import { pack } from '../list';

export const useInvalidateOnSuccessMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = any,
  TContext = unknown
>(
  keyToInvalidate: QueryKey,
  mutationFn: MutationFunction<any, any>
) => {
  const queryClient = useQueryClient();
  return useMutation<TData, TError, TVariables, TContext>(mutationFn, {
    onSuccess: () => {
      pack(keyToInvalidate).forEach((key: QueryKey | unknown) =>
        queryClient.invalidateQueries(key as QueryKey)
      );
    }
  });
};
