import { updateAddress, UpdateAddressPayload } from '@/api';
import { useInvalidateOnSuccessMutation } from '@/utils';

export const useUpdateAddress = (addressId?: string) =>
  useInvalidateOnSuccessMutation(
    ['company', 'companies'],
    ({
      id,
      street,
      streetNr,
      postalCode,
      city,
      country,
      googleLocation
    }: UpdateAddressPayload) =>
      updateAddress({
        id: addressId || id,
        street,
        streetNr,
        postalCode,
        city,
        country,
        googleLocation
      })
  );
