import {
  createLocation,
  deleteLocation,
  getCompanyLocations,
  getKillerAnswers,
  getLocationHome,
  postKillerQuestions,
  updateLocation,
  updateLocationType
} from '@/api';
import { Location, LocationHome } from '@/types/location';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { UseQueryOptions, useQuery } from 'react-query';

const keys = {
  locations: 'locations',
  location: 'location',
  killerQuestions: 'killerQuestions'
};

interface LocationOptionsSelectResult {
  value: Location['locationId'];
  label: Location['name'];
}

export const useCompanyLocations = (
  id: string,
  options: UseQueryOptions<
    Location[],
    unknown,
    LocationOptionsSelectResult[],
    string[]
  >
) => useQuery([keys.locations, id], () => getCompanyLocations(id), options);

export const useLocationHome = (
  id: string,
  options: UseQueryOptions<LocationHome, unknown, LocationHome, string[]> = {}
) => useQuery([keys.location, id], () => getLocationHome(id), options);

export const useCreateLocation = (companyId: string) =>
  useInvalidateOnSuccessMutation<{ item: Location }>(
    [
      [keys.locations, companyId],
      ['company', companyId]
    ],
    ({ name, address, locationType }) =>
      createLocation({
        name,
        address,
        locationType,
        companyId
      })
  );

export const useUpdateLocation = (id: string, addressId: number) =>
  useInvalidateOnSuccessMutation<{ item: Location }>(
    [[keys.location, id], 'company', keys.locations],
    ({ name, locationType, address }) =>
      updateLocation({
        id,
        name,
        locationType,
        address: { id: addressId, ...address }
      })
  );

export const useUpdateLocationType = (id: string) =>
  useInvalidateOnSuccessMutation([[keys.location, id]], ({ locationType }) =>
    updateLocationType({ id, locationType })
  );

export const useDeleteLocation = (id?: string) =>
  useInvalidateOnSuccessMutation([keys.locations, 'company'], (locationId) =>
    deleteLocation(locationId || id)
  );

export const useSaveKillerQuestions = (id: string) =>
  useInvalidateOnSuccessMutation([keys.killerQuestions], ({ questions }) =>
    postKillerQuestions({ locationId: id, questions })
  );

export const useKillerAnswers = (id: string, categoryIds: string[]) =>
  useQuery([keys.killerQuestions, id], () =>
    getKillerAnswers({ id, categoryIds })
  );
