import { useLocationTypes } from '@/services/locationTypes';
import { Form, Input, Select } from 'antd';
import './styles.scss';
import { keys } from 'ramda';
import { UpdatableField } from '../UpdatableField';
import { VerticalsMap } from '@/api/locationTypes';

const useBusinessTypeOptions = (
  locationsLabels: Record<string, string> | undefined
) => {
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  if (!locationsLabels || locationTypesLoading || !locationTypesData) {
    return [];
  }

  return keys(locationTypesData?.locationsLabels || {})
    .sort((a, b) =>
      locationTypesData
        ?.getLocationLabel(a)
        .localeCompare(locationTypesData?.getLocationLabel(b))
    )
    .map((key) => ({
      label: locationTypesData?.getLocationLabel(key),
      value: key
    }));
};

const matchBusinessTypeFromLocationType = (
  locationType: string,
  verticalsMap: VerticalsMap
) => {
  return (
    Object.entries(verticalsMap).find(([_, locationTypes]) =>
      locationTypes.includes(locationType)
    )?.[0] || 'other'
  );
};

export const BusinessTypeSelector = () => {
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const { setFieldValue, getFieldValue } = Form.useFormInstance();

  const locationTypeOptions = useBusinessTypeOptions(
    locationTypesData?.locationsLabels
  );

  if (locationTypesLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Form.Item>
      <Form.Item
        name={['businessType', 'businessType']}
        label="Business type"
        hidden
      />
      <UpdatableField>
        {() => (
          <Form.Item
            name={['businessType', 'locationType']}
            label="Location's main risk type"
          >
            <Select
              placeholder="Main risk"
              options={locationTypeOptions}
              showSearch
              onChange={(value) => {
                const businessType = matchBusinessTypeFromLocationType(
                  value,
                  locationTypesData?.verticalsMap || {}
                );

                // Used to keep track of the business type implicitly
                setFieldValue(['businessType', 'businessType'], businessType);

                // If the current location type is in subtypes list, remove it
                setFieldValue(
                  ['businessType', 'locationSubtypes'],
                  getFieldValue(['businessType', 'locationSubtypes']).filter(
                    (subtype: string) => subtype !== value
                  )
                );

                // If the business type is 'other', clear the subtypes list
                if (businessType === 'other') {
                  setFieldValue(['businessType', 'locationSubtypes'], []);
                }
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
      </UpdatableField>
      <UpdatableField>
        {() => (
          <Form.Item
            name={['businessType', 'locationSubtypes']}
            label="Location's secondary risk types"
            hidden={getFieldValue(['businessType', 'locationType']) === 'other'}
          >
            <Select
              mode="multiple"
              placeholder="Secondary risks"
              options={locationTypeOptions.filter(
                ({ value }) =>
                  value !== getFieldValue(['businessType', 'locationType'])
              )}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}
      </UpdatableField>
      <UpdatableField>
        {() => (
          <Form.Item
            name={['businessType', 'locationTypeSpecification']}
            label="Location's secondary risk types"
            hidden={getFieldValue(['businessType', 'locationType']) !== 'other'}
            shouldUpdate
          >
            <Input placeholder="If other: Location type specification" />
          </Form.Item>
        )}
      </UpdatableField>
    </Form.Item>
  );
};
