import React, { useState } from 'react';

import { CarrierLogo } from '@/components/CarrierLogo';
import {
  useCreateRecommendationProduct,
  useUpdateRecommendationProductByInsuranceProduct
} from '@/services';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import { useParams } from 'react-router-dom';
import { ProductModal } from '../Products/ProductModal';
import { RecommendationProductResult } from '@/types/recommendation';
import { BusinessVerticalWithDefault } from '@/enums/businessVertical';
import { InsuranceProduct } from '@/types/insurance';

const { Option } = Select;

interface InsuranceProductSelectProps {
  recommendationProductId: string;
  value: string;
  products: any[]; // fixme, RecoProduct is extended with carrierData
  index: number;
  onProductUpdate: (data: RecommendationProductResult) => void;
  categoryId: string;
  businessVertical: BusinessVerticalWithDefault;
  disabled: boolean;
}

export const InsuranceProductSelect = ({
  recommendationProductId,
  value,
  products,
  index,
  onProductUpdate,
  categoryId,
  businessVertical,
  disabled
}: InsuranceProductSelectProps) => {
  const { getFieldValue, setFieldValue } = Form.useFormInstance();
  const { recommendationId } = useParams<{ recommendationId: string }>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>();
  const createRecommendationProduct =
    useCreateRecommendationProduct(recommendationId);
  const updateRecommendationProduct =
    useUpdateRecommendationProductByInsuranceProduct(recommendationProductId);

  const handleCreateRecommendationProduct = (id: string) =>
    createRecommendationProduct.mutate(id, {
      onSuccess: onProductUpdate
    });
  const handleUpdateRecommendationProduct = (id: string) =>
    updateRecommendationProduct.mutate(id, {
      onSuccess: onProductUpdate
    });

  const closeModal = (id: string | boolean) => {
    if (isModalVisible) setIsModalVisible(!isModalVisible);
    if (id && typeof id === 'string') {
      handleCreateRecommendationProduct(id);
      setFieldValue(`insuranceProductId_${index}`, id);
    } else {
      setFieldValue(`insuranceProductId_${index}`, '');
    }
  };

  // only show insurance products for the given insurance category and vertical
  const eligibleInsuranceProducts = products.filter(
    (product: InsuranceProduct) => {
      return (
        product.businessVertical === businessVertical &&
        product.categoryId === categoryId
      );
    }
  );

  const valueExists = eligibleInsuranceProducts.some(
    (product) => product.insuranceProductId === value
  );
  if (!valueExists && value) {
    disabled = true;
    value =
      'The selected insurance product is not available for this category / vertical';
  }

  return (
    <div>
      <Select
        disabled={disabled}
        showSearch
        filterOption={(inputValue, option) =>
          option
            ? option?.displayName
                .trim()
                .toUpperCase()
                .startsWith(inputValue?.toUpperCase()) ||
              option?.carrierName
                ?.trim()
                .toUpperCase()
                .startsWith(inputValue?.toUpperCase())
            : false
        }
        value={value}
        onChange={(e) => {
          if (e === 'new') setIsModalVisible(true);
          else if (getFieldValue(`insuranceProductId_${index}`))
            handleUpdateRecommendationProduct(e);
          else handleCreateRecommendationProduct(e);
        }}
      >
        <Option
          disabled={value}
          value="new"
          key="new"
          displayName={'New product to be created'}
        >
          <PlusCircleOutlined /> New product to be created
        </Option>
        {eligibleInsuranceProducts
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ name, insuranceProductId, logo, carrierName }) => (
            <Option
              key={insuranceProductId}
              value={insuranceProductId}
              carrierName={carrierName}
              displayName={name}
            >
              <CarrierLogo name={logo} />
              {name}
            </Option>
          ))}
      </Select>
      {isModalVisible && (
        <ProductModal
          insuranceProductId={recommendationProductId}
          categoryId={categoryId}
          visible={isModalVisible}
          onClose={closeModal}
        />
      )}
    </div>
  );
};
