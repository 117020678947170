import z from 'zod';
import { newApiClient } from '@/api/api';
import { validateAxiosResponse } from '@/utils/requestValidation';

const VerticalTemplateSchema = z.object({
  id: z.string(),
  vertical: z.string()
});

const CategoryTemplateSchema = z.object({
  id: z.string(),
  category: z.string(),
  children: z.array(VerticalTemplateSchema).optional()
});

export const GetProductTemplatesListResponseSchema = z.object({
  data: z.array(CategoryTemplateSchema),
  meta: z.object({
    total_items: z.number(),
    page_size: z.number(),
    current_page: z.number()
  })
});

export type GetProductTemplatesListResponse = z.infer<
  typeof GetProductTemplatesListResponseSchema
>;

const GetProductTemplateByIdApiResponseSchema = z.object({
  id: z.string(),
  tax: z.number().optional(),
  category: z.string(),
  vertical: z.string().nullable(),
  attributes: z.array(
    z.object({
      code: z.string(),
      is_inherited: z.boolean(),
      is_highlighted: z.boolean()
    })
  )
});

export type GetProductTemplateByIdApiResponse = z.infer<
  typeof GetProductTemplateByIdApiResponseSchema
>;

export interface PostProductTemplateBody {
  category: string;
  vertical: string;
}

const PostProductTemplateResponseSchema = z.discriminatedUnion('status', [
  z.object({
    status: z.literal('created'),
    data: GetProductTemplateByIdApiResponseSchema
  }),
  z.object({
    status: z.literal('error'),
    error: z.string(),
    code: z.literal('TEMPLATE_ALREADY_EXISTS')
  })
]);

export type PostProductTemplateResponse = z.infer<
  typeof PostProductTemplateResponseSchema
>;

export type PutProductTemplateBody = GetProductTemplateByIdApiResponse;

const PutProductTemplateResponseSchema = z.discriminatedUnion('status', [
  z.object({
    status: z.literal('updated'),
    data: GetProductTemplateByIdApiResponseSchema
  }),
  z.object({
    status: z.literal('error'),
    error: z.string(),
    code: z.enum(['TOO_MANY_HIGHLIGHTED_ATTRIBUTES', 'NOT_FOUND'])
  })
]);

export type PutProductTemplateResponse = z.infer<
  typeof PutProductTemplateResponseSchema
>;

export const listProductTemplates = (
  query: {
    categoryId?: string;
    page: number;
  } = { page: 1 }
): Promise<GetProductTemplatesListResponse> =>
  validateAxiosResponse(
    GetProductTemplatesListResponseSchema,
    newApiClient.get(
      `/admin/product-templates?${new URLSearchParams({
        ...query,
        page: String(query.page)
      })}`
    )
  );

export const getProductTemplateById = (
  id: string
): Promise<GetProductTemplateByIdApiResponse> =>
  validateAxiosResponse(
    GetProductTemplateByIdApiResponseSchema,
    newApiClient.get(`/admin/product-templates/${id}`)
  );

export const createVerticalProductTemplate = ({
  category,
  vertical
}: PostProductTemplateBody): Promise<PostProductTemplateResponse> =>
  validateAxiosResponse(
    PostProductTemplateResponseSchema,
    newApiClient.post(`/admin/product-templates/`, { category, vertical })
  );

export const updateProductTemplate = (
  data: PutProductTemplateBody
): Promise<PutProductTemplateResponse> =>
  validateAxiosResponse(
    PutProductTemplateResponseSchema,
    newApiClient.put(`/admin/product-templates/${data.id}`, data)
  );
