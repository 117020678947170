import React, { useState } from 'react';

import { Modal, Form, Input, Checkbox, Row, message, Select } from 'antd';
import '../Dashboard/styles.scss';
import { evolve } from 'ramda';
import {
  useAddCompanyUser,
  useAddUserToCompany,
  useCompanyUserDetails,
  useUpdateCompanyUser,
  useUpdateUser,
  useUserDetails
} from '@/services';
import { Datepicker, Loader } from '@/components';
import { useParams } from 'react-router-dom';
import { timestampToDateString } from '@/utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface CompanyUserModalProps {
  id?: string;
  visible: boolean;
  onClose: (arg: boolean) => void;
  hide?: Record<string, boolean>;
  locationId?: string;
}

const modifier = evolve({
  birthdate: timestampToDateString
});

function makeNewUserInitialData() {
  return {
    emailNotificationsDisabled: false,
    role: 'member'
  };
}

export const CompanyUserModal = ({
  id,
  visible,
  onClose,
  hide = {},
  locationId
}: CompanyUserModalProps) => {
  const [form] = Form.useForm();
  const { companyId } = useParams<{ companyId: string }>();
  const addUserMutation = useAddCompanyUser(companyId);
  // fixme: we shouldn't use casting in below case
  const updateUserMutation = useUpdateCompanyUser(companyId, id as string);
  // fixme: we shouldn't use casting in below case
  const { data: companyUser, isLoading: isLoadingCompanyUser } =
    useCompanyUserDetails(companyId, id as string, {
      enabled: Boolean(id) && !locationId
    });

  // TODO: we should abstract loading data from the form
  const { data: locationUser, isLoading: isLoadingLocationUser } =
    useUserDetails(id as string, {
      enabled: Boolean(id) && Boolean(locationId)
    });

  const isLoading = isLoadingCompanyUser || isLoadingLocationUser;
  const data = locationUser?.[0] ?? companyUser;

  const addLocationUserMutation = useAddUserToCompany(companyId);
  const updateLocationUserMutation = useUpdateUser(id as string);

  const [isEmailEnabled, setIsEmailEnabled] = useState(false);

  const onSubmit = (values: {
    name: string;
    surname: string;
    phone: string;
    birthdate: Date;
    email: string;
    emailNotificationsDisabled: boolean;
  }) => {
    let mutation;

    if (!locationId) {
      mutation = !!id ? updateUserMutation : addUserMutation;
    } else {
      mutation = !!id ? updateLocationUserMutation : addLocationUserMutation;
    }

    mutation
      .mutateAsync(modifier({ ...values, locationId }))
      .then(() => onClose(false))
      .catch((e) => {
        message.error(e.message || 'An error occurred');
      });
  };

  const handleIsEmailEnabled = (e: CheckboxChangeEvent) => {
    setIsEmailEnabled(e.target.checked);
  };

  if (id && isLoading) return <Loader />;

  return (
    <Modal
      centered
      onCancel={() => onClose(false)}
      okText={!!id ? 'Edit' : 'Create'}
      onOk={() => form.submit()}
      cancelText="Cancel"
      title={!!id ? 'Edit User' : 'New User'}
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form
        layout="vertical"
        initialValues={data || makeNewUserInitialData()}
        form={form}
        onFinish={onSubmit}
      >
        {!hide.name && (
          <Form.Item
            label="First Name"
            name="name"
            rules={[{ required: true, message: 'name is required' }]}
          >
            <Input placeholder="Eingeben" />
          </Form.Item>
        )}
        {!hide.surname && (
          <Form.Item
            label="Surname"
            name="surname"
            rules={[{ required: true, message: 'surname is required' }]}
          >
            <Input placeholder="Eingeben" />
          </Form.Item>
        )}
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'please provide a valid email' }
          ]}
        >
          <Input disabled={!!id && !isEmailEnabled} placeholder="Eingeben" />
        </Form.Item>
        <Row align="middle" justify="space-between">
          {id && (
            <Checkbox onChange={handleIsEmailEnabled}>Edit e-mail</Checkbox>
          )}
          <Form.Item
            name="emailNotificationsDisabled"
            valuePropName="checked"
            className="form-no-margin"
          >
            <Checkbox>Turn off email notifications</Checkbox>
          </Form.Item>
        </Row>
        {!hide.phone && (
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: 'phone is required' }]}
          >
            <Input placeholder="Eingeben" />
          </Form.Item>
        )}
        {id && !hide.birthdate && (
          <Form.Item
            label="Date of birth"
            name="birthdate"
            rules={[{ required: true, message: 'birthdate is required' }]}
          >
            <Datepicker placeholder="Eingeben" />
          </Form.Item>
        )}
        {!locationId && (
          <Form.Item name="role">
            <Select
              options={[
                {
                  value: 'owner',
                  label: 'Owner'
                },
                {
                  value: 'md',
                  label: 'Managing director'
                },
                {
                  value: 'member',
                  label: 'Member'
                }
              ]}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};
