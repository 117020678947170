import {
  join,
  adjust,
  concat as concatPairs,
  pipe,
  toUpper,
  ifElse,
  length,
  gt,
  identity,
  slice,
  __
} from 'ramda';

// fixme
export const capitalize = pipe(String as any, adjust(0, toUpper), join(''));

export const truncate = (len: number) =>
  ifElse(
    pipe(length, gt(len)),
    identity,
    pipe(slice(0, len - 3), concatPairs(__, '...'))
  );
