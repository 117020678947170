import { fileTypes } from '@/enums/fileTypes';
import { downloadFile } from './url';
import { DownloadedFile } from '@/types/file';

export const base64ToBlob = (base64: string, type: string): Promise<Blob> =>
  fetch(`data:${type};base64,${base64}`).then((res) => {
    return res.blob();
  });

const previewBase64 = async (base64: string, type: string, name: string) => {
  const documentType = type
    ? type
    : fileTypes[name?.split('.')[name?.split('.')?.length - 1]];

  const blob = await base64ToBlob(base64, documentType);
  downloadFile(URL.createObjectURL(blob), name);
};

export const previewResponseFile = (file: DownloadedFile) => {
  return previewBase64(file.file, file.type, file.displayName);
};
