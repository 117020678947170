export const makeQueryString = (
  input: Record<string, string | undefined>
): string => {
  return `?${Object.entries(input)
    .filter(
      (property): property is [string, string] => property[1] !== undefined
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')}`;
};

export const downloadFile = (url: string, fileName: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.target = '_blank';
  downloadLink.click();
};
