import { Select, SelectProps } from 'antd';
import React from 'react';

const Option = Select.Option;

export const OfferTagSelect = (props: SelectProps) => (
  <Select {...props}>
    <Option value={null}>None</Option>
    <Option value="best_choice">Best offer</Option>
    <Option value="best_price">Best price</Option>
    <Option value="best_coverage">Best coverage</Option>
  </Select>
);
