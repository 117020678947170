import React, { useEffect } from 'react';

import { Input, Modal } from 'antd';
import { formatMoney } from '@/utils';
import xmlFormat from 'xml-formatter';

import Prism from 'prismjs';

import 'prismjs/themes/prism-tomorrow.css';
import { useLocationTypes } from '@/services/locationTypes';

type CarrierData =
  | {
      type: 'xml' | 'json';
      content: string;
    }
  | Record<string, unknown>;

interface FetchPriceResultSuccess {
  status: 'success';
  locationType: string;
  price: number;
  payload: CarrierData;
  carrierResponse: CarrierData;
}

interface FetchPriceResultError {
  status: 'error';
  locationType: string;
  error: string;
}

type FetchPriceResult = FetchPriceResultSuccess | FetchPriceResultError;

const FormatCarrierData = ({ data }: { data: CarrierData }) => {
  const className =
    !!data.type || data.type === 'xml' ? 'language-xml' : 'language-js';

  const content =
    data.type === 'xml'
      ? xmlFormat(data.content as string)
      : JSON.stringify(data, null, 4);

  return (
    <pre>
      <code className={className}>{content}</code>
    </pre>
  );
};

const SuccessResultCard = ({
  item,
  discount
}: {
  item: FetchPriceResultSuccess;
  discount?: number;
}) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  return (
    <section className="mt-4">
      <div className="mixed-businesses-calc-child">
        <h3 className="mixed-businesses-calc-title">
          {locationTypesLoading
            ? 'Loading...'
            : `${locationTypesData?.getLocationLabel(item.locationType)}`}
        </h3>
        <p>
          <span className="font-bold">Status: </span>
          {item.status}
        </p>
        <p>
          <span className="font-bold">Price: </span>
          {formatMoney(item.price)}
        </p>
        <p>
          <span className="font-bold">Discount value: </span> {`${discount}%`}
        </p>
        <section className="mt-4">
          <details className="details-dropdown">
            <summary>Carrier payload</summary>
            <FormatCarrierData data={item.payload} />
          </details>

          <details className="details-dropdown">
            <summary>Carrier response</summary>
            {item.carrierResponse && (
              <FormatCarrierData data={item.carrierResponse} />
            )}
          </details>
        </section>
      </div>
    </section>
  );
};

const ErrorResultCard = ({ item }: { item: FetchPriceResultError }) => {
  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  return (
    <section className="mt-4">
      <div className="mixed-businesses-calc-child">
        <h3 className="mixed-businesses-calc-title">
          {locationTypesLoading
            ? 'Loading...'
            : `${locationTypesData?.getLocationLabel(item.locationType)}`}
        </h3>
        <p>
          <span className="font-bold">Status: </span>
          {item.status}
        </p>
        {item.status === 'error' && <p>{item.error}</p>}
      </div>
    </section>
  );
};

export const MixedBusinessContextModal = ({
  handleMetadataModalClose,
  priceMetadata,
  visible
}: {
  handleMetadataModalClose: () => void;
  priceMetadata: {
    status: 'skipped' | 'success' | 'error';
    mixedBusinessContext?: FetchPriceResult[];
    discountValue?: number;
  };
  visible: boolean;
}) => {
  return (
    <Modal
      footer={null}
      centered
      onCancel={handleMetadataModalClose}
      width={800}
      open={visible}
    >
      <h2>Price calculations</h2>
      {priceMetadata.status === 'skipped' && (
        <Input.TextArea
          autoSize={{ minRows: 12 }}
          readOnly
          value={JSON.stringify(priceMetadata, undefined, 4)}
        />
      )}
      {priceMetadata.mixedBusinessContext &&
        priceMetadata.mixedBusinessContext.map(
          (item: FetchPriceResult, index: number) =>
            item.status === 'error' ? (
              <ErrorResultCard key={`result-${index}`} item={item} />
            ) : (
              <SuccessResultCard
                key={`result-${index}`}
                item={item}
                discount={priceMetadata.discountValue}
              />
            )
        )}
    </Modal>
  );
};
