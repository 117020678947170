import React, { MutableRefObject, useEffect, useState } from 'react';
import { PdfDocument } from '@/components/PdfDocument/PdfDocument';
import { PdfSkeleton } from '@/components/PdfDocument/PdfSkeleton';
import { useFile } from '@/services';
import { base64ToBlob } from '@/utils';

const useFileBlob = (fileName: string): Blob | undefined => {
  const [blob, setBlob] = useState<Blob>();
  const { data: file, isLoading } = useFile(fileName);

  useEffect(() => {
    if (!isLoading && file) {
      base64ToBlob(file.file, file.type).then((converted: Blob) => {
        setBlob(converted);
      });
    }
  }, [file, isLoading]);

  return blob;
};

export const PdfFilePreview = ({
  fileName,
  blobRef
}: {
  fileName: string;
  blobRef: MutableRefObject<Blob | null>;
}) => {
  const blob = useFileBlob(fileName);

  if (!blob) {
    return <PdfSkeleton />;
  }

  blobRef.current = blob;
  return <PdfDocument pdf={blob} />;
};
