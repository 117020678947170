import React from 'react';

import { Loader, Table } from '@/components';
import { CarrierLogo } from '@/components/CarrierLogo';
import { CategoryLabelMap } from '@/enums';
import { useExpiring, useUpdateInsuranceStatus } from '@/services';
import { Expiring as ExpiringType } from '@/types/insurance';
import { useItemDialog, useToggable } from '@/utils';
import { Radio, Switch, Typography, message } from 'antd';
import { keys } from 'ramda';
import { EditInsuranceModal } from '../LocationOverview/EditInsuranceModal';
import './styles.scss';

const statusOptions = [
  {
    label: 'Extend',
    value: 'extended'
  },
  {
    label: 'Cancel',
    value: 'canceled'
  }
];

const contractTypeOptions: Record<string, string> = {
  eigen: 'Eigen',
  fremd: 'Fremd',
  korrespondenz: 'Korrespondenz',
  unklar: 'Offen / unklar'
};

const columns = (
  openItemDialog: (val: string) => void,
  onStatusChange: (id: string, status: string) => void
) => [
  {
    title: 'Company Name',
    width: 'auto',
    dataIndex: 'companyName'
  },
  {
    width: 'auto',
    title: 'Location',
    dataIndex: 'locationName'
  },
  {
    title: 'Carrier',
    dataIndex: 'carrierName',
    width: 'auto',
    render: (value: string, { carrierLogoUrl }: ExpiringType) => (
      <>
        <CarrierLogo name={carrierLogoUrl} />
        {value}
      </>
    )
  },
  {
    width: 'auto',
    title: 'Insurance Type',
    dataIndex: 'categoryId',
    render: (category: string) => CategoryLabelMap[category],
    filters: [
      {
        text: 'Liability insurance',
        value: 'LI'
      },
      {
        text: 'Content insurance',
        value: 'CO'
      },
      {
        text: 'Other insurances',
        value: 'other'
      }
    ]
  },
  {
    width: 'auto',
    title: 'Date of expiration',
    dataIndex: 'endDate',
    defaultSortOrder: 'ascend',
    render: (e: string) =>
      new Date(e).toLocaleDateString() === 'Invalid Date' || e === ''
        ? ''
        : new Date(e).toLocaleDateString(),
    sorter: (a: ExpiringType, b: ExpiringType) =>
      Date.parse(a.endDate) - Date.parse(b.endDate)
  },
  {
    width: 'auto',
    title: 'Tariff',
    dataIndex: 'insuranceName'
  },
  {
    title: 'Contract type',
    dataIndex: 'contractType',
    width: 'auto',
    filters: keys(contractTypeOptions).map((value) => ({
      value,
      text: contractTypeOptions[value]
    })),
    onFilter: (value: string, { contractType }: ExpiringType) =>
      contractType === value,
    render: (value: string) => contractTypeOptions[value]
  },
  {
    title: 'Policy number',
    dataIndex: 'policyNumber',
    width: 'auto'
  },
  {
    title: '',
    dataIndex: 'status',
    render: (value: string, { insuranceId }: ExpiringType) => (
      <Radio.Group
        style={{ display: 'flex', gap: '10px' }}
        options={statusOptions}
        onChange={(e) => {
          onStatusChange(insuranceId, e.target.value);
        }}
        value={value}
        optionType="button"
        buttonStyle="solid"
      />
    )
  },
  {
    width: '70px',
    title: '',
    dataIndex: 'insuranceId',
    render: (value: string) => (
      <Typography.Link onClick={() => openItemDialog(value)}>
        Open
      </Typography.Link>
    )
  }
];

export const Expiring = () => {
  const [expired, setExpired] = useToggable(false);
  const { data, isLoading } = useExpiring(
    expired ? { from: 0, to: 3 } : { from: 3, to: 6 }
  );
  const updateStatusMutation = useUpdateInsuranceStatus();

  const onStatusChange = (id: string, status: string) =>
    updateStatusMutation.mutate(
      { id, status },
      {
        onSuccess: message.success('Insurance has been extended successfully!')
      }
    );

  const { item, openItemDialog, closeItemDialog, isOpen } =
    useItemDialog<string>();

  if (isLoading) return <Loader />;

  const makeRecords = (search?: string) => {
    if (search) {
      return (
        data?.filter(
          ({ companyName, locationName, carrierName }) =>
            companyName.toLowerCase().includes(search.toLowerCase()) ||
            locationName?.toLowerCase().includes(search.toLowerCase()) ||
            carrierName.toLowerCase().includes(search.toLowerCase())
        ) || []
      );
    }
    return data || [];
  };

  return (
    <div>
      <div className="table-container">
        {isOpen && item && (
          <EditInsuranceModal
            id={item}
            visible={isOpen}
            onClose={closeItemDialog}
          />
        )}
        <div
          className="expired-toggle-wrapper"
          style={{ marginBottom: '20px' }}
        >
          Expired: <Switch onChange={() => setExpired(!expired)} />
        </div>
        <Table
          searchKey="expiredSearch"
          searchPlaceholder="Search by company, location or carrier"
          paginationKey="expiredPagination"
          rowKey="insuranceId"
          columns={columns(openItemDialog, onStatusChange)}
          records={makeRecords}
        />
      </div>
    </div>
  );
};
