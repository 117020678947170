import React, { useState } from 'react';

import { Loader } from '@/components';
import { useCarriers, useCreateMultipleInsurances } from '@/services';
import { Modal, Form, Select, Button, Divider } from 'antd';
import './styles.scss';
import { keys } from 'ramda';
import { CategoryLabelMap } from '@/enums';
import { useParams } from 'react-router-dom';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { CarrierLogo } from '@/components/CarrierLogo';

interface AddInsuranceModalProps {
  disabled: boolean;
}

const { Option } = Select;

const initialValue = {
  insurances: [{ categoryId: '', carrierId: '' }]
};

export const AddInsuranceModal = ({ disabled }: AddInsuranceModalProps) => {
  const { locationId } = useParams<{ locationId: string }>();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { data: carriers, isLoading: isCarriersLoading } = useCarriers({
    enabled: visible
  });
  const createInsuranceMutation = useCreateMultipleInsurances(locationId);

  if (isCarriersLoading) return <Loader />;

  const onSubmit = () =>
    form
      .validateFields()
      .then(({ insurances }) =>
        createInsuranceMutation.mutateAsync(insurances).then(onCancel)
      );

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <>
      <Button disabled={disabled} type="link" onClick={() => setVisible(true)}>
        <PlusCircleOutlined />
        Add insurances
      </Button>
      {visible && (
        <Modal
          destroyOnClose
          okButtonProps={{ htmlType: 'submit' }}
          centered
          onOk={onSubmit}
          onCancel={onCancel}
          okText="Add"
          title="Add Insurances"
          closable={false}
          className="add-company-modal"
          open={visible}
        >
          <Form initialValues={initialValue} layout="vertical" form={form}>
            <Form.List name="insurances">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <div key={field.key}>
                      {Boolean(index) && <Divider />}
                      <div className="add-insurance-select">
                        <div className="add-insurance-form-item">
                          <label>Category</label>
                          <Form.Item
                            name={[index, 'categoryId']}
                            rules={[{ required: true, message: 'Required!' }]}
                          >
                            <Select
                              showSearch
                              filterOption={(inputValue, option) =>
                                (option?.label ?? '')
                                  ?.toUpperCase()
                                  .startsWith(inputValue?.toUpperCase())
                              }
                              options={keys(CategoryLabelMap)
                                .sort((a, b) =>
                                  CategoryLabelMap[a].localeCompare(
                                    CategoryLabelMap[b]
                                  )
                                )
                                .map((item) => ({
                                  value: item,
                                  label: CategoryLabelMap[item]
                                }))}
                            />
                          </Form.Item>
                        </div>
                        <div className="add-insurance-form-item">
                          <label>Carrier</label>
                          <Form.Item
                            name={[index, 'carrierId']}
                            rules={[{ required: true, message: 'Required!' }]}
                          >
                            <Select
                              showSearch
                              filterOption={(inputValue, option) =>
                                option?.children &&
                                option?.children[1]
                                  ?.toUpperCase()
                                  .startsWith(inputValue?.toUpperCase())
                              }
                            >
                              {carriers &&
                                carriers
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map(({ name, logoUrl, carrierId }) => (
                                    <Option key={carrierId} value={carrierId}>
                                      <CarrierLogo name={logoUrl} />
                                      {name}
                                    </Option>
                                  ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      {fields.length > 1 && (
                        <Button
                          type="text"
                          onClick={() => remove(field.name)}
                          icon={<MinusCircleOutlined />}
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  ))}
                  <div className="add-insurance-button">
                    <Button type="link" onClick={() => add()}>
                      <PlusCircleOutlined /> Add insurance
                    </Button>
                  </div>
                </>
              )}
            </Form.List>
          </Form>
        </Modal>
      )}
    </>
  );
};
