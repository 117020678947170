import React, { RefObject, useCallback, useRef, useState } from 'react';
import { Alert, message } from 'antd';

import { CoverNotePDF } from './CoverNotePDF';
import { useInsurance, useSelfAssesment } from '@/services';
import { Loader } from '@/components';
import { Location } from '@/types/location';
import {
  renderPDF,
  saveFileToLocalDisk
} from '@/components/PdfCoverNote/renderPDF';
import { prop } from 'ramda';

interface PdfCoverNoteProps {
  categoryId: string;
  insuranceId: string;
  locationData: Location;
}

function useRenderPDF(): {
  ref: RefObject<HTMLDivElement>;
  renderFile: (fileName: string) => Promise<Blob | undefined>;
} {
  const ref = useRef<HTMLDivElement>(null);

  const renderFile = useCallback(
    async (fileName: string): Promise<Blob | undefined> => {
      if (ref.current === null) {
        return;
      }
      return renderPDF({
        content: ref.current.innerHTML,
        filename: fileName
      });
    },
    [ref]
  );

  return { ref, renderFile };
}

function useDownloadPDFFile(
  renderDocument: (fileName: string) => Promise<Blob | undefined>
): {
  downloadPDFFile: (fileName: string) => void;
  isDownloading: boolean;
} {
  const [isRendering, setIsRendering] = useState(false);

  const downloadPDFFile = useCallback(
    async (fileName: string) => {
      setIsRendering(true);

      try {
        const pdfBlob = await renderDocument(fileName);

        if (pdfBlob) {
          saveFileToLocalDisk(pdfBlob, fileName);
        }
      } catch (e: unknown) {
        message.error(
          `Error when downloading Deckungsnote: ${prop('message', e) ?? 'Unknown error'}`
        );
      }

      setIsRendering(false);
    },
    [renderDocument]
  );

  return {
    downloadPDFFile,
    isDownloading: isRendering
  };
}

function getCoverNoteFileName({
  locationName,
  categoryId
}: {
  locationName: string;
  categoryId: string;
}): string {
  return `Deckungsnote_${locationName}_${categoryId}.pdf`;
}

export const PdfCoverNote = ({
  insuranceId,
  locationData
}: PdfCoverNoteProps) => {
  const { data: insuranceData, isLoading: isInsuranceDataLoading } =
    useInsurance(insuranceId);

  const { data: selfAssesmentData, isLoading: isSelfAssessmentDataLoading } =
    useSelfAssesment(locationData.locationId);

  const { ref, renderFile } = useRenderPDF();
  const blobRef = useRef<Blob | null>(null);

  const { downloadPDFFile, isDownloading } = useDownloadPDFFile(renderFile);

  if (isInsuranceDataLoading || isSelfAssessmentDataLoading) {
    return <Loader />;
  }

  if (!selfAssesmentData) {
    return <Alert type="error" message="Missing self assessment" showIcon />;
  }

  if (!insuranceData) {
    return <Alert type="warning" message="Missing insurance" showIcon />;
  }

  const handleDownload = () => {
    const fileName = getCoverNoteFileName({
      locationName: locationData.name,
      categoryId: insuranceData?.categoryId || ''
    });

    if (ref.current) {
      downloadPDFFile(fileName);
    }

    if (blobRef.current) {
      saveFileToLocalDisk(blobRef.current, fileName);
    }
  };

  return (
    <CoverNotePDF
      insuranceData={insuranceData}
      blobRef={blobRef}
      isDownloading={isDownloading}
      downloadFile={handleDownload}
    />
  );
};
