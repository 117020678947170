import { message } from 'antd';
import { Company } from '@/types/company';
import { LocationHome } from '@/types/location';
import {
  useCreatePayment,
  useContractOfferApproval,
  useUpdateAddress,
  useUpdateCompany
} from '@/services';
import { useSubmitCheckout } from '@/services/cart';
import { CheckoutPayload } from '@/api/cart';

const errorMessages = {
  NOT_SUPPORTED: 'Requesting protection is not supported yet for this carrier.',
  UNMET_DEPENDENCIES: 'There are unmet dependencies for Deckungsnote.',
  SAVE_COVER_NOTE_ERROR: 'Error when saving Deckungsnote.',
  SAVE_CONSULTATION_PROTOCOL_ERROR:
    'Error when generating consultation protocol.'
};

export interface ValidateOfferPayload {
  companyAddress?: {
    street: string;
    streetNr: string;
    postalCode: string;
    city: string;
    country: string | undefined;
  };
  company?: {
    name: string;
    foundedDate: string;
  };
  locationAddress?: {
    street: string;
    streetNr: string;
    postalCode: string;
    city: string;
    country: string | undefined;
  };
  contractDetails: {
    nextPaymentDate: Date | undefined;
    startDate: Date | undefined;
    endDate: Date | undefined;
    paymentPeriod: string | undefined;
    agreementNumber: string | undefined;
  };
  payment: { paymentId: string } | { name: string; iban: string };
  finalize: boolean;
}

export function useValidateOffer({
  company,
  location,
  entity
}: {
  company: Company;
  location: LocationHome;
  entity:
    | {
        type: 'recommendation';
        recommendationId: string;
        recommendationProductId: string;
      }
    | {
        type: 'insurance';
        insuranceId: string;
      };
}): {
  isLoading: boolean;
  validateOffer: (data: ValidateOfferPayload) => Promise<void>;
} {
  const companyId = company.companyId;
  const updateCompanyMutation = useUpdateCompany(companyId);

  const updateCompanyAddressMutation = useUpdateAddress(
    String(company.address.addressId)
  );
  const updateLocationAddressMutation = useUpdateAddress(
    String(location.address.addressId)
  );
  const createNewPaymentMutation = useCreatePayment(companyId);
  const createCheckoutSubmitMutation = useSubmitCheckout();
  const contractOfferApprovalMutation = useContractOfferApproval(
    location.locationId
  );

  const submitProductId = async (submitData: {
    paymentId: string;
    nextPaymentDate?: Date;
    startDate?: Date;
    endDate?: Date;
    paymentPeriod?: string;
    agreementNumber?: string;
    finalize: boolean;
  }) => {
    if (entity.type === 'recommendation') {
      const payload: CheckoutPayload = {
        ...submitData,
        items: [
          {
            recommendationId: entity.recommendationId,
            recommendationProductId: entity.recommendationProductId
          }
        ]
      };

      return createCheckoutSubmitMutation.mutateAsync(payload);
    } else {
      const d = {
        id: entity.insuranceId,
        ...submitData,
        // TODO: inputs are returning epoch style timestamp - we should resolve this issue in systemic way
        nextPaymentDate:
          typeof submitData.nextPaymentDate === 'number'
            ? new Date(submitData.nextPaymentDate).toISOString()
            : submitData.nextPaymentDate,
        startDate:
          typeof submitData.startDate === 'number'
            ? new Date(submitData.startDate).toISOString()
            : submitData.startDate,
        endDate:
          typeof submitData.endDate === 'number'
            ? new Date(submitData.endDate).toISOString()
            : submitData.endDate
      };
      const res = await contractOfferApprovalMutation.mutateAsync(d);

      if (res.status === 'CONTRACT_VALIDATION_FAILED') {
        message.error(errorMessages[res.reason] ?? 'Unknown error');
      }
    }
  };

  return {
    isLoading:
      updateCompanyAddressMutation.isLoading ||
      updateCompanyMutation.isLoading ||
      updateLocationAddressMutation.isLoading ||
      createNewPaymentMutation.isLoading ||
      createCheckoutSubmitMutation.isLoading ||
      contractOfferApprovalMutation.isLoading,
    validateOffer: async (data) => {
      if (data.companyAddress) {
        const companyAddress = data.companyAddress;
        const addressPayload = {
          street: companyAddress.street,
          streetNr: companyAddress.streetNr,
          postalCode: companyAddress.postalCode,
          city: companyAddress.city,
          country: company.address.country
        };
        await updateCompanyAddressMutation.mutateAsync(addressPayload);
      }

      if (data.company) {
        const { name, foundedDate } = data.company;
        const companyPayload = {
          name,
          foundedDate: new Date(foundedDate).toISOString()
        };
        await updateCompanyMutation.mutateAsync(companyPayload);
      }

      if (data.locationAddress) {
        const locationAddress = data.locationAddress;
        const locationPayload = {
          street: locationAddress.street,
          streetNr: locationAddress.streetNr,
          postalCode: locationAddress.postalCode,
          city: locationAddress.city,
          country: location.address.country
        };
        await updateLocationAddressMutation.mutateAsync(locationPayload);
      }

      let paymentId;

      if ('paymentId' in data.payment) {
        paymentId = data.payment.paymentId;
      } else {
        const newPayment = await createNewPaymentMutation.mutateAsync({
          name: data.payment.name,
          iban: data.payment.iban,
          bic: '',
          priority: 0
        });
        paymentId = newPayment.paymentId;
      }

      const {
        nextPaymentDate,
        startDate,
        endDate,
        paymentPeriod,
        agreementNumber
      } = data.contractDetails;
      await submitProductId({
        paymentId,
        nextPaymentDate: nextPaymentDate,
        startDate: startDate,
        endDate: endDate,
        paymentPeriod: paymentPeriod,
        agreementNumber: agreementNumber,
        finalize: data.finalize
      });
    }
  };
}
