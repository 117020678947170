import { useState } from 'react';

type AllowedValueTypes = string | number | boolean | Record<string, any>;

/*
wrapper around local storage, handles type conversion
 */
export function useLocalStorage(key: string, initialValue?: AllowedValueTypes) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      let itemToBeReturned;

      if (!item) return initialValue;

      try {
        itemToBeReturned = JSON.parse(item);
      } catch {
        itemToBeReturned = item;
      }
      return item ? itemToBeReturned : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: AllowedValueTypes, stringify = true) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(
        key,
        stringify ? JSON.stringify(valueToStore) : valueToStore
      );
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}
