import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isFunction } from '../type';

export const useGoToPath = (
  path: string | ((a: string) => string),
  rememberSearch = false
) => {
  const history = useHistory();
  const location = useLocation();

  return useCallback(
    (key: string) => {
      const to = isFunction(path) ? path(key) : path;
      const newEntry = rememberSearch ? `${to}${location.search}` : to;

      history.push(newEntry);
    },
    [history, path]
  );
};
