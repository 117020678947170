interface WithLegacyAttributes {
  parameters_values: {
    en?: Record<string, string>;
    de?: Record<string, string>;
  } | null;
  yes_no_values: Record<string, 0 | 1 | undefined> | null;
  highlighted_attributes: Record<string, boolean> | null;
}

interface NormalizedAttribute {
  code: string;
  is_included: boolean | undefined;
  is_highlighted: boolean;
  parameter_en: string | null;
  parameter_de: string | null;
}

type PartialNormalizedAttribute = { code: string } & Partial<
  Omit<NormalizedAttribute, 'code'>
>;

function mergeNormalizedAttribute(
  newValues: PartialNormalizedAttribute,
  oldValues: Partial<NormalizedAttribute> = {}
): NormalizedAttribute {
  const defaultValues = {
    is_included: undefined,
    is_highlighted: false,
    parameter_en: null,
    parameter_de: null
  };

  return {
    ...defaultValues,
    ...oldValues,
    ...newValues
  };
}

/**
 * This function converts from legacy attributes configuration into new one
 */
export function createNormalizedAttributes<T extends WithLegacyAttributes>(
  item: T
): NormalizedAttribute[] {
  const areIncluded = item.yes_no_values
    ? Object.entries(item.yes_no_values).map(([key, value]) => ({
        code: key,
        is_included: typeof value === 'undefined' ? value : Boolean(value)
      }))
    : [];

  const areHighlighted = item.highlighted_attributes
    ? Object.entries(item.highlighted_attributes)
        .filter(([_, value]) => value)
        .map(([code]) => ({
          code,
          is_highlighted: true
        }))
    : [];

  const parameretsEN = item.parameters_values?.en
    ? Object.entries(item.parameters_values.en).map(([code, value]) => ({
        code,
        parameter_en: value
      }))
    : [];

  const parameretsDE = item.parameters_values?.de
    ? Object.entries(item.parameters_values.de).map(([code, value]) => ({
        code,
        parameter_de: value
      }))
    : [];

  const normalizedMap: Record<string, NormalizedAttribute> = {};

  for (const attribute of areIncluded) {
    normalizedMap[attribute.code] = mergeNormalizedAttribute(attribute);
  }

  for (const attribute of areHighlighted) {
    normalizedMap[attribute.code] = mergeNormalizedAttribute(
      attribute,
      normalizedMap[attribute.code]
    );
  }

  for (const attribute of parameretsEN) {
    normalizedMap[attribute.code] = mergeNormalizedAttribute(
      attribute,
      normalizedMap[attribute.code]
    );
  }

  for (const attribute of parameretsDE) {
    normalizedMap[attribute.code] = mergeNormalizedAttribute(
      attribute,
      normalizedMap[attribute.code]
    );
  }

  return Object.values(normalizedMap);
}

/**
 * This function converts from new attributes configuration into legacy one
 */
export function toLegacyAttributes(
  attributes: NormalizedAttribute[]
): WithLegacyAttributes {
  const parametersValues: WithLegacyAttributes['parameters_values'] = {
    en: {},
    de: {}
  };
  const yesNoValues: WithLegacyAttributes['yes_no_values'] = {};
  const highlightedAttributes: WithLegacyAttributes['highlighted_attributes'] =
    {};

  for (const attribute of attributes) {
    if (attribute.is_included !== undefined) {
      yesNoValues[attribute.code] = attribute.is_included ? 1 : 0;
    }

    highlightedAttributes[attribute.code] = attribute.is_highlighted;

    if (attribute.parameter_en) {
      parametersValues.en![attribute.code] = attribute.parameter_en;
    }

    if (attribute.parameter_de) {
      parametersValues.de![attribute.code] = attribute.parameter_de;
    }
  }

  return {
    parameters_values: parametersValues,
    yes_no_values: yesNoValues,
    highlighted_attributes: highlightedAttributes
  };
}
