import {
  deleteFileById,
  downloadFile,
  getFileCategories,
  postFiles,
  updateFile
} from '@/api';
import { previewResponseFile, useInvalidateOnSuccessMutation } from '@/utils';
import { QueryKey, QueryOptions, useMutation, useQuery } from 'react-query';

export const useUploadFiles = (
  id: string,
  entity: QueryKey,
  type: string,
  category?: string
) =>
  useInvalidateOnSuccessMutation([entity, id], (files) =>
    postFiles(id, files, type, category)
  );

export const useDownloadFile = () =>
  useMutation(downloadFile, {
    onSuccess: previewResponseFile
  });

export const useFile = (name: string) =>
  useQuery(['file', name], () => downloadFile(name));

export const useUpdateFile = (belongsTo: string) =>
  useInvalidateOnSuccessMutation(['insurance', belongsTo], (file) =>
    updateFile(file)
  );

export const useFileCategories = (options: QueryOptions) =>
  useQuery('file-categories', getFileCategories, options);

export const useDeleteFile = (entity: QueryKey, id?: string) =>
  useInvalidateOnSuccessMutation(entity, (overrideId) =>
    deleteFileById(overrideId || id)
  );
