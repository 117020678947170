import React from 'react';

export const Image = ({ name, type = 'svg', ...props }: ImageProps) => (
  <img alt={`${name} icon`} src={`/assets/${name}.${type}`} {...props} />
);

export interface ImageProps {
  name: string;
  type?: 'svg' | 'png' | 'jpg' | 'jpeg' | 'gif' | 'webp';
}
