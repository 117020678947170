import React, {
  MutableRefObject,
  useEffect,
  useState,
  useTransition
} from 'react';
import { getCoverNotePreview } from '@/api';
import { PdfDocument } from '@/components/PdfDocument/PdfDocument';
import { PdfSkeleton } from '@/components/PdfDocument/PdfSkeleton';

const useCoverNotePreview = (
  insuranceId: string
): { isLoading: boolean; pdf: null | Blob } => {
  const [pdf, setPdf] = useState<Blob | null>(null);
  const [isLoading, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      getCoverNotePreview(insuranceId)
        .then(async (res) => {
          setPdf(res.data);
        })
        .catch((error) => console.error('Error:', error));
    });
  }, [insuranceId]);

  return { isLoading, pdf };
};

export const CoverNotePdfPreview = ({
  insuranceId,
  blobRef
}: {
  insuranceId: string;
  blobRef: MutableRefObject<Blob | null>;
}) => {
  const { isLoading: isLoadingPdfPreview, pdf } =
    useCoverNotePreview(insuranceId);

  if (isLoadingPdfPreview || !pdf) {
    return <PdfSkeleton />;
  }

  blobRef.current = pdf;
  return <PdfDocument pdf={pdf} />;
};
