import React, { useState } from 'react';

import { Loader } from '@/components';
import { CoverNoteValidated } from '@/pages/LocationOverview/HistoryCards/CoverNoteValidated';
import { CategoryLabelMap } from '@/enums';
import { useDownloadFile, useLocationHistory } from '@/services';
import { useGoToPath } from '@/utils';
import { Button, Modal } from 'antd';
import { concat, pipe } from 'ramda';
import './styles.scss';
import YearlyCheckSubmitted from './HistoryCards/YearlyCheckSubmitted';
import { ProtectionRequested } from '@/pages/LocationOverview/HistoryCards/ProtectionRequested';

interface HistoryModalProps {
  id: string;
}

// TODO: split entry cards into separate components
export const HistoryModal = ({ id }: HistoryModalProps) => {
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useLocationHistory(id, { enabled: visible });
  const previewFile = useDownloadFile();
  const goToRecommendation = useGoToPath(
    pipe(String, concat('/recommendation/'))
  );
  if (isLoading) return <Loader />;

  return (
    <>
      <Button onClick={() => setVisible(true)} type="primary">
        History
      </Button>
      {visible && (
        <Modal
          centered
          onCancel={() => setVisible(false)}
          title="History"
          closable={false}
          cancelText="Close"
          okButtonProps={{ style: { display: 'none' } }}
          className="add-company-modal"
          open={visible}
          width={800}
        >
          <div className="historyCardContainer">
            {data?.map((item) => {
              if (item.actionType === 'registered') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span className="historyCard-message">
                      {item?.actionExtraData?.adminName} registered
                    </span>
                  </div>
                );
              } else if (item.actionType === 'poa_signed') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span
                      onClick={() => {
                        if (item?.actionExtraData?.fileName)
                          previewFile.mutate(item.actionExtraData.fileName);
                      }}
                      className="historyCard-message document"
                    >
                      {item?.actionExtraData?.adminName} - Signed power of
                      attorney
                    </span>
                  </div>
                );
              } else if (item.actionType === 'assessment_done') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span className="historyCard-message">
                      {item?.actionExtraData?.adminName} completed{' '}
                      {item?.actionExtraData?.categoryId === 'LE'
                        ? `Legal`
                        : 'Combined'}{' '}
                      Assessment
                    </span>
                  </div>
                );
              } else if (item.actionType === 'assessment_deleted') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span className="historyCard-message">
                      {item?.actionExtraData?.adminName} deleted{' '}
                      {item?.actionExtraData?.categoryId === 'LE'
                        ? `Legal`
                        : 'Combined'}{' '}
                      Assessment
                    </span>
                  </div>
                );
              } else if (item.actionType === 'recommendation_send') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span className="historyCard-message">
                      {
                        CategoryLabelMap[
                          item?.actionExtraData?.categoryId || ''
                        ]
                      }{' '}
                      recommendation sent
                    </span>
                  </div>
                );
              } else if (item.actionType === 'poa_deleted') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span
                      onClick={() => {
                        if (item?.actionExtraData?.fileName)
                          previewFile.mutate(item.actionExtraData.fileName);
                      }}
                      className="historyCard-message document"
                    >
                      {item?.actionExtraData?.adminName} - Removed power of
                      attorney
                    </span>
                  </div>
                );
              } else if (item.actionType === 'consultation_done') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>

                    <span className="historyCard-message">
                      {item.actionExtraData?.adminName} uploaded Consultation
                      Protocol
                    </span>
                    <span
                      onClick={() => {
                        if (item?.actionExtraData?.fileName)
                          previewFile.mutate(item.actionExtraData.fileName);
                      }}
                      className="historyCard-message document"
                    >
                      File:{' '}
                      {item.actionExtraData?.displayName ??
                        item.actionExtraData?.file_name}
                    </span>
                  </div>
                );
              } else if (item.actionType === 'insurance_document_uploaded') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span
                      onClick={() => {
                        if (item?.actionExtraData?.fileName)
                          previewFile.mutate(item.actionExtraData.fileName);
                      }}
                      className="historyCard-message document"
                    >
                      {item?.actionExtraData?.adminName || 'Unknown user'} -
                      uploaded insurance for{' '}
                      {
                        CategoryLabelMap[
                          item?.actionExtraData?.categoryId || ''
                        ]
                      }
                    </span>
                  </div>
                );
              } else if (item.actionType === 'document_uploaded') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span
                      onClick={() => {
                        if (item.actionExtraData?.fileName)
                          previewFile.mutate(item.actionExtraData.fileName);
                      }}
                      className="historyCard-message document"
                    >
                      {item.actionExtraData?.adminName || 'Unknown user'} -
                      uploaded a document ({item.actionExtraData?.fileType})
                    </span>
                  </div>
                );
              } else if (item.actionType === 'insurance_bought') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    {item.actionExtraData?.relation === 'recommendation' ? (
                      <span
                        onClick={() => goToRecommendation(item.belongsTo)}
                        className="historyCard-message document"
                      >
                        {item.actionExtraData?.adminName || 'Unknown user'} -
                        bought an insurance
                      </span>
                    ) : (
                      <span>
                        {item.actionExtraData?.adminName || 'Unknown user'} -
                        bought an insurance
                      </span>
                    )}
                  </div>
                );
              } else if (item.actionType === 'file_deleted') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>
                    <span>
                      {item.actionExtraData?.adminName || 'Unknown user'} -
                      deleted a file of type {item.actionExtraData?.fileType}
                    </span>
                  </div>
                );
              } else if (item.actionType === 'customer_status_changed') {
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()} at{' '}
                      {new Date(item.createdAt).toLocaleTimeString()}
                    </span>
                    <span>
                      {item.actionExtraData?.adminName || 'Unknown user'}{' '}
                      changed the customer status from "
                      {item.actionExtraData?.oldStatus || 'no status'}" to "
                      {item.actionExtraData?.newStatus}"
                    </span>
                  </div>
                );
              } else if (item.actionType === 'assessment_edited') {
                // fall back to debug view
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>

                    <span className="historyCard-message">
                      {item.actionExtraData?.updatedByRole} (
                      {item.actionExtraData?.updatedBy}) edited the assessment
                    </span>

                    <div className="historyCard-message">
                      <span>Changes:</span>
                      <ul>
                        {item.actionExtraData?.changes?.map((change: any) => (
                          <li>
                            <span>{formatChange(change)}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              } else if (item.actionType === 'yearly_check_in') {
                return <YearlyCheckSubmitted item={item} />;
              } else if (item.actionType === 'cover_note_validated') {
                return (
                  <CoverNoteValidated
                    item={item}
                    downloadFile={previewFile.mutate}
                  />
                );
              } else if (item.actionType === 'protection_requested') {
                return <ProtectionRequested item={item} />;
              } else {
                // fall back to debug view
                return (
                  <div className="historyCard">
                    <span className="historyCard-date">
                      {new Date(item.createdAt).toLocaleDateString()}
                    </span>

                    <span className="historyCard-message">
                      {item.actionType}
                    </span>

                    <span>
                      {JSON.stringify(item.actionExtraData, undefined, 4)}
                    </span>
                  </div>
                );
              }
            })}
          </div>
        </Modal>
      )}
    </>
  );
};

const formatChange = (change: any): string => {
  switch (change?.kind) {
    case 'E':
      // simplify "updated_at" change
      if (change?.path[0] === 'updated_at') {
        return 'Changed the assessment timestamp';
      }
      return `Changed ${formatPath(change?.path)} from ${JSON.stringify(
        change?.lhs
      )} to ${JSON.stringify(change?.rhs)}`;
    case 'N':
      return `Added ${formatPath(change?.path)} with value ${JSON.stringify(
        change?.rhs
      )}`;
    case 'D':
      return `Removed ${formatPath(change?.path)} with value ${JSON.stringify(
        change?.lhs
      )}`;
    case 'A':
      return formatChange({
        ...change.item,
        path: change?.path
      });
    default:
      return 'Unknown change';
  }
};

const formatPath = (path?: string[]): string => {
  if (!path) return '';

  // return path, minus "input_data"
  return path.filter((p) => p !== 'input_data').join('.');
};
