import { makeQueryString, pack } from '@/utils';
import { api } from './api';

import { DownloadedFile } from '@/types/file';

export interface UpdateFileType {
  fileId: string;
  displayName?: string;
  visibleToUser?: boolean;
  category?: string;
}

export const postFiles = (
  id: string,
  files: string[],
  type: string,
  category?: string
) => {
  const formData = new FormData();

  pack(files).forEach((file) => formData.append('file', file as string | Blob));

  return api.post(
    `/file/${id}${makeQueryString({ type, category })}`,
    formData,
    {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>'
      }
    }
  );
};

export const getFileCategories = () => api.get('/file/categories');

export const updateFile = ({
  fileId,
  displayName,
  visibleToUser,
  category
}: UpdateFileType) =>
  api.patch(`/file/${fileId}`, {
    displayName,
    visibleToUser,
    category
  });

export const downloadFile = (name: string) =>
  api.get<DownloadedFile>(`file${makeQueryString({ name })}`);

export const deleteFileById = (id: string) => api.delete(`/file/${id}`);
