import z from 'zod';

import {
  InsuranceProduct,
  InsuranceProductWithCarrier
} from '@/types/insurance';
import { api, newApiClient } from './api';
import { validateAxiosResponse } from '@/utils/requestValidation';

/**
 * Some of the props defined in src/types/insurance.ts in InsuranceProduct type
 * are missing here. We are not using them right now. The InsuranceProduct type also
 * describes the listing of insurance products and there we use those missing ones.
 */
const GetInsuranceProductByIdResponseSchema = z.object({
  name: z.string(),
  category_id: z.string(),
  carrier_id: z.string(),
  contract_coverage_rating: z.number().nullable(),
  description: z.string(),
  deductible: z.number(),
  commission: z.number(),
  agreement_number: z.string().nullable(),
  multi_risk_allowed: z.boolean(),
  business_vertical: z.string(),
  options: z
    .record(
      z.string(),
      z.object({
        label: z.string(),
        order: z.number(),
        value: z.boolean(),
        version: z.number(),
        attributes: z.array(z.string()),
        disables: z.array(z.string()).optional()
      })
    )
    .nullable(),
  insurance_product_files: z.array(
    z.object({
      category: z.string(),
      // TODO: change to date - now some components (Uploader) is blocking this
      created_at: z.string(),
      display_name: z.string(),
      file_id: z.string(),
      file_name: z.string(),
      file_type: z.string(),
      name: z.string(),
      tag: z.string().nullable(),
      type: z.string(),
      visible_to_user: z.boolean()
    })
  ),
  parameters_values: z
    .object({
      en: z.record(z.string(), z.string()).optional(),
      de: z.record(z.string(), z.string()).optional()
    })
    .nullable(),
  yes_no_values: z
    .record(z.string(), z.union([z.literal(0), z.literal(1), z.undefined()]))
    .nullable(),
  highlighted_attributes: z.record(z.string(), z.boolean()).nullable()
});

export type GetInsuranceProductByIdResponse = z.infer<
  typeof GetInsuranceProductByIdResponseSchema
>;

export type CreateInsuranceProductPayload = Omit<
  GetInsuranceProductByIdResponse,
  'insuranceProductId'
>;

export type UpdateInsuarnceProductPayload = {
  insurance_product_id: string;
} & Partial<
  CreateInsuranceProductPayload & {
    is_archived: boolean;
  }
>;

export const listInsuranceProducts = () =>
  api.get<InsuranceProductWithCarrier[]>('/insurance_product');

export const insuranceProductsDetails = (id: string) =>
  validateAxiosResponse(
    GetInsuranceProductByIdResponseSchema,
    newApiClient.get(`/insurance_product/${id}`)
  );

export const deleteInsuranceProduct = (id: string) =>
  api.delete(`/insurance_product/${id}`);

export const createInsuranceProduct = ({
  category_id,
  business_vertical,
  carrier_id,
  name,
  description,
  deductible,
  commission,
  parameters_values = {
    de: {},
    en: {}
  },
  yes_no_values = {},
  highlighted_attributes = {},
  options = {},
  contract_coverage_rating,
  agreement_number
}: CreateInsuranceProductPayload) =>
  api.post('/insurance_product', {
    category_id,
    business_vertical,
    carrier_id,
    name,
    description,
    deductible,
    commission,
    parameters_values,
    highlighted_attributes,
    yes_no_values,
    options,
    contract_coverage_rating,
    agreement_number
  });

export const updateInsuranceProduct = ({
  insurance_product_id,
  category_id,
  business_vertical,
  carrier_id,
  name,
  contract_coverage_rating,
  description,
  deductible,
  commission,
  agreement_number,
  parameters_values = {
    en: {},
    de: {}
  },
  yes_no_values = {},
  options = {},
  highlighted_attributes = {},
  is_archived,
  multi_risk_allowed
}: UpdateInsuarnceProductPayload) =>
  api.put(`/insurance_product/${insurance_product_id}`, {
    category_id,
    carrier_id,
    business_vertical,
    name,
    contract_coverage_rating,
    description,
    deductible,
    commission,
    parameters_values,
    yes_no_values,
    agreement_number,
    options,
    highlighted_attributes,
    is_archived,
    multi_risk_allowed
  });

export const archiveInsuranceProduct = ({
  insuranceProductId,
  isArchived
}: Partial<InsuranceProduct>) =>
  api.put(`/insurance_product/${insuranceProductId}`, { isArchived });

export const getActiveInsuranceProducts = () =>
  api.get<InsuranceProductWithCarrier[]>('/insurance_product/active');

export const getArchivedInsuranceProducts = () =>
  api.get<InsuranceProductWithCarrier[]>('/insurance_product/archived');
