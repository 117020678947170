import { Form, message, Modal } from 'antd';
import { paths } from '@/routes';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useProductTemplateById,
  useUpdateProductTemplate
} from '@/services/productTemplates';
import { Loader } from '@/components';
import { PlainCategoryLabelMap } from '@/enums';
import { useLocationTypes } from '@/services/locationTypes';
import { useGoBackWithSearch } from '@/utils/hooks/useGoBackWithSearch';
import { ProductTemplateForm } from '@/pages/ProductTemplateItem/ProductTemplateForm';
import { isAxiosError } from 'axios';

export const ProductTemplateItem = () => {
  const { productTemplateId } = useParams<{ productTemplateId: string }>();

  const { isLoading, data: productTemplate } =
    useProductTemplateById(productTemplateId);
  const { data: locationTypes } = useLocationTypes();
  const onClose = useGoBackWithSearch(paths.productTemplates);
  const { mutateAsync: updateProductTemplate } = useUpdateProductTemplate();

  let subtitle = '';

  if (productTemplate) {
    subtitle += `: ${PlainCategoryLabelMap[productTemplate.category]}`;

    if (locationTypes && productTemplate.vertical) {
      subtitle += ` > ${locationTypes.getVerticalLabel(productTemplate.vertical)}`;
    }
  }

  const [form] = Form.useForm<{
    attributes: {
      code: string;
      is_inherited: boolean;
      is_highlighted: boolean;
    }[];
  }>();

  return (
    <Modal
      title={`Edit template${subtitle}`}
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      okText="Save"
      // Maybe we should just open it in new page instead of modal?
      width="90%"
    >
      {isLoading && <Loader />}
      {!productTemplate && <div>Product template not found</div>}
      {productTemplate && (
        <ProductTemplateForm
          type={productTemplate.vertical ? 'vertical' : 'category'}
          onFinish={async (values) => {
            try {
              await updateProductTemplate({
                ...productTemplate,
                ...values
              });
              message.success('Product template updated');
              onClose();
            } catch (e) {
              let messageContent = 'Something went wrong';

              if (isAxiosError(e) && e.response?.data.error) {
                messageContent = e.response?.data.error;
              }

              message.error(messageContent);
            }
          }}
          form={form}
          data={{
            attributes: productTemplate.attributes
          }}
        />
      )}
    </Modal>
  );
};
