import React from 'react';

import { Image } from '../Image';
import './styles.scss';

export const Loader = ({ fill = false }) => (
  <div className={`loader ${fill ? 'loader-fill' : ''}`}>
    <Image name="logo" type="gif" />
  </div>
);
