import React from 'react';

import { useDeleteFile, useDownloadFile, useUploadFiles } from '@/services';
import { DandD } from '@/components/DragAndDrop/index';
import { File } from '@/types/file';

interface UploaderProps {
  max: number;
  maxUploadLimit?: number;
  disabled?: boolean;
  files: File[];
  belongsTo: string;
  queryKeys: string[];
  type: string;
  extended?: boolean;
  category?: string;
}

export const Uploader = ({
  max,
  disabled,
  maxUploadLimit,
  files,
  belongsTo,
  queryKeys,
  type,
  extended,
  category
}: UploaderProps) => {
  const uploadFilesMutation = useUploadFiles(
    belongsTo,
    queryKeys,
    type,
    category
  );
  const deleteFileMutation = useDeleteFile(queryKeys);

  const previewFile = useDownloadFile();

  return (
    <DandD
      multiple
      accept=".pdf,.jpg"
      maxMbSize={200}
      max={max}
      disabled={disabled}
      maxUploadLimit={maxUploadLimit}
      files={files}
      onChange={uploadFilesMutation.mutate}
      onFileClick={(file: File) => previewFile.mutate(file.name as any)}
      onDeleteFile={(file: File) => deleteFileMutation.mutate(file.fileId)}
      extended={extended}
      belongsTo={belongsTo}
    />
  );
};
