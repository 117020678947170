import { api } from './api';

export const updateAddress = ({
  id,
  street,
  streetNr,
  postalCode,
  city,
  country,
  googleLocation
}: UpdateAddressPayload) =>
  api.put(`/address/${id}`, {
    street,
    streetNr,
    postalCode,
    city,
    country,
    googleLocation
  });

export interface UpdateAddressPayload {
  id: string;
  street: string;
  streetNr: string;
  postalCode: string;
  city: string;
  country: string;
  googleLocation: string;
}
