import React, { useState } from 'react';
import { useAttributesDefinitions } from '@/services/attributes';
import { useThrottleCallback } from '@/utils/hooks/useThrottleCallback';
import { AutoComplete, Select, Space } from 'antd';

export const AttributeAutocomplete = ({
  existingAttributes,
  onSelect
}: {
  existingAttributes: string[];
  onSelect: (code: string) => void;
}) => {
  const [language, setLanguage] = useState<'en' | 'de'>('de');
  const [query, setQuery] = useState('');
  const { data: attributesDefinitions } = useAttributesDefinitions(
    {
      page: 1,
      searchQuery: query,
      language
    },
    {
      enabled: query.length >= 3
    }
  );

  const throttledSetQuery = useThrottleCallback((v: string) => {
    setQuery(v);
  }, 300);

  const items =
    attributesDefinitions?.data
      .filter(({ code }) => !existingAttributes.includes(code))
      .map((attribute) => ({
        label: (
          <div
            onClick={() => {
              onSelect(attribute.code);
              setQuery('');
            }}
          >
            <Space>
              <div>{attribute.name_en}</div>
              <div>{attribute.name_de}</div>
            </Space>
          </div>
        ),
        key: attribute.code
      })) ?? [];

  return (
    <Space.Compact>
      <Select
        value={language}
        defaultValue={'de' as 'de' | 'en'}
        className="select-after"
        onChange={(value) => {
          setLanguage(value);
        }}
      >
        <Select.Option value="de">Name DE</Select.Option>
        <Select.Option value="en">Name EN</Select.Option>
      </Select>
      <AutoComplete
        options={items}
        style={{ width: 600 }}
        onSearch={(v) => throttledSetQuery(v)}
        placeholder={
          language === 'de' ? 'Search by German name' : 'Search by English name'
        }
        allowClear
      />
    </Space.Compact>
  );
};
