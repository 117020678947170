import { useQuery, UseQueryOptions } from 'react-query';
import {
  getProductTemplateById,
  listProductTemplates,
  GetProductTemplatesListResponse,
  GetProductTemplateByIdApiResponse,
  createVerticalProductTemplate,
  PostProductTemplateBody,
  PostProductTemplateResponse,
  PutProductTemplateBody,
  PutProductTemplateResponse,
  updateProductTemplate
} from '@/api/productTemplates';
import { useInvalidateOnSuccessMutation } from '@/utils';

const keys = {
  productTemplates: 'productTemplates'
};

export const useProductTemplates = (
  page: number,
  options: UseQueryOptions<
    GetProductTemplatesListResponse,
    unknown,
    GetProductTemplatesListResponse,
    [string, number]
  > = {}
) =>
  useQuery(
    [keys.productTemplates, page],
    () => listProductTemplates({ page }),
    options
  );

export const useProductTemplatesCategory = (
  categoryId: string,
  options: UseQueryOptions<
    GetProductTemplatesListResponse,
    unknown,
    { vertical: string; id: string }[],
    string[]
  > = {}
) =>
  useQuery<
    GetProductTemplatesListResponse,
    unknown,
    { vertical: string; id: string }[],
    string[]
  >(
    [keys.productTemplates, 'byCategory', categoryId],
    () => {
      return listProductTemplates({ categoryId, page: 1 });
    },
    {
      ...options,
      select: ({ data }): { vertical: string; id: string }[] => {
        const template = data[0];

        if (!template) {
          return [];
        }

        const children = (template.children ?? []).map((child) => ({
          id: child.id,
          vertical: child.vertical
        }));

        return [
          {
            id: template.id,
            vertical: 'default'
          },
          ...children
        ];
      }
    }
  );

export const useProductTemplateById = (
  id: string,
  options: UseQueryOptions<
    GetProductTemplateByIdApiResponse,
    unknown,
    GetProductTemplateByIdApiResponse,
    string[]
  > = {}
) =>
  useQuery<
    GetProductTemplateByIdApiResponse,
    unknown,
    GetProductTemplateByIdApiResponse,
    string[]
  >([keys.productTemplates, id], () => getProductTemplateById(id), options);

export const useCreateProductTemplate = () =>
  useInvalidateOnSuccessMutation<PostProductTemplateResponse>(
    [keys.productTemplates],
    (data: PostProductTemplateBody) => createVerticalProductTemplate(data)
  );

export const useUpdateProductTemplate = () =>
  useInvalidateOnSuccessMutation<PutProductTemplateResponse>(
    // We need to invalidate all product templates (specifically the children of the category)
    [keys.productTemplates],
    (data: PutProductTemplateBody) => updateProductTemplate(data)
  );
