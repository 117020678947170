export interface PaymentInfo {
  iban: string;
  bic: string | null;
  priority: 0 | 1;
  companyId: string;
  created_at: Date;
  updated_at: Date;
  paymentId: string;
  name: string | null;
}

export function findDefaultPayment(
  paymentInfos: PaymentInfo[]
): PaymentInfo | undefined {
  return paymentInfos.find((el) => el.priority === 1);
}
