import {
  Button,
  Form,
  FormInstance,
  FormListFieldData,
  Space,
  Table,
  Tag,
  Typography
} from 'antd';
import React, { useState } from 'react';
import { StarButton } from '@/components/YesNo/StarButton';
import { DeleteOutlined } from '@ant-design/icons';
import { AttributeAutocomplete } from '@/pages/ProductTemplateItem/AttributeAutocomplete';
import { useAttributesTranslations } from '@/services/attributes';
import { ColumnsType } from 'antd/es/table';

interface FormData {
  attributes: {
    code: string;
    is_inherited: boolean;
    is_highlighted: boolean;
  }[];
}

export const ProductTemplateForm = ({
  onFinish,
  form,
  data,
  type
}: {
  type: 'vertical' | 'category';
  onFinish: (values: FormData) => void;
  form: FormInstance<FormData>;
  data?: FormData;
}) => {
  const [highlightedAttributesCount, setHighlightedAttributesCount] =
    useState<number>(
      data?.attributes.filter(({ is_highlighted }) => is_highlighted).length ??
        0
    );

  const { isLoading: isLoadingENTranslation, data: enAttributes } =
    useAttributesTranslations('en');
  const { isLoading: isLoadingDETranslation, data: deAttributes } =
    useAttributesTranslations('de');

  const currentAttributes = Form.useWatch('attributes', form) ?? [];

  if (isLoadingENTranslation || isLoadingDETranslation) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/*
      <Typography.Title level={4}>Tax</Typography.Title>
      <Input value={productTemplate.tax} />
      */}
      <Typography.Title level={4}>Attributes</Typography.Title>

      <Form
        onFinish={onFinish}
        initialValues={data}
        form={form}
        onValuesChange={(_changed, allValues) => {
          const count = allValues.attributes.filter(
            ({ is_highlighted }) => is_highlighted
          ).length;

          setHighlightedAttributesCount(count);
        }}
      >
        <Form.List
          name="attributes"
          rules={[
            {
              validator: (_rule, value: { is_highlighted: boolean }[]) => {
                if (!value) {
                  return Promise.resolve();
                }

                const highlightedAttributes = value.filter(
                  ({ is_highlighted }) => is_highlighted
                );

                if (highlightedAttributes.length > 5) {
                  return Promise.reject(
                    'You can only highlight up to 5 attributes'
                  );
                }

                return Promise.resolve();
              },
              message: 'max 5 items'
            }
          ]}
        >
          {(fields, { add, remove }) => {
            const dataSource = fields.map((field, index) => ({
              key: field.key,
              field,
              ...form.getFieldValue(['attributes', index])
            }));

            const columns: ColumnsType<{
              code: string;
              field: FormListFieldData;
              is_highlighted: boolean;
              is_inherited: boolean;
            }> = [
              {
                title: 'Highlighted',
                dataIndex: 'is_highlighted',
                key: 'is_highlighted',
                render: (is_highlighted: boolean, { field }) => {
                  return (
                    <Form.Item
                      shouldUpdate={true}
                      {...field}
                      name={[field.name, 'is_highlighted']}
                      noStyle
                    >
                      <StarButton
                        disabled={
                          highlightedAttributesCount === 5 && !is_highlighted
                        }
                      />
                    </Form.Item>
                  );
                }
              },
              {
                title: 'Code',
                dataIndex: 'code',
                key: 'code'
              },
              {
                title: 'Name EN',
                dataIndex: 'code',
                key: 'name_en',
                render: (code: string) => {
                  return enAttributes?.getAttributeLabel(code);
                }
              },
              {
                title: 'Name DE',
                dataIndex: 'code',
                key: 'name_de',
                render: (code: string) => {
                  return deAttributes?.getAttributeLabel(code);
                }
              },
              {
                title: 'Type',
                dataIndex: 'is_inherited',
                key: 'type',
                render: (is_inherited: boolean) => {
                  if (type === 'category') {
                    return <Tag color="warning">default</Tag>;
                  }

                  return is_inherited ? (
                    <Tag color="warning">inherited</Tag>
                  ) : (
                    <Tag color="processing">vertical</Tag>
                  );
                }
              },
              {
                title: 'Actions',
                dataIndex: 'is_inherited',
                key: 'actions',
                render: (is_inherited: boolean, { field }) => {
                  return (
                    <Space>
                      {!is_inherited && (
                        <Button
                          icon={<DeleteOutlined style={{ color: 'red' }} />}
                          onClick={() => {
                            remove(field.name);
                          }}
                        ></Button>
                      )}
                    </Space>
                  );
                }
              }
            ];

            return (
              <>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                />
                <Space direction="vertical" style={{ marginTop: '1rem' }}>
                  <Typography.Title level={4}>
                    Add new attribute
                  </Typography.Title>
                  <AttributeAutocomplete
                    existingAttributes={currentAttributes.map(
                      ({ code }) => code
                    )}
                    onSelect={(selected) => {
                      add({
                        code: selected,
                        is_inherited: false,
                        is_highlighted: false
                      });
                    }}
                  />
                </Space>
              </>
            );
          }}
        </Form.List>
      </Form>
    </>
  );
};
