import { Roles, UserTypes } from '@/enums';
import {
  both,
  complement,
  find,
  join,
  pathEq,
  pipe,
  prop,
  propEq,
  props,
  length,
  lt
} from 'ramda';
import { Company } from '@/types/company';
import { User } from '@/types/user';

export const isAdmin = propEq('role', Roles.admin);
export const isMD = pathEq(['userTypes', UserTypes.md], true);
export const isOwner = both(
  pathEq(['userTypes', UserTypes.owner], true),
  complement(isMD)
);
export const hasMoreThanOneUser = pipe(length, lt(1));

const findByRole = (role: string) =>
  pipe(prop('users'), find(pathEq(['userTypes', role], true)));

export const findOwner = findByRole(UserTypes.owner);
export const findMDs = (company: Company): User[] => {
  return company.users.filter(({ userTypes }) => userTypes.MD);
};

export const getUserFullname = pipe(props(['name', 'surname']), join(' '));
