import React from 'react';
import { ActionsHistory } from '@/types/actionsHistory';

interface Props {
  item: ActionsHistory;
  downloadFile: (fileId: string) => void;
}

export const CoverNoteValidated = ({ item, downloadFile }: Props) => (
  <div className="historyCard">
    <span className="historyCard-date">
      {new Date(item.createdAt).toLocaleDateString()}
    </span>

    <span className="historyCard-message">
      {item.actionExtraData?.adminName} validated Deckungsnote
    </span>

    <span
      className="historyCard-message document"
      onClick={() => {
        if (item.actionExtraData?.fileName) {
          downloadFile(item.actionExtraData.fileName);
        }
      }}
    >
      File: {item.actionExtraData?.displayName}
    </span>
  </div>
);
