import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { config } from './config';

Sentry.init({
  dsn: config.sentryDSN,
  environment: config.environment,
  integrations: [new BrowserTracing()],
  tracesSampleRate: config.sentryTracesSampleRate
});
