import React, { useEffect, useState } from 'react';

import { Loader } from '@/components';
import { Modal, Form, Button, Radio } from 'antd';
import './styles.scss';
import { useParams } from 'react-router-dom';
import { useKillerAnswers, useSaveKillerQuestions } from '@/services';
import { killerQuestions } from '@/enums/killerQuestions';
import { toPairs } from 'ramda';

export const KillerQuestionModal = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const saveKillerQuestions = useSaveKillerQuestions(locationId);

  const handleCancel = (hide = true) => {
    form.resetFields();
    setEdit(false);
    if (hide) setVisible(false);
  };

  const { data: killerAnswers, isLoading: isKillerAnswersLoading } =
    useKillerAnswers(locationId, ['LI', 'CO', 'LE', 'CY', 'FL']);

  const handleSubmit = () => {
    saveKillerQuestions
      .mutateAsync({
        questions: form.getFieldsValue()
      })
      .then(() => {
        handleCancel();
      });
  };

  useEffect(() => {
    form?.setFieldsValue(killerAnswers?.data || {});
  }, [form, killerAnswers?.data]);

  if (isKillerAnswersLoading) return <Loader />;

  const FooterComponent = (
    <div>
      <Button onClick={() => handleCancel(!edit)}>
        {edit ? 'Cancel' : 'Close'}
      </Button>
      {edit ? (
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
      ) : (
        <Button type="primary" onClick={() => setEdit(true)}>
          Edit
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Button onClick={() => setVisible(true)} type="link">
        {'View'}
      </Button>
      {visible && (
        <Modal
          centered
          footer={FooterComponent}
          title="Killer Questions"
          closable={false}
          cancelText="Close"
          okText="Submit"
          className="add-company-modal"
          open={visible}
        >
          <Form
            disabled={!edit}
            className="assesmentForm"
            initialValues={killerAnswers?.data || {}}
            layout="vertical"
            form={form}
            style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
          >
            {toPairs(killerQuestions).map(([key, label]) => (
              <Form.Item
                className="killer-questions-form"
                key={key}
                name={key}
                label={label}
              >
                <Radio.Group optionType="button" buttonStyle="solid">
                  <Radio value={true}>Ja</Radio>
                  <Radio value={false}>Nein</Radio>
                </Radio.Group>
              </Form.Item>
            ))}
          </Form>
        </Modal>
      )}
    </>
  );
};
