import React from 'react';

import { Loader, Table } from '@/components';
import { useDeleteUser, useListUsers } from '@/services';
import { ShortUser } from '@/types/user';
import { useItemDialog, useLocalStorage } from '@/utils';
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Popconfirm } from 'antd';
import { UserModal } from './UserModal';
import './styles.scss';

export const UserDashboard = () => {
  const {
    isOpen,
    openItemDialog,
    closeItemDialog,
    item: selectedUserId
  } = useItemDialog<string>();

  const { data, isLoading } = useListUsers();
  const deleteUserMutation = useDeleteUser();
  const [sortsAndFilters, setSortsAndFilters] = useLocalStorage(
    'usersSortAndFilters',
    {
      sorts: {},
      filters: {}
    }
  );
  const makeRecords = (search?: string) => {
    if (search) {
      return (
        data?.filter(
          ({ name, surname, email }) =>
            name.toLowerCase().includes(search.toLowerCase()) ||
            surname?.toLowerCase().includes(search.toLowerCase()) ||
            email?.toLowerCase().includes(search.toLowerCase())
        ) || []
      );
    }
    return data || [];
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'name',
      sortOrder:
        sortsAndFilters.sorts.field === 'name'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a: ShortUser, b: ShortUser) => a?.name?.localeCompare(b?.name)
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      sortOrder:
        sortsAndFilters.sorts.field === 'surname'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a: ShortUser, b: ShortUser) =>
        a?.surname?.localeCompare(b?.surname)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sortOrder:
        sortsAndFilters.sorts.field === 'email'
          ? sortsAndFilters.sorts.order
          : null,
      sorter: (a: ShortUser, b: ShortUser) => a?.email?.localeCompare(b?.email)
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: '',
      render: (__: unknown, { auth0 }: ShortUser) => (
        <Dropdown overlay={<UserDashboardActions id={auth0} />}>
          <MenuOutlined style={{ cursor: 'pointer' }} />
        </Dropdown>
      )
    }
  ];

  const UserDashboardActions = ({ id }: { id: string }) => (
    <Menu
      items={[
        {
          key: `${id}-edit`,
          label: 'Edit',
          onClick: () => openItemDialog(id)
        },
        {
          key: `${id}-popover`,
          label: (
            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => deleteUserMutation.mutate(id)}
            >
              <div style={{ color: 'red' }}>Delete</div>
            </Popconfirm>
          )
        }
      ]}
    />
  );

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="table-container">
        <Table
          onButtonClick={() => openItemDialog()}
          searchPlaceholder="Search by name, surname or email"
          buttonText="New user"
          setSortsAndFilters={setSortsAndFilters}
          rowKey="auth0"
          columns={columns}
          records={makeRecords}
          searchKey="userSearch"
          paginationKey="userPagination"
        />
      </div>
      {isOpen && (
        <UserModal
          id={selectedUserId}
          visible={isOpen}
          onClose={closeItemDialog}
        />
      )}
    </div>
  );
};
