import React, { useState } from 'react';

import { Loader } from '@/components';
import { useCreateUser, useUpdateUser, useUserDetails } from '@/services';
import { Checkbox, Form, Input, Modal, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import './styles.scss';

interface UserModalProps {
  id?: string;
  visible: boolean;
  onClose: (val: boolean) => void;
}

export const UserModal = ({ id, visible, onClose }: UserModalProps) => {
  const [form] = Form.useForm();
  const updateUserMutation = useUpdateUser(id || '');
  const createUserMutation = useCreateUser();
  const { data, isLoading } = useUserDetails(id || '', {
    enabled: Boolean(id)
  });
  const [isEmailEnabled, setIsEmailEnabled] = useState(false);

  const onSubmit = () =>
    form.validateFields().then((values) => {
      const formattedValues = { ...values, email: values.email.toLowerCase() };
      const mutation = !!id ? updateUserMutation : createUserMutation;

      mutation.mutateAsync(formattedValues).then(() => onClose(false));
    });

  const handleIsEmailEnabled = (e: CheckboxChangeEvent) => {
    setIsEmailEnabled(e.target.checked);
  };

  if (id && isLoading) return <Loader />;

  return (
    <Modal
      centered
      onCancel={() => onClose(false)}
      okText={!!id ? 'Edit' : 'Create'}
      onOk={onSubmit}
      cancelText="Cancel"
      title={!!id ? 'Edit User' : 'New User'}
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form layout="vertical" initialValues={data?.[0] || {}} form={form}>
        <Form.Item
          label="First Name"
          name="name"
          rules={[{ required: true, message: 'name is required' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="Surname"
          name="surname"
          rules={[{ required: true, message: 'surname is required' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Email is required' },
            { type: 'email', message: 'please provide a valid email' }
          ]}
        >
          <Input disabled={!!id && !isEmailEnabled} placeholder="Eingeben" />
        </Form.Item>
        <Row align="middle" justify="space-between">
          {id && (
            <Checkbox onChange={handleIsEmailEnabled}>Edit e-mail</Checkbox>
          )}
          <Form.Item
            name="emailNotificationsDisabled"
            valuePropName="checked"
            className="form-no-margin"
          >
            <Checkbox>Turn off email notifications</Checkbox>
          </Form.Item>
        </Row>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'phone is required' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
