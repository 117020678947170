import React, { Fragment, useState } from 'react';

import { Loader } from '@/components';
import { useAddCarrier, useCarrier, useUpdateCarrier } from '@/services';
import { Modal, Form, Input, message, Rate, Checkbox } from 'antd';
import { columns } from '.';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface CarrierModalProps {
  visible: boolean;
  onClose: (arg?: boolean) => void;
  id?: string;
}

interface UpdateCarrierArgs {
  logoUrl: string;
  name: string;
  postalCode: string;
  city: string;
  street: string;
  country: string;
  acronym: string;
  generalBrokerId: string;
  clientBrokerId: string;
  contactPerson: string;
  contactPhoneNr: string;
  email: string;
  brokerSupportEmail: string;
  creationOfferEmail: string;
  contractDocumentsEmail: string;
  clientTransferEmail: string;
  requestProtectionEmail: null;
  generalRequestProtectionEmail: null;
  linkPriceCalculator: string;
  linkPriceCalculatorUsername: string;
  linkPriceCalculatorPassword: string;
  linkBrokerSite: string;
  linkBrokerSiteUsername: string;
  linkBrokerSitePassword: string;
  linkPostbox: string;
  linkPostboxUsername: string;
  linkPostboxPassword: string;
  notes: string;
  commissionFile: string;
  folderLink: string;
  contactUpdatesDates: string;
  customerSatisfactionRating: number;
  claimHandlingRating: number;
  responseTimeRating: number;
}

export const CarrierModal = ({ visible, onClose, id }: CarrierModalProps) => {
  const [form] = Form.useForm();
  // fixme: we shouldn't use casting in below case
  const { data, isLoading } = useCarrier(id as string, {
    enabled: Boolean(id)
  });
  const updateCarrierMutation = useUpdateCarrier(id);
  const addCarrierMutation = useAddCarrier();
  const [isEditingLogoUrl, setIsEditingLogoUrl] = useState(false);

  const handleEditLogoUrl = (e: CheckboxChangeEvent) => {
    setIsEditingLogoUrl(e.target.checked);
  };

  if (isLoading) return <Loader />;

  const onSubmit = async (submitData: Partial<UpdateCarrierArgs>) => {
    const mutation = id ? updateCarrierMutation : addCarrierMutation;

    await mutation.mutateAsync(submitData);
    message.success(
      id ? 'Carrier updated successfully' : 'Carrier added successfully'
    );
    onClose();
  };

  return (
    <Modal
      centered
      onOk={form.submit}
      onCancel={() => onClose(false)}
      okText={id ? 'Update' : 'Add'}
      title={id ? 'Update Carrier' : 'Add Carrier'}
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form
        scrollToFirstError
        initialValues={data || {}}
        layout="vertical"
        form={form}
        style={{ gap: '20px', display: 'flex', flexDirection: 'column' }}
        onFinish={onSubmit}
      >
        {columns.map(({ title, dataIndex }) =>
          dataIndex === 'logoUrl' ? (
            <Fragment key={dataIndex}>
              <Form.Item label={title} name={dataIndex}>
                <Input disabled={!isEditingLogoUrl} />
              </Form.Item>
              <Checkbox onChange={handleEditLogoUrl}>Edit logo</Checkbox>
            </Fragment>
          ) : (
            <Form.Item label={title} name={dataIndex} key={dataIndex}>
              <Input />
            </Form.Item>
          )
        )}
        <Form.Item
          label="Customer satisfaction"
          name="customerSatisfactionRating"
        >
          <Rate />
        </Form.Item>
        <Form.Item label="Claims handling" name="claimHandlingRating">
          <Rate />
        </Form.Item>
        <Form.Item
          label="Processing speed of enquiries"
          name="responseTimeRating"
        >
          <Rate />
        </Form.Item>
      </Form>
    </Modal>
  );
};
