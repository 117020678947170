export const Roles = {
  admin: 'admin',
  user: 'user'
};

export const UserTypes = {
  owner: 'owner',
  md: 'MD',
  companyUser: 'companyUser'
};
