import { api } from './api';

export const getAllCommissions = () => api.get('/admin/commissions');

export interface GetCommissionPayload {
  categoryId: string;
  carrierId: string;
}

export interface UpdateCommissionPayload extends GetCommissionPayload {
  commission: number;
}

export const updateCommission = ({
  categoryId,
  carrierId,
  commission
}: UpdateCommissionPayload) =>
  api.put(`/admin/commissions/categories/${categoryId}/carriers/${carrierId}`, {
    commission
  });
