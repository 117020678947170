import { z } from 'zod';

import { newApiClient } from './api';
import { camelToSnakeCase, snakeToCamelCase } from '@/utils';
import { validateAxiosResponse } from '@/utils/requestValidation';

const AttributeTranslationsApiResponseSchema = z.record(
  z.string(),
  z.object({
    name: z.string(),
    description: z.string().nullable()
  })
);

export const getAttributesTranslations = async (
  language = 'de'
): Promise<{
  getAttributeLabel: (code: string) => string;
  getAttributeDescription: (code: string) => string | null;
}> => {
  const attributes = await validateAxiosResponse(
    AttributeTranslationsApiResponseSchema,
    newApiClient.get(`/public/attributes?language=${language}`)
  );

  return {
    /**
     * Returns the code as a fallback
     */
    getAttributeLabel(code: string): string {
      // FIXME: remove this when we will use snake case for data everywhere
      const label =
        attributes[snakeToCamelCase(code)]?.name ??
        attributes[camelToSnakeCase(code)]?.name;

      if (!label) {
        console.warn(`No attribute translation found for code: ${code}`);
      }

      return label ?? code;
    },

    getAttributeDescription(code: string): string | null {
      return attributes[code]?.description ?? null;
    }
  };
};

export const AttributeDefinitionSchema = z.object({
  id: z.string(),
  code: z.string(),
  name_en: z.string(),
  name_de: z.string(),
  description_en: z.string().nullable(),
  description_de: z.string().nullable()
});

export type AttributeDefinition = z.infer<typeof AttributeDefinitionSchema>;

export interface AttributeDefinitionPutApiPayload {
  id?: string;
  code: string;
  name_en: string;
  name_de: string;
  description_en?: string;
  description_de?: string;
}

const AttributeDefinitionsApiResponseSchema = z.object({
  data: z.array(AttributeDefinitionSchema),
  meta: z.object({
    total_items: z.number(),
    page_size: z.number(),
    current_page: z.number()
  })
});

export type AttributeDefinitionsApiResponse = z.infer<
  typeof AttributeDefinitionsApiResponseSchema
>;

// in the future this will have more data than the listing item
export type AttributeDefinitionItemApiResponse = AttributeDefinition;

export const getAttributesDefinitionsList = async ({
  page,
  searchQuery,
  language
}: {
  page: number;
  searchQuery?: string;
  language?: string;
}): Promise<AttributeDefinitionsApiResponse> => {
  const searchParams = new URLSearchParams({ page: String(page) });

  if (searchQuery) {
    searchParams.append('searchQuery', searchQuery);
    searchParams.append('language', language ?? 'de');
  }

  return validateAxiosResponse(
    AttributeDefinitionsApiResponseSchema,
    newApiClient.get(`/admin/attributes-definitions?${searchParams.toString()}`)
  );
};

export const getAttributesDefinitionById = async (
  id: string
): Promise<AttributeDefinitionItemApiResponse> =>
  validateAxiosResponse(
    AttributeDefinitionSchema,
    newApiClient.get(`/admin/attributes-definitions/${id}`)
  );

export const createOrUpdateAttributeDefinition = async (
  data: AttributeDefinitionPutApiPayload
): Promise<AttributeDefinitionItemApiResponse> => {
  return newApiClient.put(`/admin/attributes-definitions`, data);
};
