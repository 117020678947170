import { useState } from 'react';

import { Datepicker, UpdatableField } from '@/components';
import { CarrierLogo } from '@/components/CarrierLogo';
import { paymentPeriodOptions } from '@/enums';
import { useCreatePayment } from '@/services';
import { useSubmitCheckout } from '@/services/cart';
import { Company } from '@/types/company';
import { LocationHome } from '@/types/location';
import { findDefaultPayment, PaymentInfo } from '@/types/payment';
import { useGoToPath } from '@/utils';
import { ShoppingCartOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Tag,
  Typography
} from 'antd';
import { concat } from 'ramda';
import './styles.scss';
import { useValidateOffer, ValidateOfferPayload } from './useValidateOffer';

export interface ValidateModalProps {
  visible: boolean;
  onClose: (value: boolean) => void;
  data: {
    company: Company;
    location: LocationHome;
    payments: PaymentInfo[];
    entity:
      | {
          type: 'recommendation';
          recommendationId: string;
          recommendationProductId: string;
        }
      | {
          type: 'insurance';
          insuranceId: string;
        };
    name: string;
    carrier: {
      name: string;
      logoUrl?: string | null;
    };

    startDate?: Date | null | string;
    endDate?: Date | null | string;
    nextPaymentDate?: Date | null | string;
    agreementNumber?: string | null;
    paymentPeriod?: string | null;
    grossPrice?: number | null;
  };
}

export const ValidateModal = ({
  visible,
  onClose,
  data
}: ValidateModalProps) => {
  const [form] = Form.useForm();
  const [isValidatingOffer, setIsValidatingOffer] = useState(false);

  const { company, location, payments } = data;
  const companyId = company.companyId;
  const locationId = location.locationId;

  const goToLocation = useGoToPath(concat(`/companies/${companyId}/`));
  const createNewPaymentMutation = useCreatePayment(companyId);
  const createCheckoutSubmitMutation = useSubmitCheckout();

  const { isLoading: isSubmitting, validateOffer } = useValidateOffer({
    company: company,
    location: location,
    entity: data.entity
  });

  const onSubmit = async (formData: {
    paymentId?: string;
    paymentName: string;
    paymentIban: string;
    nextPaymentDate?: Date;
    startDate?: Date;
    endDate?: Date;
    paymentPeriod?: string;
    agreementNumber?: string;
  }) => {
    const result: ValidateOfferPayload = {
      contractDetails: {
        nextPaymentDate: formData.nextPaymentDate,
        startDate: formData.startDate,
        endDate: formData.endDate,
        paymentPeriod: formData.paymentPeriod,
        agreementNumber: formData.agreementNumber
      },
      payment: formData.paymentId
        ? { paymentId: formData.paymentId }
        : { name: formData.paymentName, iban: formData.paymentIban },
      finalize: isValidatingOffer
    };

    const isCompanyAddressEdited = form.isFieldsTouched(['companyAddress']);

    if (isCompanyAddressEdited) {
      const companyAddress = form.getFieldValue('companyAddress');
      result.companyAddress = {
        street: companyAddress.streetName,
        streetNr: companyAddress.streetNumber,
        postalCode: companyAddress.postalCode,
        city: companyAddress.city,
        country: company?.address.country
      };
    }

    const isCompanyEdited = form.isFieldsTouched([
      'companyName',
      'companyFoundationDate'
    ]);

    if (isCompanyEdited) {
      const { companyName, companyFoundationDate } = form.getFieldsValue();
      result.company = {
        name: companyName,
        foundedDate: new Date(companyFoundationDate).toISOString()
      };
    }

    const isLocationAddressEdited = form.isFieldsTouched(['locationAddress']);

    if (isLocationAddressEdited) {
      const locationAddress = form.getFieldValue('locationAddress');
      result.locationAddress = {
        street: locationAddress.streetName,
        streetNr: locationAddress.streetNumber,
        postalCode: locationAddress.postalCode,
        city: locationAddress.city,
        country: location?.address.country
      };
    }

    try {
      await validateOffer(result);
      onClose(true);
      goToLocation(locationId);
    } catch (e) {
      // this error will be handled alredy, but without this catch it's failing
    }
  };

  const onNewClick = () => form.setFieldValue('paymentId', null);

  const copyCompanyAddress = (isLocationAddressTheSame: boolean) => {
    let newAddress = {
      streetName: undefined,
      streetNumber: undefined,
      postalCode: undefined,
      city: undefined
    };

    if (isLocationAddressTheSame) {
      const companyAddress = form.getFieldValue('companyAddress');
      newAddress = {
        streetName: companyAddress.streetName,
        streetNumber: companyAddress.streetNumber,
        postalCode: companyAddress.postalCode,
        city: companyAddress.city
      };
    }

    form.setFieldsValue({
      locationAddress: newAddress
    });
  };

  const defaultPaymentInfo = payments?.length
    ? findDefaultPayment(payments)
    : null;

  const editingCompanyDisabled = company?.poaSigned;

  const initialValues = {
    nextPaymentDate: data.nextPaymentDate,
    startDate: data.startDate,
    endDate: data.endDate,
    agreementNumber: data.agreementNumber,
    paymentPeriod: data.paymentPeriod,
    paymentId: defaultPaymentInfo?.paymentId ?? payments[0]?.paymentId,
    companyName: company?.name,
    companyFoundationDate: company?.foundedDate,
    companyAddress: {
      streetName: company?.address.street,
      streetNumber: company?.address.streetNr,
      postalCode: company?.address.postalCode,
      city: company?.address.city
    },
    locationAddress: {
      streetName: location?.address.street,
      streetNumber: location?.address.streetNr,
      postalCode: location?.address.postalCode,
      city: location?.address.city
    }
  };

  const requiredForContractValidationRule = {
    required: isValidatingOffer,
    message: 'Required!'
  };

  return (
    <Modal
      centered
      title="Validate Offer"
      closable={false}
      className="add-company-modal"
      open={visible}
      confirmLoading={
        createCheckoutSubmitMutation.isLoading ||
        createNewPaymentMutation.isLoading
      }
      footer={null}
    >
      <Form
        onFinish={onSubmit}
        initialValues={initialValues}
        layout="vertical"
        form={form}
        disabled={isSubmitting}
      >
        <div className="validate-carrier-container">
          <CarrierLogo name={data.carrier.logoUrl} />
          <div className="validate-carrier-info">
            <span className="validate-carrier-carrierName">
              {data.carrier.name}
            </span>
            <span className="validate-carrier-productName">{data.name}</span>
          </div>
        </div>

        <Typography.Title level={4}>Insurance details</Typography.Title>
        <Form.Item
          label="Next payment date"
          name="nextPaymentDate"
          rules={[requiredForContractValidationRule]}
        >
          <Datepicker />
        </Form.Item>
        <Form.Item
          label="Start date"
          name="startDate"
          rules={[requiredForContractValidationRule]}
        >
          <Datepicker />
        </Form.Item>
        <Form.Item
          label="End date"
          name="endDate"
          rules={[requiredForContractValidationRule]}
        >
          <Datepicker />
        </Form.Item>
        <Form.Item
          label="Payment period"
          name="paymentPeriod"
          rules={[requiredForContractValidationRule]}
        >
          {/* disabled as payment period requires price recalculation for auto reco */}
          <Select disabled={true}>
            {paymentPeriodOptions.map(({ label, value }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="RaVe. Nr."
          name="agreementNumber"
          rules={[requiredForContractValidationRule]}
        >
          <Input readOnly disabled={true} />
        </Form.Item>

        <Typography.Title level={4}>Insurance holder details</Typography.Title>
        {editingCompanyDisabled && (
          <Alert
            showIcon
            type="info"
            message="POA is already signed. You can't edit company details."
            style={{ marginBottom: '1rem' }}
          />
        )}
        <Form.Item
          label="Company name"
          name="companyName"
          rules={[requiredForContractValidationRule]}
        >
          <Input disabled={editingCompanyDisabled} />
        </Form.Item>
        <Form.Item
          label="Foundation date"
          name="companyFoundationDate"
          rules={[requiredForContractValidationRule]}
        >
          <Datepicker disabled={editingCompanyDisabled} />
        </Form.Item>
        <Form.Item
          label="Street name"
          name={['companyAddress', 'streetName']}
          rules={[requiredForContractValidationRule]}
        >
          <Input disabled={editingCompanyDisabled} />
        </Form.Item>
        <Form.Item
          label="Street number"
          name={['companyAddress', 'streetNumber']}
          rules={[requiredForContractValidationRule]}
        >
          <Input disabled={editingCompanyDisabled} />
        </Form.Item>
        <Form.Item
          label="Postal code"
          name={['companyAddress', 'postalCode']}
          rules={[requiredForContractValidationRule]}
        >
          <Input disabled={editingCompanyDisabled} />
        </Form.Item>
        <Form.Item
          label="City"
          name={['companyAddress', 'city']}
          rules={[requiredForContractValidationRule]}
        >
          <Input disabled={editingCompanyDisabled} />
        </Form.Item>

        <Typography.Title level={4}>Risk location details</Typography.Title>

        <Form.Item
          label="Street name"
          name={['locationAddress', 'streetName']}
          rules={[requiredForContractValidationRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Street number"
          name={['locationAddress', 'streetNumber']}
          rules={[requiredForContractValidationRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Postal code"
          name={['locationAddress', 'postalCode']}
          rules={[requiredForContractValidationRule]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="City"
          name={['locationAddress', 'city']}
          rules={[requiredForContractValidationRule]}
        >
          <Input />
        </Form.Item>

        <Checkbox onChange={(evt) => copyCompanyAddress(evt.target.checked)}>
          Location address equals company address?
        </Checkbox>

        <Typography.Title level={4}>Payment method</Typography.Title>
        <Form.Item
          name="paymentId"
          rules={[
            {
              validator: async (_, value: string) => {
                const { paymentName, paymentIban } = form.getFieldsValue();
                if (!value && (!paymentName || !paymentIban)) {
                  return Promise.reject(new Error('Payment is required!'));
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Radio.Group>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: 'flex' }}
            >
              {payments.map(({ name, iban, paymentId, priority }) => (
                <Card
                  size="small"
                  key={paymentId}
                  title={
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '1rem' }}>{name}</div>
                      <div>
                        {priority === 1 && <Tag color="green">Default</Tag>}
                      </div>
                    </div>
                  }
                  extra={<Radio value={paymentId} />}
                  style={{ width: '100%' }}
                >
                  <p>{iban}</p>
                </Card>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <UpdatableField>
          {() =>
            form.getFieldValue('paymentId') === null ? (
              <>
                <Form.Item
                  rules={[{ required: true, message: 'Required!' }]}
                  name="paymentName"
                >
                  <Input placeholder="Name" />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Required!' }]}
                  name="paymentIban"
                >
                  <Input placeholder="Iban" />
                </Form.Item>
              </>
            ) : (
              <Button onClick={onNewClick}>Add new payment</Button>
            )
          }
        </UpdatableField>
        <div className="validate-sum-total-container">
          <span className="validate-sum-total-label">
            Sum total (incl. tax):{' '}
          </span>
          {data.grossPrice}
        </div>
        <Space>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button
            loading={isSubmitting && !isValidatingOffer}
            onClick={() => {
              setIsValidatingOffer(false);
              form.submit();
            }}
          >
            Save & Close
          </Button>
          <Button
            loading={isSubmitting && isValidatingOffer}
            icon={<ShoppingCartOutlined />}
            type="primary"
            onClick={() => {
              setIsValidatingOffer(true);
              form.submit();
            }}
          >
            Validate Offer
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};
