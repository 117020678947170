import React from 'react';

import { Loader, Table } from '@/components';
import { AutoDetectUrl } from '@/components/AutoDetectUrl';
import { CarrierLogo } from '@/components/CarrierLogo';
import {
  UpdateCarrierArgs,
  useAllCarriersData,
  useUpdateCarrier
} from '@/services';
import { Carrier } from '@/types/carrier';
import { useItemDialog } from '@/utils';
import { Rate, Typography } from 'antd';
import { UseMutateFunction } from 'react-query';
import { CarrierModal } from './CarrierModal';

export const columns = [
  {
    title: 'Carrier logo',
    dataIndex: 'logoUrl',
    fixed: 'left',
    render: (logoUrl: string) => <CarrierLogo name={logoUrl} />
  },
  {
    title: 'Carrier',
    dataIndex: 'name',
    fixed: 'left',
    sorter: (a: Carrier, b: Carrier) => a.name?.localeCompare(b.name),
    width: 250
  },
  {
    title: 'ZIP code/postal code',
    dataIndex: 'postalCode',
    width: 100
  },
  {
    title: 'City',
    dataIndex: 'city'
  },
  {
    title: 'Street',
    dataIndex: 'street'
  },
  {
    title: 'Country',
    dataIndex: 'country'
  },
  {
    title: 'Acronym',
    dataIndex: 'acronym'
  },
  {
    title: 'general Broker-ID',
    dataIndex: 'generalBrokerId'
  },
  {
    title: 'broker-Id Client transfer',
    dataIndex: 'clientBrokerId'
  },
  {
    title: 'Contact person',
    dataIndex: 'contactPerson'
  },
  {
    title: 'Phone nr',
    dataIndex: 'contactPhoneNr'
  },
  {
    title: 'E-mail',
    dataIndex: 'email'
  },
  {
    title: 'Email brokerage/broker support',
    dataIndex: 'brokerSupportEmail'
  },
  {
    title: 'E-mail creation offer',
    dataIndex: 'creationOfferEmail'
  },
  {
    title: 'E-mail Contract documents',
    dataIndex: 'contractDocumentsEmail'
  },
  {
    title: 'E-mail Client transfer',
    dataIndex: 'clientTransferEmail'
  },
  {
    title: 'E-mail Request protection',
    dataIndex: 'requestProtectionEmail'
  },
  {
    title: 'E-mail General request protection',
    dataIndex: 'generalRequestProtectionEmail'
  },
  {
    title: 'Link price calculator',
    dataIndex: 'linkPriceCalculator',
    render: (value: string) => <AutoDetectUrl text={value} />
  },
  {
    title: 'Username',
    dataIndex: 'linkPriceCalculatorUsername'
  },
  {
    title: 'Password',
    dataIndex: 'linkPriceCalculatorPassword'
  },
  {
    title: 'Link broker site',
    dataIndex: 'linkBrokerSite',
    render: (value: string) => <AutoDetectUrl text={value} />
  },
  {
    title: 'Username',
    dataIndex: 'linkBrokerSiteUsername'
  },
  {
    title: 'Password',
    dataIndex: 'linkBrokerSitePassword'
  },
  {
    title: 'Link postbox',
    dataIndex: 'linkPostbox'
  },
  {
    title: 'Username',
    dataIndex: 'linkPostboxUsername'
  },
  {
    title: 'Password',
    dataIndex: 'linkPostboxPassword'
  },
  {
    title: 'Notes',
    dataIndex: 'notes'
  },
  {
    title: 'commission file',
    dataIndex: 'commissionFile',
    render: (value: string) => <AutoDetectUrl text={value} />
  },
  {
    title: 'Folder Link',
    dataIndex: 'folderLink',
    render: (value: string) => <AutoDetectUrl text={value} />
  },
  {
    title: 'Contact update dates',
    dataIndex: 'contactUpdatesDates'
  }
];

const makeColumns = (
  openItemDialog: (carrierId: string) => void,
  mutateCarrier: UseMutateFunction<Carrier, unknown, UpdateCarrierArgs, unknown>
) => [
  {
    title: '',
    dataIndex: 'carrierId',
    fixed: 'left',
    width: 80,
    render: (carrierId: string) => (
      <Typography.Link onClick={() => openItemDialog(carrierId)}>
        Edit
      </Typography.Link>
    )
  },
  ...columns,
  {
    title: 'Customer satisfaction',
    dataIndex: 'customerSatisfactionRating',
    width: 180,
    render: (value: number, { carrierId }: Carrier) => (
      <Rate
        defaultValue={value}
        onChange={(customerSatisfactionRating) =>
          mutateCarrier({ carrierId, customerSatisfactionRating })
        }
      />
    )
  },
  {
    title: 'Claims handling',
    dataIndex: 'claimHandlingRating',
    width: 180,
    render: (value: number, { carrierId }: Carrier) => (
      <Rate
        defaultValue={value}
        onChange={(claimHandlingRating) =>
          mutateCarrier({ carrierId, claimHandlingRating })
        }
      />
    )
  },
  {
    title: 'Processing speed of enquiries',
    dataIndex: 'responseTimeRating',
    width: 180,
    render: (value: number, { carrierId }: Carrier) => (
      <Rate
        defaultValue={value}
        onChange={(responseTimeRating) =>
          mutateCarrier({ carrierId, responseTimeRating })
        }
      />
    )
  }
];

export const Carriers = () => {
  const { data, isLoading } = useAllCarriersData();
  const updateCarrierMutation = useUpdateCarrier();

  const {
    item: carrierId,
    openItemDialog,
    closeItemDialog,
    isOpen
  } = useItemDialog<string>();

  const makeRecords = (search?: string) => {
    if (search) {
      return (
        data?.filter(({ name }) =>
          name.toLowerCase().includes(search.toLowerCase())
        ) || []
      );
    }
    return data || [];
  };
  if (isLoading) return <Loader />;

  return (
    <>
      <Table
        onButtonClick={() => openItemDialog()}
        searchPlaceholder="Search by carrier name"
        buttonText="Add new carrier"
        searchKey="carrierSearch"
        rowKey="carrierId"
        paginationKey="carrierPagination"
        records={makeRecords}
        columns={makeColumns(openItemDialog, updateCarrierMutation.mutate)}
        scroll={{ x: 4000 }}
      />
      {isOpen && (
        <CarrierModal
          visible={isOpen}
          onClose={closeItemDialog}
          id={carrierId}
        />
      )}
    </>
  );
};
