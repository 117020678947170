import { api } from './api';
import { Admin, CompanyUser } from '@/types/user';

export const listUsers = () => api.get('/admin/users');

export const listAdmin = () => api.get<Admin[]>('/admin/admins');

export const userDetails = (id: string) => api.get(`/admin/user/${id}`);

export const companyUserDetails = (companyId: string, userId: string) =>
  api.get<CompanyUser>(`/admin/companies/${companyId}/users/${userId}`);

export const deleteUser = (id: string) => api.delete(`/admin/users/${id}`);

export const createUser = ({
  email,
  name,
  surname,
  phone,
  emailNotificationsDisabled
}: CreateUserPayload) =>
  api.post('/admin/user', {
    email,
    name,
    surname,
    phone,
    emailNotificationsDisabled
  });

export const updateUser = ({
  name,
  surname,
  phone,
  userId,
  birthdate,
  email,
  emailNotificationsDisabled
}: UpdateUserPayload) =>
  api.put(`/admin/user/${userId}`, {
    name,
    surname,
    phone,
    birthdate,
    email,
    emailNotificationsDisabled
  });

export const updateCompanyUser = ({
  name,
  surname,
  phone,
  userId,
  birthdate,
  email,
  emailNotificationsDisabled,
  role,
  companyId
}: UpdateCompanyUserPayload) =>
  api.put(`/admin/companies/${companyId}/users/${userId}`, {
    name,
    surname,
    phone,
    birthdate,
    email,
    emailNotificationsDisabled,
    role
  });

export const createCompanyUser = ({
  name,
  surname,
  phone,
  birthdate,
  email,
  emailNotificationsDisabled,
  role,
  companyId
}: CreateCompanyUserPayload) =>
  api.post(`/admin/companies/${companyId}/users`, {
    name,
    surname,
    phone,
    birthdate,
    email,
    emailNotificationsDisabled,
    role
  });

export const requestSignPoa = ({ userId, companyId }: RequestSignPoaPayload) =>
  api.post(`/admin/requestSignPoa/${userId}/${companyId}`);

export const requestSignPoaV2 = ({
  userIds,
  companyId
}: RequestSignPoaV2Payload): Promise<{
  success: true;
  partialFailure?: true;
  failedEmails?: string[];
}> =>
  api.post(`/admin/v2/companies/${companyId}/sign-poa-request`, {
    userIds
  });

type CreateUserPayload = {
  email: string;
  name: string;
  surname: string;
  phone: string;
  emailNotificationsDisabled?: boolean;
};

type UpdateUserPayload = {
  name: string;
  surname: string;
  phone: string;
  userId: string;
  birthdate: Date;
  email: string;
  emailNotificationsDisabled?: boolean;
};

type UpdateCompanyUserPayload = {
  name: string;
  surname: string;
  phone: string;
  userId: string;
  birthdate: Date;
  email: string;
  emailNotificationsDisabled?: boolean;
  role: 'owner' | 'md' | 'member';
  companyId: string;
};

export type CreateCompanyUserPayload = {
  name: string;
  surname: string;
  phone: string;
  birthdate: Date;
  email: string;
  emailNotificationsDisabled?: boolean;
  role: 'owner' | 'md' | 'member';
  companyId: string;
};

type RequestSignPoaPayload = {
  userId: string;
  companyId: string;
};

type RequestSignPoaV2Payload = {
  userIds: string[];
  companyId: string;
};
