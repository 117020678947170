import React from 'react';
import { Loader, Table } from '@/components';
import {
  useAllTransferAccount,
  useMarkAsCompletedTA,
  useStartAutomationTA
} from '@/services';
import { capitalize, truncate } from '@/utils';
import { Button, Popover, message } from 'antd';

export const TransferAccount = () => {
  const { data, isLoading } = useAllTransferAccount();
  const startAutomationTa = useStartAutomationTA();
  const completeAutomationTa = useMarkAsCompletedTA();
  truncate(12); // fixme
  if (isLoading) return <Loader />;

  const makeRecords = (search) =>
    data?.filter(({ companyName }) =>
      companyName.toLowerCase().includes(search.toLowerCase())
    );

  const handleTransferAccountComplete = (id) =>
    completeAutomationTa.mutate(id, {
      onSuccess: () => message.success('Automation started successfully')
    });

  const handleTransferAccountStart = (id) =>
    startAutomationTa.mutate(id, {
      onSuccess: () => message.success('Automation completed successfully')
    });

  const columns = [
    {
      fixed: 'left',
      title: '',
      render: (_, b) => (
        <Popover
          trigger="hover"
          content={
            !b.carrierEmail &&
            b.automationCaseTransferAccountStatus !== 'completed' &&
            'Please complete this carrier with an email'
          }
        >
          <Button
            disabled={
              b.automationCaseTransferAccountStatus === 'completed' ||
              !b.carrierEmail
            }
            onClick={() => {
              if (b.automationCaseTransferAccountStatus === null)
                handleTransferAccountStart(b.insuranceId);
              else if (b.automationCaseTransferAccountStatus !== 'completed')
                handleTransferAccountComplete(
                  b.automationCaseTransferAccountId
                );
            }}
          >
            {b.automationCaseTransferAccountStatus === null
              ? 'Start'
              : b.automationCaseTransferAccountStatus === 'completed'
                ? 'Completed'
                : 'Mark As Completed'}
          </Button>
        </Popover>
      )
    },
    {
      width: 180,
      title: 'MD Name',
      dataIndex: 'mdName'
    },
    {
      width: 400,
      title: 'Company Name',
      dataIndex: 'companyName'
    },
    {
      title: 'Carrier Name',
      dataIndex: 'carrierName'
    },
    {
      title: 'Product Name',
      dataIndex: 'name'
    },
    {
      width: 150,
      title: 'Policy Number',
      dataIndex: 'policyNumber'
    },
    {
      width: 200,
      title: 'MD Birthdate',
      dataIndex: 'ownerBirthdate',
      render: (ownerBirthdate) =>
        new Date(ownerBirthdate).toLocaleDateString() === 'Invalid Date' ||
        ownerBirthdate === ''
          ? ''
          : new Date(ownerBirthdate).toLocaleDateString()
    },
    {
      width: 200,
      title: 'Company Address',
      dataIndex: 'companyAddress'
    },
    {
      width: 80,
      title: 'PoA',
      dataIndex: 'companyPoaSigned',
      render: (e) => (e ? 'Signed' : 'Not Signed')
    },
    {
      title: 'Automation Status',
      dataIndex: 'automationCaseTransferAccountStatus',
      render: (e) => (e ? capitalize(e) : 'Not started')
    },
    {
      width: 400,
      title: 'Carrier Email',
      dataIndex: 'carrierEmail'
    }
    // {
    //   width: 200,
    //   title: 'Initial E-mail',
    //   render: (_, b) =>
    //     `${
    //       new Date(
    //         b.automationCaseTransferAccountStartedAt,
    //       ).toLocaleDateString() === 'Invalid Date' ||
    //       !b.automationCaseTransferAccountStartedAt
    //         ? ''
    //         : new Date(
    //             b.automationCaseTransferAccountStartedAt,
    //           ).toLocaleDateString()
    //     } ${b.automationCaseTransferAccountStartedBy || ''}`,
    // },
  ];

  return (
    <Table
      columns={columns}
      records={makeRecords}
      paginationKey="transferAccountPagination"
      searchPlaceholder="Search by company name"
      searchKey="transferAccountSearchValue"
    />
  );
};
