import React from 'react';

import { config } from '../config';

export const CarrierLogo = ({ name }: { name: string | null | undefined }) =>
  !name ? (
    <img
      src={`${config.cdn}/${config.genericCarrierLogo}`}
      style={{ width: '20px', marginRight: '10px' }}
    />
  ) : (
    <img
      src={`${config.cdn}/${name}`}
      style={{ width: '40px', marginRight: '10px' }}
    />
  );
