import * as Sentry from '@sentry/react';

import axios, {
  AxiosError,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig
} from 'axios';
import { identity, mergeLeft, pick } from 'ramda';

class ApiRequestError extends Error {
  constructor(baseUrl: string) {
    super(`Request to ${baseUrl} returned error`);
    this.name = ApiRequestError.name;
  }
}

export const createApiClient = (
  baseURL: string,
  getHeaders = identity as any // fixme
) => {
  const contentInterceptor = (config: InternalAxiosRequestConfig) => {
    const headers = mergeLeft(config.headers || {}, {
      'Content-Type': 'application/json',
      ...getHeaders()
    });

    config.headers = { ...config.headers, ...headers } as AxiosRequestHeaders;

    return config;
  };

  const responseErrorInterceptor = (error: AxiosError) => {
    const captureContext = {
      extra: {
        response: {
          config: pick(
            ['baseUrl', 'url', 'data', 'method'],
            error.response?.config
          ),
          ...pick(['status', 'statusText', 'data'], error.response)
        }
      }
    };
    Sentry.captureException(new ApiRequestError(baseURL), captureContext);
    return Promise.reject(error);
  };

  const multiTenantApi = axios.create({
    baseURL
  });

  multiTenantApi.interceptors.request.use(contentInterceptor);

  multiTenantApi.interceptors.response.use(
    (item) => item,
    responseErrorInterceptor
  );

  return multiTenantApi;
};
