import React, { ComponentType } from 'react';

import { Loader } from '@/components';
import { useAuth } from '@/providers';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, RouteProps } from 'react-router-dom';

export const AuthGuard = ({
  component: Component,
  ...props
}: { component: ComponentType } & Omit<RouteProps, 'component'>) => {
  const { isLoading } = useAuth();

  if (isLoading) return <Loader />;

  const Protected = withAuthenticationRequired(Component, {
    loginOptions: {
      authorizationParams: {
        ui_locales: 'de',
        glass0: '#fff',
        glass100: '#F8FAFC',
        glass300: '#E4EAF1',
        glass500: '#587393',
        errorColor: '#FF5656',
        loginLabel: 'Einloggen',
        loginSubmitLabel: 'Einloggen',
        title: 'Verifizierungscode',
        loginSubtitle: 'zum Einloggen anfordern',
        signUpTitle: 'Konto erstellen',
        signUpLabel: 'Konto erstellen',
        signUpSubmitLabel: 'Konto erstellen',
        forgotPasswordTitle: 'Setzen Sie Ihr Passwort zurück',
        forgotPasswordAction: 'Passwort vergessen?',
        forgotPasswordSubmitLabel: 'Reset-Link senden',
        signUpWithLabel: 'Mit %s erstellen',
        loginWithLabel: 'Mit %s anmelden',
        emailLabel: 'E-Mail Adresse',
        passwordLabel: 'Passwort',
        resetPasswordHint: '',
        signUpTerms:
          'Dieses Angebot unterliegt den SureIn <a>Geschäftsbedingungen</a> sowie den Bestimmungen zur <a>Datenverarbeitung</a>.',
        submitLabel: 'Anfordern',
        invalidEmailErrorHint: 'Ungültige E-Mail-Adresse',
        successMagicLink:
          'Ein Verifizierungscode zum Einloggen wurde an %s gesendet.',
        confirmItIsYou: 'Bestätige deine Identität'
      }
    }
  });

  return <Route {...props} component={Protected} />;
};
