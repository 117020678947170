import {
  useAttributesDefinitions,
  useCreateOrUpdateAttributeDefinition
} from '@/services/attributes';
import { ColumnsType } from 'antd/es/table';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Table
} from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { useUrlParams } from '@/utils/hooks/useUrlParams';
import { useThrottleCallback } from '@/utils/hooks/useThrottleCallback';
import { SearchAwareLink } from '@/components/SearchAwareLink';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AttributeDefinitionForm } from '@/components';
import { AttributeDefinition } from '@/api/attributes';
import { isAxiosError } from 'axios';

const columns: ColumnsType<{
  id: string;
  code: string;
  name_en: string;
  name_de: string;
  description_en: string | null;
  description_de: string | null;
}> = [
  {
    title: 'Code',
    dataIndex: 'code'
  },
  {
    title: 'Name (EN)',
    dataIndex: 'name_en'
  },
  {
    title: 'Name (DE)',
    dataIndex: 'name_de'
  },
  {
    title: 'Description (EN)',
    dataIndex: 'description_en'
  },
  {
    title: 'Description (DE)',
    dataIndex: 'description_de'
  },
  {
    title: 'Actions',
    width: '20%',
    key: 'action',
    render: (_, { id }) => (
      <Space size="middle">
        <SearchAwareLink to={`/attributes/${id}`}>Edit</SearchAwareLink>
      </Space>
    )
  }
];

const Option = Select.Option;

export const Attributes = () => {
  const [creatingNewAttribute, setCreatingNewAttribute] = useState(false);
  const [newAttributeForm] = Form.useForm<Omit<AttributeDefinition, 'id'>>();

  const { current, setParams } = useUrlParams<{
    page: string;
    searchQuery?: string;
    language?: 'en' | 'de';
  }>();

  const { isLoading, data: attributesDefinitions } = useAttributesDefinitions(
    {
      page: current.page ? Number(current.page) : 1,
      searchQuery: current.searchQuery ?? '',
      language: current.language ?? 'de'
    },
    {
      keepPreviousData: true
    }
  );

  const { mutateAsync: createOrUpdateAtteibute, isLoading: isSavingAttribute } =
    useCreateOrUpdateAttributeDefinition();

  const throttledOnSearchQueryChange = useThrottleCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length >= 3) {
        setParams({ searchQuery: e.target.value, page: '1' });
      }

      if (e.target.value.length === 0) {
        setParams({ searchQuery: undefined, page: '1' });
      }
    },
    300
  );

  const startCreatingNewAttribute = () => {
    setCreatingNewAttribute(true);
  };

  return (
    <div>
      <h1>Attributes</h1>
      <div className="dashboard-control-panel">
        <Input.Search
          defaultValue={current.searchQuery}
          placeholder={
            current.language === 'de'
              ? 'Search attributes by german name'
              : 'Search attributes by english name'
          }
          size="large"
          className="dashboardSearch"
          onChange={throttledOnSearchQueryChange}
          allowClear
          loading={isLoading}
          addonBefore={
            <Select
              value={current.language}
              defaultValue={'de' as 'de' | 'en'}
              className="select-after"
              onChange={(value) => {
                setParams({
                  searchQuery: undefined,
                  language: value
                });
              }}
            >
              <Option value="de">Name DE</Option>
              <Option value="en">Name EN</Option>
            </Select>
          }
        />
        <Button
          type="primary"
          size="large"
          icon={<PlusCircleOutlined />}
          onClick={startCreatingNewAttribute}
        >
          New attribute
        </Button>
      </div>

      <Table
        rowKey="id"
        loading={isLoading}
        columns={columns}
        dataSource={attributesDefinitions?.data}
        pagination={{
          total: attributesDefinitions?.meta.total_items,
          pageSize: attributesDefinitions?.meta.page_size,
          current: Number(attributesDefinitions?.meta.current_page ?? '1'),
          showSizeChanger: false
        }}
        onChange={(paginationChange) => {
          setParams({ page: String(paginationChange.current ?? 1) });
        }}
      />

      <Modal
        title="New attribute"
        open={creatingNewAttribute}
        onOk={newAttributeForm.submit}
        onCancel={() => setCreatingNewAttribute(false)}
        confirmLoading={isSavingAttribute}
        width={1000}
      >
        <AttributeDefinitionForm
          form={newAttributeForm}
          creatingNew={true}
          onFinish={async (data) => {
            try {
              await createOrUpdateAtteibute(data);
              setCreatingNewAttribute(false);
            } catch (e) {
              if (isAxiosError(e) && e.response) {
                // not ideal - it would be better to display this error in the form but for now it'll do the job
                message.error(e.response.data.error ?? 'Something went wrong');
              } else {
                throw e;
              }
            }
          }}
        />
      </Modal>
    </div>
  );
};
