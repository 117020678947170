import { isObject } from './type';

export const locationAutocompleteRule = {
  validator: (_: unknown, value: Record<string, unknown>) => {
    return isObject(value)
      ? !value.city
        ? Promise.reject('City is required')
        : !value.country
          ? Promise.reject('Country is required')
          : !value.googleLocation
            ? Promise.reject('City is required')
            : !value.postalCode
              ? Promise.reject('Postal Code is required')
              : !value.street
                ? Promise.reject('Street is required')
                : !value.streetNr
                  ? Promise.reject('Street Number is required')
                  : Promise.resolve()
      : Promise.reject('Please choose an option');
  }
};

export const nonNegativeRule = {
  validator: (_: unknown, value: string) => {
    return !value || parseFloat(value) >= 0
      ? Promise.resolve()
      : Promise.reject('Cannot be negative');
  }
};

export const positiveRule = {
  validator: (_: unknown, value: string) => {
    return parseFloat(value) > 0
      ? Promise.resolve()
      : Promise.reject('Should be positive');
  }
};
