import React from 'react';
import { ActionsHistory } from '@/types/actionsHistory';

interface Props {
  item: ActionsHistory;
}

export const ProtectionRequested = ({ item }: Props) => (
  <div className="historyCard">
    <span className="historyCard-date">
      {new Date(item.createdAt).toLocaleDateString()}
    </span>

    <span className="historyCard-message">
      {item.actionExtraData?.adminEmail} requested protection
    </span>

    <span className="historyCard-message">
      Requested email: {item.actionExtraData?.requestEmail}
    </span>
  </div>
);
