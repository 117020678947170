import React from 'react';

import { fileCategories } from '@/enums/fileTypes';
import { useFileCategories, useUpdateFile } from '@/services';
import { isUndefined } from '@/utils';
import {
  CloseOutlined,
  LoadingOutlined,
  MenuOutlined,
  UploadOutlined
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip,
  Upload,
  message
} from 'antd';
import { format } from 'date-fns';
import {
  __,
  divide,
  map,
  pipe,
  pluck,
  prepend,
  prop,
  sortBy,
  sum
} from 'ramda';
import { useState } from 'react';
import './styles.scss';

const { Option } = Select;

const sumMbSize = pipe(pluck('size'), sum, divide(__, 1024 ** 2));

const transform = pipe(
  map((value) => ({ value, label: fileCategories[value] })),
  sortBy(prop('label')),
  prepend({ value: null, label: 'Uncategorized' })
);

export const DandD = ({
  files = [],
  onChange,
  multiple,
  accept,
  maxMbSize,
  max,
  maxUploadLimit = 10,
  disabled,
  onFileClick,
  onDeleteFile,
  extended,
  belongsTo
}) => {
  const [editId, setEditId] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { data = [] } = useFileCategories({ select: transform });
  const updateFileMutation = useUpdateFile(belongsTo);

  const FileActions = (item) => (
    <Menu
      items={[
        {
          label: 'Edit',
          onClick: () => setEditId(item.fileId)
        },
        {
          label: (
            <Popconfirm
              title="Are you sure to delete this file?"
              onConfirm={() => onDeleteFile(item)}
            >
              <div className="delete-text">Delete</div>
            </Popconfirm>
          )
        }
      ]}
    />
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (displayName, item) =>
        item.fileId === editId ? (
          <Input
            disabled={updateFileMutation.isLoading}
            defaultValue={displayName}
            onBlur={(e) =>
              updateFileMutation.mutate(
                { displayName: e.target.value, fileId: editId },
                { onSuccess: () => setEditId(null) }
              )
            }
          />
        ) : (
          <Tooltip title={displayName} zIndex={1000000}>
            <Button type="link" onClick={() => onFileClick(item)}>
              <span className="filename-link">{displayName}</span>
            </Button>
          </Tooltip>
        )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category = null, { fileId }) => (
        <Select
          className="category-select"
          defaultValue={category}
          onChange={(category) =>
            updateFileMutation.mutate({ category, fileId })
          }
        >
          {data.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      title: 'Visible',
      dataIndex: 'visibleToUser',
      key: 'visibleToUser',
      render: (visible, { fileId }) => (
        <Switch
          defaultChecked={visible}
          onChange={(visibleToUser) =>
            updateFileMutation.mutate({ visibleToUser, fileId })
          }
        />
      )
    },
    {
      title: 'Added Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <span>{format(new Date(createdAt), 'dd/MM/yyyy')}</span>
      )
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (__, item) => (
        <div className="file-action-dropdown">
          <Dropdown
            className="file-action-dropdown"
            overlay={<FileActions {...item} />}
          >
            <MenuOutlined className="actions-icon" />
          </Dropdown>
        </div>
      )
    }
  ];

  const isDisabled =
    disabled ||
    max === 0 ||
    (!isUndefined(max) && max - files.length <= 0) ||
    isUploading;

  return (
    <div className="dragger-wrapper">
      <Upload.Dragger
        disabled={isDisabled}
        showUploadList={false}
        multiple={multiple}
        accept={accept}
        customRequest={({ file }) => {
          if (files.find(({ name }) => file.name === name)) {
            message.error(`${file.name} already uploaded!`);
            return;
          }
          setIsUploading(true);
          onChange(file, {
            onSuccess: () => setIsUploading(false),
            onError: () => setIsUploading(false)
          });
        }}
        beforeUpload={(_, item) => {
          if (isDisabled) {
            return false;
          }

          if (maxUploadLimit && item.length > maxUploadLimit) {
            message.error(
              `You cannot upload more than ${maxUploadLimit} files at once`
            );
            return false;
          }

          if (sumMbSize(item) > maxMbSize) {
            message.error(`Maximum size is ${maxMbSize}MB`);
            return false;
          }

          if (max && item.length > max - files.length) {
            message.error(
              `You cannot select more than ${
                max - files.length
              } files, total maximum is ${max}`
            );
            return false;
          }

          return true;
        }}
      >
        <p className="ant-upload-drag-icon">
          {isUploading ? (
            <LoadingOutlined style={{ fontSize: 32 }} spin />
          ) : (
            <UploadOutlined />
          )}
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        {!isDisabled && Boolean(max) && (
          <p className="ant-upload-hint">
            You can still choose up to {max - files.length} files!
          </p>
        )}
      </Upload.Dragger>
      {extended ? (
        <Table
          dataSource={files}
          columns={columns}
          className="file-table"
          rowKey="fileId"
        />
      ) : (
        files.map((item, index) => (
          <div
            key={`${item.name}-${index}`}
            title={item.fileName || item.name}
            className="filename-wrapper"
          >
            <Button type="link" onClick={() => onFileClick(item)}>
              <span className="filename-link">
                {item.fileName || item.name}
              </span>
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this file?"
              overlayStyle={{ zIndex: 1000000 }}
              onConfirm={() => onDeleteFile(item)}
            >
              <CloseOutlined />
            </Popconfirm>
          </div>
        ))
      )}
    </div>
  );
};
