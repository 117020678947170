import { getAllCommissions, updateCommission } from '@/api';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery } from 'react-query';

const keys = {
  commissions: 'commissions',
  commission: 'commission'
};

export const useAllCommissions = () =>
  useQuery(keys.commissions, getAllCommissions);

export const useUpdateCommission = () =>
  useInvalidateOnSuccessMutation(keys.commissions, updateCommission);
