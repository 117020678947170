import z from 'zod';
import { AxiosResponse } from 'axios';
import { isDev } from '@/utils/environment';
import * as Sentry from '@sentry/react';

class InvalidResponseError extends Error {
  constructor() {
    super('Invalid response');
    this.name = InvalidResponseError.name;
  }
}

export const validateAxiosResponse = async <
  ResponseSchema extends z.ZodTypeAny
>(
  schema: ResponseSchema,
  response: Promise<AxiosResponse>
): Promise<z.infer<ResponseSchema>> => {
  const data = (await response).data;
  const validatedResponse = schema.safeParse(data);

  if (!validatedResponse.success) {
    const err = new InvalidResponseError();

    if (isDev()) {
      console.error(
        'Invalid response from server!',
        validatedResponse.error.errors
      );
      throw err;
    } else {
      Sentry.captureException(err, {
        extra: {
          validationErrors: JSON.stringify(
            validatedResponse.error.errors,
            null,
            2
          )
        }
      });
      return data;
    }
  } else {
    return validatedResponse.data;
  }
};
