import React from 'react';

import { useCreateLocation, useUpdateLocation } from '@/services';
import { Location } from '@/types/location';
import { locationAutocompleteRule } from '@/utils';
import { Form, Input, Modal, Select } from 'antd';
import { keys, pick } from 'ramda';
import { useParams } from 'react-router-dom';
import './styles.scss';
import { LocationAutocomplete } from '@/components';
import { useLocationTypes } from '@/services/locationTypes';

interface LocationModalProps {
  data?: Pick<Location, 'name' | 'address' | 'locationId'> & {
    locationType: string | null;
  };
  visible: boolean;
  onClose: (arg: boolean) => void;
  onAdd?: (locationId: string) => void;
}

export const LocationModal = ({
  data,
  visible,
  onClose,
  onAdd
}: LocationModalProps) => {
  const { companyId } = useParams<{ companyId: string }>();
  const [form] = Form.useForm();
  const updateLocationMutation = useUpdateLocation(
    // fixme: we shouldn't use casting in below case
    data?.locationId as string,
    data?.address?.addressId as number
  );
  const createLocationMutation = useCreateLocation(companyId);

  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const onSubmit = () =>
    form.validateFields().then((values) => {
      const mutation = !!data ? updateLocationMutation : createLocationMutation;

      mutation.mutateAsync(values).then((res) => {
        onClose(false);
        if (!data) onAdd?.(res?.item?.locationId);
      });
    });

  return (
    <Modal
      centered
      onCancel={() => onClose(false)}
      okText={!!data ? 'Edit' : 'Create'}
      onOk={onSubmit}
      cancelText="Cancel"
      title={!!data ? 'Edit Location' : 'New Location'}
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form
        layout="vertical"
        initialValues={pick(['name', 'address', 'locationType'], data || {})}
        form={form}
      >
        <Form.Item
          label="Location Name"
          name="name"
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="Location Address"
          name="address"
          rules={[
            { required: true, message: 'Required!' },
            locationAutocompleteRule
          ]}
        >
          <LocationAutocomplete name="address" />
        </Form.Item>
        <Form.Item label="Location Type" name="locationType">
          <Select
            showSearch
            loading={locationTypesLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={
              locationTypesData?.locationsLabels
                ? keys(locationTypesData?.locationsLabels || {})
                    .sort((a, b) =>
                      locationTypesData
                        ?.getLocationLabel(a)
                        .localeCompare(locationTypesData?.getLocationLabel(b))
                    )
                    .map((key) => ({
                      label: locationTypesData?.getLocationLabel(key),
                      value: key
                    }))
                : []
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
