import { Form, Modal } from 'antd';
import { paths } from '@/routes';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGoBackWithSearch } from '@/utils/hooks/useGoBackWithSearch';
import {
  useAttributesDefinitionItem,
  useCreateOrUpdateAttributeDefinition
} from '@/services/attributes';
import { AttributeDefinition } from '@/api/attributes';
import { AttributeDefinitionForm, Loader } from '@/components';

export const AttributesItem = () => {
  const { attributeId } = useParams<{ attributeId: string }>();
  const onClose = useGoBackWithSearch(paths.attributes);
  const [form] = Form.useForm<Omit<AttributeDefinition, 'id'>>();

  const { isLoading, data: attributeDefinition } =
    useAttributesDefinitionItem(attributeId);

  const { mutateAsync: createOrUpdateAtt, isLoading: isSavingAttribute } =
    useCreateOrUpdateAttributeDefinition();

  return (
    <Modal
      title="Edit attribute"
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      width={1000}
      confirmLoading={isSavingAttribute}
    >
      {isLoading && <Loader />}
      {attributeDefinition && (
        <AttributeDefinitionForm
          attribute={attributeDefinition}
          creatingNew={false}
          form={form}
          onFinish={async (data) => {
            await createOrUpdateAtt({
              id: attributeId,
              ...data
            });
            onClose();
          }}
        />
      )}
    </Modal>
  );
};
