import { api } from './api';
import { PaymentInfo } from '@/types/payment';

export const getPayments = (companyId: string) =>
  api.get<PaymentInfo[]>(`/payment/getAll/${companyId}`);

export const getPaymentById = (id: string) => api.get(`/payment/getOne/${id}`);

export interface CreatePaymentPayload {
  name: string;
  bic: string;
  iban: string;
  priority?: 0 | 1;
  companyId: string;
}

export const createPayment = ({
  name,
  bic,
  iban,
  priority = 0,
  companyId
}: CreatePaymentPayload) =>
  api.post<PaymentInfo>('/payment', {
    name,
    bic,
    iban,
    priority,
    companyId
  });

export interface UpdatePaymentPayload {
  id: string;
  name: string;
  bic: string;
  iban: string;
  priority?: 0 | 1;
}

export const updatePayment = ({
  id,
  name,
  bic,
  iban,
  priority
}: UpdatePaymentPayload) =>
  api.put(`/payment/${id}`, { name, bic, iban, priority });

export const deletePayment = (id: string) => api.delete(`/payment/${id}`);
