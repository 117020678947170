export const BusinessVerticals = [
  'gastronomy',
  'craftwork',
  'construction',
  'cleaningServices',
  'consultingBusinessType',
  'beauty',
  'startUpAndItServices',
  'therapist',
  'ecommerceAndRetail',
  'medicalProfessions',
  'realEstateServices',
  'fitness',
  'lawAndTaxes',
  'engineersAndArchitects',
  'marketingAndCreative',
  'educationBusinessType',
  'officeServices',
  'otherServices',
  'petCare',
  'vehicleTradingAndMaintenance',
  'travel',
  'logistics',
  'agriculture',
  'manufacturing',
  'eventManagement',
  'research',
  'associations',
  'security',
  'other'
] as const;

export type BusinessVertical = (typeof BusinessVerticals)[number];

export type BusinessVerticalWithDefault = BusinessVertical | 'default';
