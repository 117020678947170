import { Insurance, InsuranceApiResult } from '@/types/insurance';
import { AtLeastOne } from '@/utils/types';
import { api, binaryDataApi } from './api';

export const getInsuranceById = (id: string) =>
  api.get<InsuranceApiResult>(`/insurance/${id}`);

export type DocumentDependency = {
  dependency: 'coverNote' | 'poa';
  isMet: boolean;
};

type EmailOption = {
  email: string | null;
  label: string;
};

export type OtherDependency = {
  dependency: 'email';
  emailOptions: Array<EmailOption>; // TODO: this should be generic
};

type DependenciesStatus = {
  documents: Array<DocumentDependency>;
  other: Array<OtherDependency>;
};

export const getInsuranceRequestProtectionDependenciesById = (id: string) =>
  api.get<DependenciesStatus>(`/insurance/${id}/protection/dependencies`);

export const createMultipleInsurances = (
  data: CreateMultipleInsurancesPayload
) => api.post('/insurance', data);

export const deleteInsurance = (id: string) => api.delete(`/insurance/${id}`);

export const updateInsurance = ({
  id,
  ...rest
}: AtLeastOne<UpdateInsurancePayload>) => api.put(`/insurance/${id}`, rest);

type ApproveContractResponse =
  | {
      status: 'CONTRACT_VALIDATED';
    }
  | {
      status: 'SAVED';
    }
  | {
      status: 'CONTRACT_VALIDATION_FAILED';
      reason:
        | 'NOT_SUPPORTED'
        | 'UNMET_DEPENDENCIES'
        | 'SAVE_COVER_NOTE_ERROR'
        | 'SAVE_CONSULTATION_PROTOCOL_ERROR';
    };

export const approveContractOffer = ({
  id,
  finalize,
  paymentId,
  nextPaymentDate,
  startDate,
  endDate,
  agreementNumber
}: {
  id: string;
  finalize: boolean;
  paymentId?: string;
  nextPaymentDate?: string | Date;
  startDate?: string | Date;
  endDate?: string | Date;
  agreementNumber?: string;
}) =>
  api.post<
    {
      finalize: boolean;
      paymentId?: string;
      nextPaymentDate?: string | Date;
      startDate?: string | Date;
      endDate?: string | Date;
      agreementNumber?: string;
    },
    ApproveContractResponse
  >(`/insurance/${id}/approve`, {
    finalize,
    paymentId,
    nextPaymentDate,
    startDate,
    endDate,
    agreementNumber
  });

type RequestProtectionResponse =
  | {
      status: 'PROTECTION_REQUESTED';
    }
  | {
      status: 'FAILED';
      reason: string;
    };

export const requestProtection = ({
  id,
  email,
  coverNoteFileId,
  poaFileId
}: {
  id: string;
  email: string;
  coverNoteFileId: string;
  poaFileId: string;
}) =>
  api.post<
    {
      email: string;
    },
    RequestProtectionResponse
  >(`/insurance/${id}/request-protection`, {
    email,
    coverNoteFileId,
    poaFileId
  });

export const getFinancials = () => api.get('/admin/financial');

export const updateInsuranceFinancials = ({
  insuranceId,
  ...body
}: UpdateInsuranceFinancialsPayload) =>
  api.put(`/admin/financial/insurance/${insuranceId}`, body);

export const getCoverNotePreview = (insuranceId: string) =>
  binaryDataApi.get(`/admin/insurances/${insuranceId}/cover-note-preview`);

interface UpdateInsurancePayload extends Insurance {
  id: string;
}

type UpdateInsuranceFinancialsPayload = AtLeastOne<Insurance>;

type MultipleInsuranceEntry = {
  carrier_id: string;
  category_id: string;
  location_id: string;
};

export type CreateMultipleInsurancesPayload = MultipleInsuranceEntry[];
