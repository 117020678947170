import React, { useState } from 'react';

import { Button, Drawer, Form, Input, Select } from 'antd';
import { handleNegativeInput, toInt } from '@/utils';
import { paymentPeriodOptions } from '@/enums';
import { evolve, keys } from 'ramda';
import { useUpdateRecommendationSearchCriteria } from '@/services';
import { useParams } from 'react-router-dom';
import { Datepicker } from '@/components';
import type { SearchCriteria as ISearchCriteria } from '@/types/recommendation';
import { useLocationTypes } from '@/services/locationTypes';

const { Option } = Select;

export const searchCriteriaSupportedCategories = ['LI', 'CO', 'LE', 'FL'];

const MAX_BUSINESS_TYPES = 15;

const makeSubmitValues = evolve({
  approxTurnover: toInt,
  salary: toInt,
  ownersCount: toInt,
  fullTimeEmployeesCount: toInt,
  partTimeEmployeesCount: toInt,
  miniJobbersCount: toInt
});

interface SearchCriteriaProps {
  categoryId: string;
  onClose: () => void;
  isOpen: boolean;
  initialValues: ISearchCriteria | undefined;
}

const businessTypeValidator = async (
  _rule: unknown,
  value: unknown[],
  _callback: unknown
) => {
  if (value) {
    if (value.length > MAX_BUSINESS_TYPES) {
      return Promise.reject(
        `You can only select ${MAX_BUSINESS_TYPES} business types`
      );
    } else if (value.length <= MAX_BUSINESS_TYPES) {
      return Promise.resolve();
    }
  }
  return;
};

export const SearchCriteria = ({
  categoryId,
  onClose,
  isOpen,
  initialValues
}: SearchCriteriaProps) => {
  const [form] = Form.useForm();
  const { recommendationId } = useParams<{
    recommendationId: string;
  }>();

  const { data: locationTypesData, isLoading: locationTypesLoading } =
    useLocationTypes();

  const [otherSpecificationRequired, setOtherSpecificationRequired] = useState(
    initialValues?.businessType?.includes('other') || false
  );

  const updateSearchCriteriaMutation =
    useUpdateRecommendationSearchCriteria(recommendationId);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      updateSearchCriteriaMutation.mutate(makeSubmitValues(values), {
        onSuccess: onClose
      });
    });
  };

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSubmit(hasErrors);
  };

  return (
    <Drawer placement="left" visible={isOpen} onClose={onClose}>
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFieldsChange={handleFormChange}
      >
        <Form.Item name="startDate" label="Start date">
          <Datepicker />
        </Form.Item>
        <Form.Item name="paymentPeriod" label="Payment Period">
          <Select>
            {paymentPeriodOptions.map(({ label, value, disabledFor }) => (
              <Option
                key={value}
                value={value}
                disabled={disabledFor.includes(categoryId)}
              >
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="businessType"
          label="Business Type"
          rules={[
            {
              required: true,
              message: 'Please select at least one business type!'
            },
            {
              validator: businessTypeValidator
            }
          ]}
        >
          <Select
            showSearch
            loading={locationTypesLoading || !locationTypesData}
            mode="multiple"
            optionFilterProp="label"
            options={keys(locationTypesData?.locationsLabels || {})
              .sort()
              .map((key) => ({
                label: locationTypesData?.getLocationLabel(key),
                value: key,
                key
              }))}
            onChange={(value) => {
              setOtherSpecificationRequired(value?.includes('other') || false);
            }}
          />
        </Form.Item>
        {otherSpecificationRequired && (
          <Form.Item
            name="businessTypeOtherSpecification"
            label="specify risk 'other'"
            rules={[
              {
                required: otherSpecificationRequired,
                message: 'Please specify risk "other"!'
              }
            ]}
          >
            <Input type="{text}"></Input>
          </Form.Item>
        )}
        <Form.Item name="approxTurnover" label="Revenue">
          <Input
            addonAfter="€"
            type="number"
            min="0"
            onKeyDown={handleNegativeInput}
          ></Input>
        </Form.Item>
        <Form.Item name="salary" label="Salary">
          <Input
            addonAfter="€"
            type="number"
            min="0"
            onKeyDown={handleNegativeInput}
          ></Input>
        </Form.Item>
        <Form.Item name="ownersCount" label="Owners">
          <Input type="number" min="0" onKeyDown={handleNegativeInput}></Input>
        </Form.Item>
        <Form.Item name="fullTimeEmployeesCount" label="Full time employee">
          <Input type="number" min="0" onKeyDown={handleNegativeInput}></Input>
        </Form.Item>
        <Form.Item name="partTimeEmployeesCount" label="Part time employee">
          <Input type="number" min="0" onKeyDown={handleNegativeInput}></Input>
        </Form.Item>
        <Form.Item name="miniJobbersCount" label="Minijobbers">
          <Input type="number" min="0" onKeyDown={handleNegativeInput}></Input>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          <Button
            type="primary"
            disabled={updateSearchCriteriaMutation.isLoading || disabledSubmit}
            onClick={handleSubmit}
          >
            Search
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
