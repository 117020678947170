import React from 'react';
import Icon from '@ant-design/icons';

const QontoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_1560_18703)">
      <path
        d="M2.92323 6.55551C1.17366 4.80285 0.133 2.4691 0 0C2.46701 0.136938 4.79515 1.18216 6.53311 2.93305C8.28219 4.6861 9.32276 7.01969 9.45634 9.48869C6.98932 9.3517 4.66118 8.30643 2.92323 6.55551ZM17.0768 6.55551C15.3388 8.30643 13.0107 9.3517 10.5437 9.48869C10.6772 7.01969 11.7178 4.6861 13.4669 2.93305C15.2048 1.18216 17.533 0.136938 20 0C19.867 2.4691 18.8263 4.80285 17.0768 6.55551ZM17.0768 13.4445C18.8263 15.1972 19.867 17.5309 20 20C17.533 19.8631 15.2048 18.8178 13.4669 17.0669C11.7178 15.3139 10.6772 12.9803 10.5437 10.5113C13.0107 10.6483 15.3388 11.6936 17.0768 13.4445ZM2.92323 13.4445C4.66118 11.6936 7.02337 10.6472 9.4904 10.5103C9.35682 12.9793 8.28219 15.3139 6.53311 17.0669C4.79515 18.8178 2.46701 19.8631 0 20C0.133 17.5309 1.17366 15.1972 2.92323 13.4445Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1560_18703">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const QontoIcon = () => <Icon component={QontoSvg} />;
