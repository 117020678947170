import React, { ChangeEvent, useState } from 'react';

import { useDeleteFile, useDownloadFile, useUploadFiles } from '@/services';
import { truncate } from '@/utils';
import { CloseOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { isEmpty } from 'ramda';

const truncateFilename = truncate(12);

interface UploadProps {
  buttonText: string;
  file?: any;
  queryKeys: any;
  belongsTo: string | undefined;
  type: string;
  shouldDelete?: boolean;
  deleteMessage?: string;
}

export const Upload = ({
  buttonText,
  file = {},
  queryKeys,
  belongsTo,
  type,
  shouldDelete = true,
  deleteMessage = 'Are you sure to delete the poa?'
}: UploadProps) => {
  const [ref, setRef] = useState<HTMLInputElement | null>();
  const uploadFilesMutation = useUploadFiles(belongsTo ?? '', queryKeys, type);
  const deleteFileMutation = useDeleteFile(queryKeys, file?.fileId);

  const previewFile = useDownloadFile();

  const handleDeleteFile = () => {
    deleteFileMutation.mutate(undefined);
  };

  const onPickerClick = () => {
    if (ref) {
      ref.value = '';
      ref.click();
    }
  };

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const [file] = [...(event.target.files ?? [])];
    uploadFilesMutation.mutate(file);
  };

  return isEmpty(file) ? (
    <>
      <input
        type="file"
        ref={setRef}
        multiple={false}
        accept=".pdf,.jpg"
        style={{ display: 'none' }}
        onChange={onUpload}
      />
      <Button type="link" onClick={onPickerClick}>
        <UploadOutlined />
        {buttonText}
      </Button>
    </>
  ) : (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <Button type="link" onClick={() => previewFile.mutate(file.name)}>
        <span
          style={{
            maxWidth: '400px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left'
          }}
        >
          {truncateFilename(file.fileName)}
        </span>
      </Button>
      {shouldDelete && (
        <Popconfirm
          title={deleteMessage}
          overlayStyle={{ zIndex: 1000000 }}
          onConfirm={handleDeleteFile}
        >
          <CloseOutlined />
        </Popconfirm>
      )}
    </div>
  );
};
