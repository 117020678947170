import { Psd2Report } from '@/types/integrations';
import { formatMoney } from '@/utils';
import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Table,
  Typography,
  message
} from 'antd';
import React from 'react';

type Props = {
  visible: boolean;
  onClose: (arg: boolean) => void;
  report: Psd2Report | undefined;
};

const makeName = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  if (!firstName && !lastName) {
    return 'Error: Missing name from integration';
  }
  return [firstName, lastName].filter(Boolean).join(' ');
};

const prepareReportData = (report: Psd2Report) => ({
  ...report,
  general: {
    ...report.general,
    holder: {
      name: makeName(
        report.general.holder.firstName,
        report.general.holder.lastName
      )
    }
  },
  finance: {
    ...report.finance,
    avgMonthlyRevenue: (report.finance.avgRevenue || 0) / 12,
    avgMonthlyPersonnelExpenses: (report.finance.avgPersonnelExpenses || 0) / 12
  }
});

const periodicityToTransactionAmount = (periodicity: string) => {
  switch (periodicity) {
    case 'monthly':
      return 1;
    case 'quarterly':
      return 3;
    case 'half-yearly':
      return 6;
    case 'yearly':
      return 12;
    default:
      return 'N/A';
  }
};

const columns = [
  {
    title: 'Carrier',
    dataIndex: 'carrierName'
  },
  {
    title: 'Policy ID',
    dataIndex: 'policyId'
  },
  {
    title: 'Payment',
    dataIndex: 'totalCost',
    render: (value: number) => formatMoney(value)
  },
  {
    title: '#',
    dataIndex: 'periodicity',
    render: (value: string) => periodicityToTransactionAmount(value)
  }
];

const BankingIntegrationModal = ({ visible, onClose, report }: Props) => {
  const [form] = Form.useForm();

  if (!report) {
    message.error('No banking report found');
    onClose(false);
    return null;
  }

  const reportData = prepareReportData(report);

  return (
    <Modal
      centered
      onCancel={() => onClose(false)}
      okText="Update"
      cancelText="Cancel"
      title={'Banking Info'}
      closable={false}
      className="add-company-modal"
      width={800}
      open={visible}
      footer={[
        <Button key="back" onClick={() => onClose(false)}>
          Close
        </Button>
      ]}
    >
      <Form disabled initialValues={reportData} layout="vertical" form={form}>
        <Space
          className="banking-form-wrapper"
          direction="vertical"
          size="middle"
        >
          <div>
            <Typography.Title level={4}>General</Typography.Title>
            <Form.Item
              label="Account Holder"
              name={['general', 'holder', 'name']}
            >
              <Input />
            </Form.Item>
            <Form.Item label="IBAN" name={['general', 'iban']}>
              <Input />
            </Form.Item>
            <Form.Item label="Bank Name" name={['general', 'bankName']}>
              <Input />
            </Form.Item>
          </div>
          <div>
            <Typography.Title level={4}>Revenue</Typography.Title>
            <Form.Item
              label="Average all Months"
              name={['finance', 'avgMonthlyRevenue']}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Total Revenue" name={['finance', 'avgRevenue']}>
              <Input />
            </Form.Item>
          </div>
          <div>
            <Typography.Title level={4}>Salaries</Typography.Title>
            <Form.Item
              label="Average all Months"
              name={['finance', 'avgMonthlyPersonnelExpenses']}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Total Salaries"
              name={['finance', 'avgPersonnelExpenses']}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="insurance-contract-check">
            <Typography.Title level={4}>
              Insurance Contract Check
            </Typography.Title>
            <Table
              dataSource={reportData.insurance}
              columns={columns}
              rowKey={({ policyId }) => policyId[0]}
            />
          </div>
        </Space>
      </Form>
    </Modal>
  );
};

export default BankingIntegrationModal;
