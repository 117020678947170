import React from 'react';

import './styles.scss';
import { Input } from 'antd';
import { handleNegativeInput } from '@/utils';
import { Loader, Table } from '@/components';
import { CategoryLabelMap } from '@/enums';
import {
  useAllCommissions,
  useCarriers,
  useUpdateCommission
} from '@/services';
import { filter, keys, pipe, propEq, reduce } from 'ramda';
import { CarrierLogo } from '@/components/CarrierLogo';
import { Carrier, Commission } from '@/types/carrier';

const getCarrierCommissions = (
  carrierId: string,
  commissions: Commission[]
) => {
  return pipe(
    filter(propEq('carrierId', carrierId)),
    reduce(
      (acc, cur: Commission) => ({ ...acc, [cur.categoryId]: cur.commission }),
      {}
    )
  )(commissions);
};

export const Commissions = () => {
  const { data: carriers, isLoading: areCarriersLoading } = useCarriers();
  const { data, isLoading } = useAllCommissions();
  const updateCommissionMutation = useUpdateCommission();

  if (isLoading || areCarriersLoading) return <Loader />;

  const makeRecords = (search?: string) => {
    if (search) {
      return (
        carriers
          ?.filter(({ name }) =>
            name.toLowerCase().includes(search.toLowerCase())
          )
          .map(({ carrierId, name, logoUrl }) => ({
            carrierId,
            name,
            logoUrl,
            ...getCarrierCommissions(carrierId, data)
          })) || []
      );
    }
    return (
      carriers?.map(({ carrierId, name, logoUrl }) => ({
        carrierId,
        name,
        logoUrl,
        ...getCarrierCommissions(carrierId, data)
      })) || []
    );
  };

  const essentialCategories = ['LI', 'CO', 'LE'];
  const otherCategories = Object.keys(CategoryLabelMap).filter(
    (v) => !essentialCategories.includes(v)
  );

  const columns = [
    {
      title: 'Carrier Logo',
      dataIndex: 'logoUrl',
      fixed: 'left',
      render: (logoUrl: string) => <CarrierLogo name={logoUrl} />
    },
    {
      title: 'Carrier name',
      dataIndex: 'name',
      fixed: 'left',
      width: 250,
      sorter: (a: Carrier, b: Carrier) => a.name?.localeCompare(b.name),
      defaultSortOrder: 'ascend'
    },
    ...essentialCategories.map((item) => ({
      title: CategoryLabelMap[item],
      dataIndex: item,
      render: (value: number, record: Commission) => (
        <Input
          type="number"
          min="0"
          onKeyDown={handleNegativeInput}
          defaultValue={value}
          onBlur={(e) => {
            if (e.target.value) {
              updateCommissionMutation.mutate({
                carrierId: record.carrierId,
                categoryId: item,
                commission: parseInt(e.target.value)
              });
            }
          }}
        />
      )
    })),
    ...otherCategories
      .sort((a, b) => CategoryLabelMap[a]?.localeCompare(CategoryLabelMap[b]))
      .map((item) => ({
        title: CategoryLabelMap[item],
        dataIndex: item,
        render: (value: number, record: Commission) => (
          <Input
            type="number"
            min="0"
            onKeyDown={handleNegativeInput}
            defaultValue={value}
            onBlur={(e) => {
              if (e.target.value) {
                updateCommissionMutation.mutate({
                  carrierId: record.carrierId,
                  categoryId: item,
                  commission: parseInt(e.target.value)
                });
              }
            }}
          />
        )
      }))
  ];

  return (
    <div>
      <Table
        scroll={{ x: keys(CategoryLabelMap).length * 120 }}
        searchKey="commissionsSearchValue"
        searchPlaceholder="Search by carrier name"
        columns={columns}
        records={makeRecords}
        rowKey="carrierId"
        paginationKey="commissionsPagination"
      />
    </div>
  );
};
