import React from 'react';

import { Modal, Form, Input, Checkbox } from 'antd';
import './styles.scss';
import { useCreatePayment, usePayment, useUpdatePayment } from '@/services';
import { Loader } from '@/components';
import { useParams } from 'react-router-dom';
import { evolve } from 'ramda';

interface PaymentModalProps {
  id?: string;
  visible: boolean;
  onClose: (arg: boolean) => void;
}

export const PaymentModal = ({ id, visible, onClose }: PaymentModalProps) => {
  const { companyId } = useParams<{ companyId: string }>();
  const [form] = Form.useForm();
  // fixme: we shouldn't use casting in below case
  const { data, isLoading } = usePayment(id as string, {
    enabled: Boolean(id)
  });
  // fixme: we shouldn't use casting in below case
  const updatePaymentMutation = useUpdatePayment(id as string);
  const createPaymentMutation = useCreatePayment(companyId);

  const onSubmit = () =>
    form.validateFields().then(({ name, iban, bic, priority }) => {
      const mutation = !!id ? updatePaymentMutation : createPaymentMutation;

      mutation
        .mutateAsync({ name, iban, bic, priority: +priority })
        .then(() => onClose(false));
    });

  if (isLoading) return <Loader />;

  return (
    <Modal
      centered
      onCancel={() => onClose(false)}
      okText={!!id ? 'Edit' : 'Create'}
      onOk={onSubmit}
      cancelText="Cancel"
      title={!!id ? 'Edit Payment' : 'New Payment'}
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form
        layout="vertical"
        initialValues={id ? evolve({ priority: Boolean }, data) : {}}
        form={form}
      >
        <Form.Item label="Payment name" name="name">
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item
          label="IBAN"
          name="iban"
          rules={[{ required: true, message: 'Required!' }]}
        >
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item label="BIC" name="bic">
          <Input placeholder="Eingeben" />
        </Form.Item>
        <Form.Item initialValue={false} name="priority" valuePropName="checked">
          <Checkbox>Is standard payment</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
