import React, { useEffect } from 'react';

import { pack } from '@/utils';
import { Checkbox, Form, Input } from 'antd';
import { omit } from 'ramda';
import { Address } from '@/types/self-assessment';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface onChangeArgs extends Address {
  googleLocation: string;
}

interface LocationAutocompleteProps {
  name: string | string[];
  value?: Address;
  onChange?: (args: onChangeArgs) => void;
  handleSame?: (e: CheckboxChangeEvent) => void;
  disabled?: boolean;
}

export const LocationAutocomplete = ({
  name,
  value,
  onChange,
  handleSame,
  disabled
}: LocationAutocompleteProps) => {
  useEffect(() => {
    if (value?.street && !!onChange)
      onChange({
        ...value,
        googleLocation: `${value.street} ${value.streetNr}, ${value.postalCode} ${value.city}, ${value.country}`
      });
  }, [JSON.stringify(omit(['googleLocation'], value))]);

  const safeNamePath = Array.isArray(name) ? name : pack(name);

  return (
    <>
      <div>
        <Form.Item name={[...safeNamePath, 'street']}>
          <Input disabled={disabled} placeholder="Street" />
        </Form.Item>
        <Form.Item name={[...safeNamePath, 'streetNr']}>
          <Input disabled={disabled} placeholder="Street Nr" />
        </Form.Item>
      </div>
      <div>
        <Form.Item name={[...safeNamePath, 'postalCode']}>
          <Input disabled={disabled} placeholder="Postal Code" type="number" />
        </Form.Item>
        <Form.Item name={[...safeNamePath, 'city']}>
          <Input disabled={disabled} placeholder="City" />
        </Form.Item>
      </div>
      <Form.Item
        style={{ display: 'none' }}
        name={[...safeNamePath, 'country']}
        initialValue="Germany" // FIXME: this is causing error and likely not setting the value
      >
        <Input disabled={disabled} />
      </Form.Item>
      {handleSame && (
        <Checkbox onChange={handleSame}>
          Location address equals company address?
        </Checkbox>
      )}
    </>
  );
};
