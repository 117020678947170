import { submitCheckout } from '@/api/cart';
import { useInvalidateOnSuccessMutation } from '@/utils';

const keys = {
  cart: 'cart'
};

export const useSubmitCheckout = () =>
  useInvalidateOnSuccessMutation([keys.cart, 'location'], (checkout) =>
    submitCheckout(checkout)
  );
