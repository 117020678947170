import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Button, message, Radio, Space } from 'antd';
import { prop } from 'ramda';
import { getRecommendationPDF } from '@/api';
import { PdfSkeleton } from '@/components/PdfDocument/PdfSkeleton';
import { PdfDocument } from '@/components/PdfDocument/PdfDocument';
import { DownloadOutlined } from '@ant-design/icons';
import { saveFileToLocalDisk } from '@/components/PdfCoverNote/renderPDF';

const useRecommendationPDFPreview = (
  recommendationId: string,
  language: 'en' | 'de'
): { isLoading: boolean; pdf: null | Blob } => {
  const [pdf, setPdf] = useState<Blob | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setPdf(null);
    getRecommendationPDF(recommendationId, language)
      .then(async (res) => {
        setPdf(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(
          `Failed to download PDF: ${prop('message', error) ?? 'Unknown error'}`
        );
      });
  }, [recommendationId, language]);

  return { isLoading, pdf };
};

export const PdfRecommendation = ({
  recommendationId,
  companyName,
  categoryId
}: {
  recommendationId: string;
  companyName: string;
  categoryId: string;
}) => {
  const [language, setLanguage] = useState<'en' | 'de'>('de');

  const { isLoading, pdf } = useRecommendationPDFPreview(
    recommendationId,
    language
  );

  const handleLocalDownload = () => {
    if (pdf) {
      saveFileToLocalDisk(
        pdf,
        `${companyName}-${categoryId}-${format(new Date(), 'dd-MM-yyyy')}-${language}`.replaceAll(
          '.',
          ''
        )
      );
    }
  };

  return (
    <Space direction="vertical" align="end">
      <Space>
        <Radio.Group
          onChange={(e) => setLanguage(e.target.value)}
          value={language}
          disabled={isLoading}
        >
          <Radio.Button value="de">DE</Radio.Button>
          <Radio.Button value="en">EN</Radio.Button>
        </Radio.Group>

        <Button
          icon={<DownloadOutlined />}
          type="primary"
          onClick={handleLocalDownload}
          disabled={isLoading}
        >
          Download
        </Button>
      </Space>

      {isLoading || !pdf ? <PdfSkeleton /> : <PdfDocument pdf={pdf} />}
    </Space>
  );
};
