import { Location, LocationType } from '@/types/location';
import { updateAddress } from './address';
import { api } from './api';

export const getCompanyLocations = (id: string) =>
  api.get(`/location/company/${id}`);

export const getLocationHome = (id: string) => api.get(`/location/${id}/home`);

export const createLocation = ({
  name,
  address,
  locationType,
  companyId
}: CreateLocationPayload) =>
  api.post('/location', { name, address, locationType, companyId });

export const updateLocation = ({
  id,
  name,
  address,
  locationType
}: UpdateLocationPayload) =>
  Promise.all([
    updateAddress(address),
    api.put(`/location/${id}`, { name, locationType })
  ]);

export const updateLocationType = ({
  id,
  locationType
}: UpdateLocationTypePayload) => api.put(`/location/${id}`, { locationType });

export const deleteLocation = (id: string) => api.delete(`/location/${id}`);

export const postKillerQuestions = ({
  questions,
  locationId
}: PostKillerQuestionsPayload) =>
  api.patch(`/locations/${locationId}/killer-questions/answers`, questions);

export const getKillerAnswers = ({
  id,
  categoryIds
}: GetKillerAnswersPayload) =>
  api.get<{ data: Record<string, boolean> }>(
    `/locations/${id}/killer-questions/answers?${categoryIds
      .map((categoryId: string) => `categoryId=${categoryId}&`)
      .join('')}`
  );

type CreateLocationPayload = Pick<
  Location,
  'name' | 'address' | 'locationType' | 'companyId'
>;

type UpdateLocationPayload = {
  id: string;
  companyId?: string;
} & Pick<Location, 'name' | 'address' | 'locationType'>;

type UpdateLocationTypePayload = {
  id: string;
  locationType: LocationType;
};

type GetKillerAnswersPayload = {
  id: string;
  categoryIds: string[];
};

type PostKillerQuestionsPayload = {
  locationId: string;
  questions: Record<string, boolean>;
};
