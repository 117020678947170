import { allPass, complement, is } from 'ramda';

export const isUndefined = (x: unknown) => typeof x === 'undefined';
export const isNumber = is(Number);
export const isArray = is(Array);
export const isFunction = is(Function);
export const isObject = allPass([
  is(Object),
  complement(is(FormData)),
  complement(isArray),
  complement(isFunction)
]);

export function isNotEmpty<T>(v: T | undefined): v is T {
  return !!v;
}
