import React, { MutableRefObject } from 'react';

import './styles.scss';
import { findCoverNote, InsuranceApiResult } from '@/types/insurance';
import { CoverNoteInfo } from '@/components/PdfCoverNote/CoverNoteInfo';
import { CoverNotePdfPreview } from '@/components/PdfCoverNote/CoverNotePreview';
import { PdfFilePreview } from '@/components/PdfFilePreview/PdfFilePreview';

export const CoverNotePDF = ({
  insuranceData,
  blobRef,
  isDownloading,
  downloadFile
}: {
  insuranceData: InsuranceApiResult;
  blobRef: MutableRefObject<Blob | null>;
  isDownloading: boolean;
  downloadFile: () => void;
}) => {
  const existingCoverNote = findCoverNote(insuranceData);

  return (
    <>
      <CoverNoteInfo
        insurance={insuranceData}
        isDownloading={isDownloading}
        downloadFile={downloadFile}
      />

      {existingCoverNote ? (
        <PdfFilePreview fileName={existingCoverNote.name} blobRef={blobRef} />
      ) : (
        insuranceData.coverNote?.status === 'SUPPORTED' && (
          <CoverNotePdfPreview
            insuranceId={insuranceData.insuranceId}
            blobRef={blobRef}
          />
        )
      )}
    </>
  );
};
