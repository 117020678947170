import { complement } from 'ramda';

export const isDev = () => process.env.NODE_ENV === 'development';

export const isProd = complement(isDev);

export const log = (...args: any) => {
  if (isDev()) {
    console.log(...args);
  }
};

export const logTable = (...args: any) => {
  if (isDev()) {
    console.table(...args);
  }
};
