import { api } from '@/api/api';

export type LocationTypeResponse = Record<
  string, // BusinessVertical
  {
    label: string; // BusinessVertical label
    locationTypes: Record<string, string>; // LocationType -> LocationType label
  }
>;

export interface VerticalsMap {
  [verticalCode: string]: string[];
}

export const getLocationTypes = async (language: 'en' | 'de' = 'de') => {
  const data = await api.get<LocationTypeResponse>(
    `/public/locations?language=${language}`
  );

  const locationsLabels = Object.values(data).reduce(
    (acc, val) => {
      return { ...acc, ...val.locationTypes };
    },
    {} as Record<string, string>
  );

  const verticalsLabels = Object.entries(data).reduce(
    (acc, [verticalCode, { label }]) => {
      return { ...acc, [verticalCode]: label };
    },
    {} as Record<string, string>
  );

  const verticalsMap: VerticalsMap = Object.entries(data).reduce(
    (acc, [verticalCode, { locationTypes }]) => ({
      ...acc,
      [verticalCode]: Object.keys(locationTypes)
    }),
    {} as VerticalsMap
  );

  return {
    getLocationLabel(code: string) {
      return locationsLabels[code] ?? null;
    },
    getVerticalLabel(code: string) {
      return verticalsLabels[code] ?? null;
    },
    verticalsMap,
    locationsLabels
  };
};
