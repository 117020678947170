import {
  getAllTransferAccount,
  markAsCompletedTA,
  startAutomationTA
} from '@/api';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { useQuery } from 'react-query';

const keys = {
  transferAccount: 'transferAccount'
};

export const useAllTransferAccount = () =>
  useQuery(keys.transferAccount, getAllTransferAccount);

export const useStartAutomationTA = () =>
  useInvalidateOnSuccessMutation(keys.transferAccount, (id) =>
    startAutomationTA({ insuranceId: id })
  );

export const useMarkAsCompletedTA = () =>
  useInvalidateOnSuccessMutation(keys.transferAccount, (id) =>
    markAsCompletedTA({ automationCaseTransferAccountId: id })
  );
