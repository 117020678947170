import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { setAuthInterceptor } from '@/api/api';
import { Loader } from '@/components';
import { paths } from '@/routes';
import {
  isProd,
  oneOrManyChildren,
  renameObjKeysBy,
  snakeToCamelCase
} from '@/utils';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { assoc, isEmpty, join, omit, pipe, prepend, when, __ } from 'ramda';
import { config } from '../config';
import { message } from 'antd';

class FailedToGetAccessToken extends Error {
  name = FailedToGetAccessToken.name;
  constructor(cause) {
    super('Failed to get access token', { cause });
  }
}

const makeRedirectURL = when(
  isProd,
  pipe(prepend(__, [paths.authRedirect]), join('')),
  window.location.origin
);

const TokenProvider = ({ children }) => {
  const {
    getIdTokenClaims,
    isAuthenticated,
    getAccessTokenWithPopup,
    getAccessTokenSilently
  } = useAuth0();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      const getToken = window.origin.includes('localhost')
        ? getAccessTokenWithPopup
        : getAccessTokenSilently;

      getToken({
        authorizationParams: {
          audience: config.backendApiAudience,
          scope: 'openid profile email'
        }
      })
        .then((accessToken) => {
          setAuthInterceptor(accessToken);
          setLoading(false);
        })
        .catch((err) => {
          Sentry.captureException(new FailedToGetAccessToken(err));
          message.error('Something went wrong. Please try again later.');
        });
    }
  }, [
    getIdTokenClaims,
    isAuthenticated,
    getAccessTokenSilently,
    getAccessTokenWithPopup
  ]);

  if (isLoading && isAuthenticated) return <Loader />;

  return children;
};

TokenProvider.propTypes = {
  children: oneOrManyChildren
};

export const AuthProvider = ({ children }) => (
  <Auth0Provider
    clientId={config.auth0ClientId}
    domain={config.authDomain}
    redirectUri={makeRedirectURL}
  >
    <TokenProvider>{children}</TokenProvider>
  </Auth0Provider>
);

AuthProvider.propTypes = {
  children: oneOrManyChildren
};

const assignRole = (user = {}) => {
  const [role] = user?.['https://surein.auth0/role'] || [];

  if (isEmpty(user)) return undefined;

  return pipe(omit(['https://surein.auth0/role']), assoc('role', role))(user);
};

export const useAuth = () => {
  const authContext = useAuth0();

  const logout = (returnTo, options) =>
    authContext.logout({
      ...options,
      returnTo: returnTo || makeRedirectURL
    });

  const user = assignRole(authContext.user);

  return renameObjKeysBy(snakeToCamelCase, {
    ...authContext,
    logout,
    user
  });
};
