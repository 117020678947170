import {
  companyUserDetails,
  createUser,
  deleteUser,
  listAdmin,
  listUsers,
  updateCompanyUser,
  updateUser,
  userDetails
} from '@/api';
import { Admin, CompanyUser, User } from '@/types/user';
import { useInvalidateOnSuccessMutation } from '@/utils';
import { UseQueryOptions, useQuery } from 'react-query';

const keys = { users: 'users', user: 'user', admins: 'admins' };

export const useListUsers = <T = User[]>(
  options?: UseQueryOptions<User[], unknown, T, string>
) => useQuery(keys.users, listUsers, options);

export const useListAdmins = (
  options?: UseQueryOptions<Admin[], unknown, Admin[], string>
) => useQuery(keys.admins, listAdmin, options);

export const useUserDetails = (
  id: string,
  options: UseQueryOptions<User[], unknown, User[], string[]>
) => useQuery([keys.user, id], () => userDetails(id), options);

export const useCompanyUserDetails = (
  companyId: string,
  userId: string,
  options: UseQueryOptions<CompanyUser, unknown, CompanyUser, string[]>
) =>
  useQuery(
    [keys.user, userId],
    () => companyUserDetails(companyId, userId),
    options
  );

export const useDeleteUser = () =>
  useInvalidateOnSuccessMutation(keys.users, (id) => deleteUser(id));

export const useCreateUser = () =>
  useInvalidateOnSuccessMutation(keys.users, createUser);

export const useUpdateUser = (userId: string) =>
  useInvalidateOnSuccessMutation(
    [keys.users, [keys.user, userId], 'company', 'location'],
    ({ name, surname, phone, birthdate, email, emailNotificationsDisabled }) =>
      updateUser({
        userId,
        name,
        surname,
        phone,
        birthdate,
        email,
        emailNotificationsDisabled
      })
  );

// TODO: probably should be in company
export const useUpdateCompanyUser = (companyId: string, userId: string) =>
  useInvalidateOnSuccessMutation(
    [keys.users, [keys.user, userId], 'company', 'location'],
    ({
      name,
      surname,
      phone,
      birthdate,
      email,
      emailNotificationsDisabled,
      role
    }: {
      name: string;
      surname: string;
      phone: string;
      birthdate: Date;
      email: string;
      emailNotificationsDisabled: boolean;
      role: 'owner' | 'md' | 'member';
    }) =>
      updateCompanyUser({
        userId,
        companyId,
        name,
        surname,
        phone,
        birthdate,
        email,
        emailNotificationsDisabled,
        role
      })
  );
