import { useAttributesTranslations } from '@/services/attributes';
import { Empty, Form, Input, Space } from 'antd';
import { BooleanYesNoRadio, UpdatableField } from '@/components';
import { StarButton } from '@/components/YesNo/StarButton';
import { isAttributeHighlightingDisabled } from '@/pages/RecommendationPage/helpers';

export function AttributesList() {
  const { data: attributesTranslations } = useAttributesTranslations();
  const form = Form.useFormInstance<{
    attributes: { is_highlighted: string }[];
  }>();
  const attributes = form.getFieldValue('attributes');

  if (!attributes || attributes.length === 0) {
    return <Empty description="There are no attributes" />;
  }

  return (
    <div className="gridContainer">
      <div className="attributeContainer">
        <span>Attributes</span>
        <span>German</span>
        <span>English</span>
        <span>Yes/No</span>
        <span>Highlight</span>
      </div>

      <Form.List name="attributes">
        {(fields) => {
          return (
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <Space className="attributeContainer" key={key} align="start">
                  <i className="parameter-value-label">
                    {attributesTranslations?.getAttributeLabel(
                      form.getFieldValue(['attributes', name, 'code'])
                    )}
                  </i>
                  <Form.Item {...restField} name={[name, 'parameter_de']}>
                    <Input />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'parameter_en']}>
                    <Input />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'is_included']}>
                    <BooleanYesNoRadio />
                  </Form.Item>
                  <UpdatableField>
                    {() => (
                      <Form.Item {...restField} name={[name, 'is_highlighted']}>
                        <StarButton
                          disabled={isAttributeHighlightingDisabled(
                            form.getFieldValue('attributes'),
                            form.getFieldValue(['attributes', name, 'code'])
                          )}
                        />
                      </Form.Item>
                    )}
                  </UpdatableField>
                </Space>
              ))}
            </div>
          );
        }}
      </Form.List>
    </div>
  );
}
