import { Button, Form, Modal, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { useProductTemplates } from '@/services/productTemplates';
import { Loader } from '@/components';
import { useLocationTypes } from '@/services/locationTypes';
import { GetProductTemplatesListResponse } from '@/api/productTemplates';
import { PlainCategoryLabelMap } from '@/enums';
import { useUrlParams } from '@/utils/hooks/useUrlParams';
import { SearchAwareLink } from '@/components/SearchAwareLink';
import React, { useCallback, useMemo, useState } from 'react';
import { NewVerticalTemplateForm } from '@/pages/ProductTemplates/NewVerticalTemplateForm';
import { useGoToPath } from '@/utils';

const mapToColumns = (
  { data }: GetProductTemplatesListResponse,
  getVerticalLabel: (category: string) => string
): {
  type: 'category';
  id: string;
  name: string;
  category: string;
  children?: {
    type: 'vertical';
    id: string;
    name: string;
  }[];
}[] => {
  return data.map((category) => {
    return {
      type: 'category',
      id: category.id,
      name: PlainCategoryLabelMap[category.category],
      category: category.category,
      children:
        category?.children?.length === 0
          ? undefined
          : category.children?.map((vertical) => {
              return {
                type: 'vertical',
                id: vertical.id,
                name: getVerticalLabel(vertical.vertical)
              };
            })
    };
  });
};

export const ProductTemplates = () => {
  const [newTemplateData, setNewTemplateData] = useState<{
    category: string;
    existingVerticals: string[];
  } | null>(null);
  const { current: currentUrlParams, setParams } = useUrlParams<{
    page: string;
  }>();

  const { isLoading, data: productTemplates } = useProductTemplates(
    currentUrlParams.page ? Number(currentUrlParams.page) : 1,
    { keepPreviousData: true }
  );

  const getExistingVerticalsForCategory = useCallback(
    (category: string): string[] => {
      return (
        productTemplates?.data
          .find((template) => template.category === category)
          ?.children?.map((child) => child.vertical) ?? []
      );
    },
    [productTemplates]
  );

  const { isLoading: isLoadingLocationTypes, data: locationTypes } =
    useLocationTypes();

  const columns: ColumnsType<{
    type: 'category' | 'vertical';
    id: string;
    name: string;
    category: string;
  }> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (_, { id, name, type }) => (
          <Space>
            {type === 'vertical' && '↳'}
            <SearchAwareLink to={`/product-templates/${id}`}>
              {name}
            </SearchAwareLink>
          </Space>
        )
      },
      {
        title: 'Action',
        width: '20%',
        key: 'action',
        render: (_, { type, category }) => (
          <Space size="middle">
            {type === 'category' && (
              <Button
                onClick={() =>
                  setNewTemplateData({
                    category,
                    existingVerticals: getExistingVerticalsForCategory(category)
                  })
                }
              >
                Add vertical
              </Button>
            )}
          </Space>
        )
      }
    ],
    [setNewTemplateData, getExistingVerticalsForCategory]
  );

  const [form] = Form.useForm();

  const openNewProductTemplate = useGoToPath(
    (id: string) => `/product-templates/${id}`,
    true
  );

  if (
    isLoading ||
    !productTemplates ||
    isLoadingLocationTypes ||
    !locationTypes
  ) {
    return <Loader />;
  }

  const tableData = mapToColumns(
    productTemplates,
    locationTypes.getVerticalLabel
  );

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={2}>Product Templates</Typography.Title>
        <Link to="/attributes">Edit attributes</Link>
      </div>
      <Table
        rowKey="id"
        expandable={{
          defaultExpandAllRows: true
        }}
        columns={columns}
        dataSource={tableData}
        pagination={{
          total: productTemplates?.meta.total_items,
          pageSize: productTemplates?.meta.page_size,
          current: Number(productTemplates?.meta.current_page ?? '1'),
          showSizeChanger: false
        }}
        onChange={(paginationChange) => {
          setParams({ page: String(paginationChange.current ?? 1) });
        }}
      />

      <Modal
        title={`New vertical template for ${newTemplateData?.category ? PlainCategoryLabelMap[newTemplateData?.category] : ''}`}
        open={!!newTemplateData}
        onOk={form.submit}
        onCancel={() => setNewTemplateData(null)}
        width={1000}
      >
        <NewVerticalTemplateForm
          form={form}
          {...newTemplateData!}
          onFinish={(createdId: string) => {
            setNewTemplateData(null);
            openNewProductTemplate(createdId);
          }}
        />
      </Modal>
    </div>
  );
};
