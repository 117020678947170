import React from 'react';
import { useUpdateCompany } from '@/services';
import { Checkbox, Form, Input, Modal, message } from 'antd';
import { useParams } from 'react-router-dom';
import './styles.scss';

interface HeadquarterModalProps {
  bannerUrl: string | null;
  companyId?: string;
  visible: boolean;
  logoUrl: string | null;
  isHeadquarters?: boolean;
  onClose: (arg: boolean) => void;
}

export const HeadquartersModal = ({
  visible,
  onClose,
  bannerUrl,
  logoUrl,
  isHeadquarters
}: HeadquarterModalProps) => {
  const { companyId } = useParams<{ companyId: string }>();
  const updateCompanyMutation = useUpdateCompany(companyId);
  const [form] = Form.useForm();

  const onSubmit = () =>
    form.validateFields().then((values) => {
      updateCompanyMutation
        .mutateAsync(values)
        .then(() => {
          onClose(false);
        })
        .catch((e) => {
          message.error(e.message || 'An error occurred');
        });
    });

  return (
    <Modal
      centered
      onCancel={() => onClose(false)}
      okText="Update"
      onOk={onSubmit}
      cancelText="Cancel"
      title={'Edit Headquarters settings'}
      closable={false}
      className="add-company-modal"
      open={visible}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{ logoUrl, bannerUrl, isHeadquarters }}
      >
        <Form.Item label="Logo URL" name="logoUrl">
          <Input placeholder="Enter image name with extension" />
        </Form.Item>
        <Form.Item label="Banner URL" name="bannerUrl">
          <Input placeholder="Enter image name with extension" />
        </Form.Item>
        <Form.Item
          initialValue={false}
          name="isHeadquarters"
          valuePropName="checked"
        >
          <Checkbox>Company is a HQ</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
