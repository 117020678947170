import React from 'react';

import { linkify } from '@/utils';

export const AutoDetectUrl = ({ text }: { text: string }) => {
  const safeText = text || '';

  if (!safeText.includes('http')) return <span>{safeText}</span>;
  return <div dangerouslySetInnerHTML={{ __html: linkify(safeText) }} />;
};
