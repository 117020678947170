import { Form, FormInstance, message, Select } from 'antd';
import { BusinessVerticals } from '@/enums/businessVertical';
import { useLocationTypes } from '@/services/locationTypes';
import { Loader } from '@/components';
import { useCreateProductTemplate } from '@/services/productTemplates';
import { isAxiosError } from 'axios';

export const NewVerticalTemplateForm = ({
  form,
  existingVerticals,
  category,
  onFinish
}: {
  onFinish: (id: string) => void;
  form: FormInstance<{ vertical: string }>;
  category: string;
  existingVerticals: string[];
}) => {
  const { isLoading: isLoadingLocationTypes, data: locationTypes } =
    useLocationTypes();

  const { mutateAsync } = useCreateProductTemplate();

  if (isLoadingLocationTypes || !locationTypes) {
    return <Loader />;
  }

  return (
    <Form
      form={form}
      onFinish={async ({ vertical }) => {
        try {
          const result = await mutateAsync({
            category,
            vertical
          });

          if (result.status === 'created') {
            onFinish(result.data.id);
          } else {
            message.error(result.error);
          }
        } catch (error) {
          if (isAxiosError(error)) {
            message.error(error.response?.data.error ?? 'Something went wrong');
          } else {
            throw error;
          }
        }
      }}
    >
      <Form.Item label="Vertical" name="vertical" rules={[{ required: true }]}>
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={BusinessVerticals.map((code) => ({
            value: code,
            label: locationTypes.getVerticalLabel(code)
          })).filter((option) => !existingVerticals.includes(option.value))}
        />
      </Form.Item>
    </Form>
  );
};
