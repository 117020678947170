import { Alert, Button, Space } from 'antd';
import React from 'react';
import { findCoverNote, InsuranceApiResult } from '@/types/insurance';
import { DownloadOutlined } from '@ant-design/icons';

export const CoverNoteInfo = ({
  insurance,
  isDownloading,
  downloadFile
}: {
  insurance: InsuranceApiResult;
  isDownloading: boolean;
  downloadFile: () => void;
}) => {
  const existingCoverNote = findCoverNote(insurance);
  const coverNoteInfo = insurance.coverNote ?? { status: 'NOT_SUPPORTED' };

  let message: React.JSX.Element;

  const isOther = insurance.risksInsured === 'other';

  if (coverNoteInfo.status === 'NOT_SUPPORTED') {
    return (
      <div>
        <Alert
          className="unmet-dependency-alert"
          type="warning"
          message="Cover note not supported"
          description="We do not support cover not for this carrier yet."
          showIcon
        />
      </div>
    );
  }

  if (coverNoteInfo.status === 'OTHER_NOT_SUPPORTED') {
    return (
      <div>
        <Alert
          className="unmet-dependency-alert"
          type="warning"
          message="Cover note not supported"
          description="We do not support cover note if other is main risk"
          showIcon
        />
      </div>
    );
  }

  const unmetDependencies = coverNoteInfo.unmetDependencies;
  const canBeApproved = unmetDependencies.length === 0;

  if (existingCoverNote) {
    message = (
      <Alert
        className="unmet-dependency-alert"
        type="success"
        message="Deckungsnote already approved"
        showIcon
      />
    );
  } else {
    message = !canBeApproved ? (
      <Alert
        className="unmet-dependency-alert"
        type="warning"
        message="Some values have not been properly filled out!"
        description={`Please check ${unmetDependencies.join(', ')}.`}
        showIcon
      />
    ) : (
      <Alert
        className="unmet-dependency-alert"
        type="success"
        message="All good"
        description="You can approve Deckungsnote"
        showIcon
      />
    );
  }

  return (
    <div>
      <Space className="button-wrapper">
        <Button
          loading={isDownloading}
          onClick={downloadFile}
          icon={<DownloadOutlined />}
        >
          Download PDF version
        </Button>
      </Space>

      {isOther && (
        <Alert
          className="unmet-dependency-alert"
          type="warning"
          description="We are unable to display a Deckungsnote for a location type that is not connected to our Search Engine. Create an auto-recommendation to create a Deckungsnote."
          showIcon
        />
      )}

      {message}
    </div>
  );
};
