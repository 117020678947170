import { Skeleton } from 'antd';
import React from 'react';

import './styles.scss';

export const PdfSkeleton = () => (
  <div className="pdf-document-wrapper">
    <div className="page-skeleton">
      <Skeleton active paragraph={{ rows: 30 }} />
    </div>
  </div>
);
